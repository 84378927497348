import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  RiProgress5Line,
  RiProgress8Fill,
  RiProgress8Line,
} from "react-icons/ri";
import { Minus } from "react-flaticons";
import { TbCircleDashed } from "react-icons/tb";

import Sidebar from "components/core/Sidebar";
import Topbar from "components/core/topbar/Topbar";

import Loader from "components/common/Loader";
import IconWrapper from "components/common/IconWrapper";

import IdeasComponent from "components/ideas/IdeasComponent";

import { capitalizeFirstLetter, fieldIconMapping } from "utils";
import { ideaTopbarButtonFunctions } from "utils/topbarButtonFunctions";
import { ideaTopbarTabFunctions } from "utils/topbarTabFunctions";

import { setIdeaActiveProperties } from "reduxStore/slices/ideaSlice";
import { useNavigate } from "react-router-dom";
import AllDetailView from "components/common/AllDetailView";

const Ideas = () => {
  const { statuses, products, tags } = useSelector((state) => state.company);
  const { accounts } = useSelector((state) => state.accounts);
  const { users } = useSelector((state) => state.users);
  const [ideaStatuses, setIdeaStatuses] = useState([]);
  const [searchbarTerm, setSearchbarTerm] = useState("");

  useEffect(() => {
    setIdeaStatuses(statuses.idea);
  }, [statuses]);
  const { viewType, isLoading, isViewsLoading } = useSelector(
    (state) => state.ideas
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const topbarFunctions = ideaTopbarButtonFunctions(dispatch, navigate);
  const topbarTabFunctions = ideaTopbarTabFunctions(dispatch, navigate);
  const setActiveProperties = (
    sortOption,
    selectedColumns,
    groupByOption,
    filters
  ) => {
    dispatch(
      setIdeaActiveProperties({
        sortOption,
        selectedColumns,
        groupByOption,
        filters,
      })
    );
  };

  const statusParked =
    (ideaStatuses ?? []).filter((item) => item.type === "parked") ?? [];
  const statusToDo =
    (ideaStatuses ?? []).filter((item) => item.type === "to_do") ?? [];
  const statusInProgress =
    (ideaStatuses ?? []).filter((item) => item.type === "in_progress") ?? [];
  const statusDone =
    (ideaStatuses ?? []).filter((item) => item.type === "done") ?? [];

  const tabs = [
    {
      label: "All",
      filters: {},
      selectedColumns: ["status", "importance", "product"],
      groupByOption: "default",
      sortOption: { order: "asc", field: "created" },
    },
  ];

  const groupByDropdownOptions = [
    { label: "Default", icon: Minus },
    ...(viewType !== "kanban"
      ? [{ label: "Status", icon: fieldIconMapping["status"] }]
      : []),
    { label: "Product", icon: fieldIconMapping["product"] },
    { label: "Importance", icon: fieldIconMapping["importance"] },
    { label: "Accounts", icon: fieldIconMapping["accounts"] },
    { label: "Tags", icon: fieldIconMapping["tags"] },
    { label: "Assignee", icon: fieldIconMapping["assignee"] },
    { label: "Source", icon: fieldIconMapping["source"] },
    { label: "submittedBy", icon: fieldIconMapping["creator"] },
    // { label: "Objective", icon: fieldIconMapping["objectives"] },
  ];

  const getFilteredIdeas = () => {
    const predefinedFields = [
      "status",
      "importance",
      "score",
      "product",
      "accounts",
      "tags",
      "assignee",
      "source",
      "creator",
      "roadmap",
      "feature",
    ];

    const filteredOptions = predefinedFields.map((field) => ({
      label: capitalizeFirstLetter(field),
      icon: fieldIconMapping[field] || Minus,
    }));

    if (viewType !== "kanban") {
      filteredOptions.unshift({
        subOptions: [{ label: "Modern" }, { label: "Classic" }],
        optionType: "viewType",
        showAs: "tab",
      });
    }

    return [...filteredOptions];
  };

  const showButtonDropdownOptions = getFilteredIdeas();

  const getFilterOptions = () => [
    {
      label: "Status",
      icon: fieldIconMapping.status,
      subOptions: [
        ...statusParked.map((item) => ({
          ...item,
          iconColor: item.color,
          icon: TbCircleDashed,
        })),
        ...statusToDo.map((item) => ({
          ...item,
          iconColor: item.color,
          icon: RiProgress8Fill,
        })),
        ...statusInProgress.map((item) => ({
          ...item,
          iconColor: item.color,
          icon: RiProgress5Line,
        })),
        ...statusDone.map((item) => ({
          ...item,
          iconColor: item.color,
          icon: RiProgress8Line,
        })),
      ],
    },
    {
      label: "Product",
      icon: fieldIconMapping.product,
      subOptions: [{ label: "No Product", iconColor: "#A9A9A9" }].concat(
        (products ?? []).map((product) => ({
          ...product,
          iconColor: product.color,
        }))
      ),
    },
    {
      label: "Importance",
      icon: fieldIconMapping.importance,
      subOptions: [1, 2, 3, 4, 5].map((importance) => ({
        _id: importance,
        label: importance.toString(),
      })),
    },
    {
      label: "Accounts",
      icon: fieldIconMapping.accounts,
      subOptions: (accounts ?? []).map((account) => ({
        ...account,
        label: account.name,
      })),
    },
    {
      label: "Assignee",
      icon: fieldIconMapping.assignee,
      subOptions: users ?? [],
    },
    {
      label: "submittedBy",
      icon: fieldIconMapping.assignee,
      subOptions: users ?? [],
    },
    {
      label: "Source",
      icon: fieldIconMapping.assignee,
      subOptions: [
        { label: "Internal", _id: "Internal" },
        { label: "External", _id: "External" },
      ],
    },
    {
      label: "Tags",
      icon: fieldIconMapping.tags,
      subOptions: tags ?? [],
    },
  ];

  const filterOptions = getFilterOptions();

  // const sortOptions = [
  //   "Default",
  //   "Status",
  //   "Product",
  //   "Age",
  //   "Importance",
  //   "Score",
  //   "Assignee",
  // ].map((label) => ({
  //   label,
  // }));

  const sortOptions = [
    { label: "Created", icon: Minus },
    { label: "Status", icon: fieldIconMapping.status },
    { label: "Product", icon: fieldIconMapping.product },
    { label: "Importance", icon: fieldIconMapping.importance },
    { label: "Accounts", icon: fieldIconMapping.accounts },
    { label: "Score", icon: fieldIconMapping.score },
    { label: "Assignee", icon: fieldIconMapping.assignee },
    { label: "Source", icon: fieldIconMapping.source },
    { label: "SubmittedBy", icon: fieldIconMapping.creator },
  ];

  return (
    <div className="flex font-inter w-screen h-screen tracking-wide">
      <Sidebar />
      {isLoading && isViewsLoading ? (
        <div className="absolute inset-0 flex items-center justify-center">
          <Loader />
        </div>
      ) : (
        <div className="flex flex-col min-w-[80%] w-[100%]">
          <Topbar
            title="Ideas"
            titleIcon={
              <IconWrapper
                icon={React.createElement(fieldIconMapping.idea)}
                iconColor="text-ideas-icon"
                size="14"
              />
            }
            tabs={tabs}
            groupByDropdownOptions={groupByDropdownOptions}
            showButtonDropdownOptions={showButtonDropdownOptions}
            filterOptions={filterOptions}
            sortOptions={sortOptions}
            topbarFunctions={topbarFunctions}
            topbarTabFunctions={topbarTabFunctions}
            setActiveProperties={setActiveProperties}
            searchTerm={searchbarTerm}
            onSearchChange={(value) => setSearchbarTerm(value)}
            activeState={"ideas"}
          />
          <IdeasComponent searchTerm={searchbarTerm} />
        </div>
      )}
      <AllDetailView />
    </div>
  );
};

export default Ideas;

/* eslint-disable react/prop-types */
import React, { useEffect, useMemo, useState } from "react";
import DemoProfileImage from "assets/demo_profile_image.webp";
import { useSelector } from "react-redux";
import { generateStatusArray } from "utils/status";
import ProductSection from "../detailView/detailViewRightSidebarSections/ProductSection";
import ReleaseSection from "../detailView/detailViewRightSidebarSections/ReleaseSection";
import StatusSection from "../detailView/detailViewRightSidebarSections/StatusSection";
import { fieldIconMapping } from "utils";

const AddFeaturePopupOverlay = ({ option, name, setIsWarn }) => {
  const [status, setStatus] = useState(null);
  const [release, setRelease] = useState(null);
  const [product, setProduct] = useState(null);
  const { statuses, releases, products } = useSelector(
    (state) => state.company
  );
  const { scoreType } = useSelector((state) => state.company);

  const handleStatusOptionClick = (option) => {
    setStatus(option);
  };
  const handleReleaseOptionClick = (option) => {
    setRelease(option);
  };
  const handleProductOptionClick = (option) => {
    setProduct(option);
  };


  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === "Enter") {
        handleAddFeature()
      }
    };

    window.addEventListener("keydown", handleKeyPress);

    // Cleanup function to remove the event listener
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [name]);

  const handleAddFeature = () => {
    if (name === "") {
      setIsWarn(true);
      setTimeout(() => {
        setIsWarn(false);
      }, 3000);
      return;
    }
    option.onAddClick({
      title: name,
      status: status?._id ?? null,
      release: release?._id ?? null,
      product: product?._id ?? null,
      score: {
        type:
          scoreType === "value_effort_score"
            ? "Value/Effort"
            : scoreType === "rice_score"
            ? "RICE"
            : scoreType === "impact_effort_score"
            ? "Impact/Effort"
            : "",
        value: 0.0,
        effort: 0.0,
        reach: 0.0,
        impact: 0.0,
        value_effort_score: 0.0,
        impact_effort_score: 0.0,
        rice_score: 0.0,
      },
    });
  };

  const [featureStatuses, setFeatureStatuses] = useState([]);

  useEffect(() => {
    setFeatureStatuses(statuses.feature);
  }, [statuses]);

  const statusArray = useMemo(
    () => generateStatusArray(featureStatuses),
    [featureStatuses]
  );

  useEffect(() => {
    if (products !== null) {
      setProduct(products?.[0] ?? "");
    }
  }, [products]);

  useEffect(() => {
    setStatus(statusArray[0]);
  }, [statusArray]);

  return (
    <div className="flex flex-col gap-y-2 -ml-1">
      <div className="flex items-center gap-x-2">
        {option.addOptions.includes("status") && (
          <StatusSection
            item={status ?? {}}
            statusArray={statusArray}
            handleStatusOptionClick={(option) =>
              handleStatusOptionClick(option)
            }
            position=""
            with_title={false}
          />
        )}
        {option.addOptions.includes("release") && (
          <div className="rounded border">
            <ReleaseSection
              itemLabel={release?.label ?? "No release"}
              releases={releases}
              handleReleaseOptionClick={(option) => {
                handleReleaseOptionClick(option);
              }}
              with_title={false}
              with_icon={true}
            />
          </div>
        )}
        {option.addOptions.includes("product") && (
          <ProductSection
            item={{
              ...product,
              icon: React.createElement(fieldIconMapping.product),
            }}
            products={products}
            handleProductOptionClick={(option) => {
              handleProductOptionClick(option);
            }}
            position=""
            with_title={false}
          />
        )}
        {/* {option.addOptions.includes("assignee") && (
          <div className="mr-3 p-1 flex items-center">
            <img
              src={DemoProfileImage}
              alt="Profile"
              className="w-6 h-6 rounded-full"
            />
          </div>
        )} */}
      </div>
      <hr />
      <button
        className="p-2 bg-add-popup-button rounded text-white w-fit text-sm ml-auto"
        onClick={handleAddFeature}
      >
        Add {option.label}
      </button>
    </div>
  );
};

export default AddFeaturePopupOverlay;

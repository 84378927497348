/* eslint-disable react/prop-types */
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useMemo, useState } from "react";
import { generateStatusArray } from "utils/status";
import { fieldIconMapping } from "utils";
import Loader from "components/common/Loader";
import { DragDropContext } from "@hello-pangea/dnd";
import FeatureKanbanColumn from "./FeatureKanbanColumn";
import { updateFeatureData } from "reduxStore/operations/featuresAPI";

const FeatureKanbanView = ({ groupedData }) => {
  const dispatch = useDispatch();
  const { groupByOption, isViewsLoading } = useSelector(
    (state) => state.features
  );
  const { statuses, products, releases } = useSelector(
    (state) => state.company
  );
  const [featureStatuses, setFeatureStatuses] = useState([]);

  useEffect(() => {
    setFeatureStatuses(statuses.feature);
  }, [statuses]);

  const statusArray = useMemo(
    () => generateStatusArray(featureStatuses),
    [featureStatuses]
  );

  const [localGroupByOption, setLocalGroupByOption] = useState(null);

  useEffect(() => {
    setLocalGroupByOption(groupByOption);
  }, [groupByOption]);

  // const handleOnDragStart = (result) => {
  //   console.log(result);
  // };

  const handleOnDragEnd = (result) => {
    const { source, destination, draggableId } = result;

    // const destinationDroppableId = result.source.droppableId;
    // const destinationGroup = groupedData[destinationDroppableId];
    // const item = destinationGroup.items.find(
    //   (item) => item._id === result.draggableId // Compare with the draggableId, not droppableId
    // );
    // const ToBeStatusId = result.destination.droppableId;
    // console.log(ToBeStatusId);
    // const restOption = restOptionStatuses.find(
    //   (option) => option._id === ToBeStatusId
    // );

    if (!destination || source.droppableId === destination.droppableId) return;

    if (groupByOption === "default") {
      const updateToStatusId = destination.droppableId;
      dispatch(
        updateFeatureData(
          draggableId,
          { status: updateToStatusId }
          // "status",
          // item,
          // restOption
        )
      );
    }

    if (groupByOption === "release") {
      let updateToReleaseId = destination.droppableId;
      if (updateToReleaseId==="no-release") {
        updateToReleaseId= null
      }
      dispatch(
        updateFeatureData(
          draggableId,
          { release: updateToReleaseId },
          "release"
        )
      );
    }

    if (groupByOption === "product") {
      const updateToProductId = destination.droppableId;
      dispatch(
        updateFeatureData(
          draggableId,
          { product: updateToProductId },
          "product"
        )
      );
    }
  };

  return (
    <div className={`h-full overflow-x-auto`}>
      <DragDropContext
        onDragEnd={handleOnDragEnd}
        // onDragStart={handleOnDragStart}
      >
        <div className="flex gap-x-5 p-4">
          {isViewsLoading ||
          localGroupByOption === null ||
          localGroupByOption !== groupByOption ? (
            <div className="flex items-center justify-center h-[600px] w-full">
              <Loader />
            </div>
          ) : (
            Object.entries(groupedData)?.map(([groupId, groupData]) => (
              <FeatureKanbanColumn
                key={groupId}
                groupByOption={groupByOption}
                group={{
                  _id: groupId,
                  label: groupData.label,
                  color: groupData.color,
                }}
                itemsForGroup={groupData.items}
                statusArray={statusArray}
              />
            ))
          )}
        </div>
      </DragDropContext>
    </div>
  );
};

export default FeatureKanbanView;

/* eslint-disable react/prop-types */
import React from "react";
import IconText from "components/common/IconText";
import { capitalizeFirstLetter, fieldIconMapping } from "utils";
import { Info } from "react-flaticons";

const CreatorSection = ({
  creator,
  position = "gap-x-6",
  with_title = true,
  with_label_title = true,
  iconSize=16,
  with_border = true,
  showHover = false,
}) => {

  return (
    <div className={`flex ${position} items-center text-title_50`}>
      {with_title && (
        <IconText
          icon={React.createElement(fieldIconMapping.creator)}
          text={with_label_title?"Creator":""}
          iconColor="#696969"
          iconSize={iconSize}
          hoverable={false}
          gap_x={with_label_title?"gap-x-2":""}
        />
      )}
      <div
        className={`${
          with_border && "border rounded     "
        } p-1 text-xs cursor-default text-nowrap `}
      >
        {creator ? capitalizeFirstLetter(creator.name ?? "Unknown") : "Unknown"}
      </div>
      {/* {showHover && (
        <div className="group">
          <Info size={14} className="cursor-pointer" />
          <div className="absolute hidden group-hover:block text-black w-[18rem] py-3 top-4 right-[-3rem] z-50">
            <div className="bg-white p-3 px-4 rounded-md shadow-md text-gray-600 text-sm">
              <p>
                <b className="text-title_50">Submitted By:</b>{" "}
                {user?.name ?? "Unknown"}
              </p>
              <p className="pt-3">Details about the user could go here.</p>
            </div>
          </div>
        </div>
      )} */}
    </div>
  );
};

export default CreatorSection;

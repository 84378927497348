/* eslint-disable react/prop-types */
import React, {
  useState,
  useCallback,
  useEffect,
  useRef,
  useMemo,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AngleSmallDown, AngleSmallUp } from "react-flaticons";

import IconText from "components/common/IconText";
import Importance from "components/common/Importance";
import Progress from "components/common/Progress";
import DropdownMenu from "components/common/detailView/dropdown/DropdownMenu";
import StatusRowSection from "components/common/classicList/StatusRowSection";
import TagsRowSection from "components/common/classicList/TagsRowSection";
import HealthRowSection from "components/common/classicList/HealthRowSection";
import AssigneeRowSection from "components/common/classicList/AssigneeRowSection";
import TimeFrameRowSection from "components/common/classicList/TimeFrameRowSection";
import TagsSearchDropdown from "components/common/detailView/dropdown/TagsSearchDropdown";
import ModuleRowSection from "components/common/classicList/ModuleRowSection";

import { capitalizeFirstLetter, toSnakeCase, fieldIconMapping } from "utils";
import { generateStatusArray } from "utils/status";
import { objectiveHealths, getHealthBgColor } from "utils/objectiveUtils";
import {
  getInitiativeBasedObjectiveProgress,
  getKeyResultBasedObjectiveProgress,
} from "utils/progressUtils";

import {
  setActiveObjectiveId,
  setObjectiveShowDetailViewDrawer,
} from "reduxStore/slices/objectiveSlice";

import { setDetailKeyResults } from "reduxStore/slices/keyResultSlice";

import {
  updateObjectiveTags,
  updateObjectiveData,
} from "reduxStore/operations/objectivesAPI";

import useRowOutsideClick from "hooks/useRowOutsideClick";
import { setObjectiveProgress } from "reduxStore/slices/objectiveSlice";

const ObjectiveRowComponent = ({
  objective,
  onSelect,
  isSelected,
  setShowInitiatives,
  showInitiatives,
  setShowKeyResults,
  showKeyResults,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { tags, statuses, timeFrames } = useSelector((state) => state.company);
  const { selectedColumns, showDetailViewDrawer } = useSelector(
    (state) => state.objectives
  );
  const { ideas } = useSelector((state) => state.ideas);
  const { features } = useSelector((state) => state.features);
  const { keyResults } = useSelector((state) => state.keyResults);
  const { roadmaps } = useSelector((state) => state.roadmaps);
  const { users } = useSelector((state) => state.users);

  const objectiveKeyResults = useMemo(() => {
    return (keyResults ?? []).filter((keyResult) =>
      (objective?.keyResults ?? []).includes(keyResult._id)
    );
  }, [objective?.keyResults, keyResults]);

  const objectiveRoadmaps = useMemo(() => {
    return (roadmaps ?? []).filter((roadmap) =>
      (objective?.roadmaps ?? []).includes(roadmap._id)
    );
  }, [objective?.roadmaps, roadmaps]);

  const objectiveProgress =
    objective.type === "Key_result_based"
      ? getKeyResultBasedObjectiveProgress(objectiveKeyResults, ideas, features)
      : getInitiativeBasedObjectiveProgress(objective, ideas, features);

  useEffect(() => {
    dispatch(
      setObjectiveProgress({
        objectiveId: objective._id,
        progress: objectiveProgress,
      })
    );
  }, [dispatch, objective._id, objectiveProgress]);

  const [isHovered, setIsHovered] = useState(false);
  const [objectiveStatuses, setObjectiveStatuses] = useState([]);
  const [dropdownState, setDropdownState] = useState({
    isStatusDropdownOpen: false,
    isTimeFrameDropdownOpen: false,
    isTagDropdownOpen: false,
    isHealthDropdownOpen: false,
    isAssigneeDropdownOpen: false,
  });
  const dropdownRef = useRef(null);

  const [selectedTags, setSelectedTags] = useState(
    new Set(objective?.tags?.map((tag) => tag._id))
  );
  const [selectTags, setSelectTags] = useState(objective?.tags ?? []);
  const [initialSelectedTags, setInitialSelectedTags] = useState(
    new Set((objective?.tags ?? []).map((tag) => tag._id))
  );

  useEffect(() => {
    setSelectTags(objective?.tags ?? []);
    setSelectedTags(new Set(objective?.tags?.map((tag) => tag._id)));
    setInitialSelectedTags(
      new Set((objective?.tags ?? []).map((tag) => tag._id))
    );
  }, [objective]);

  useEffect(() => {
    setObjectiveStatuses(statuses.objective);
  }, [statuses]);

  const statusArray = useMemo(
    () => generateStatusArray(objectiveStatuses),
    [objectiveStatuses]
  );

  const toggleDropdown = useCallback((type) => {
    setDropdownState((prev) => {
      const newState = Object.keys(prev).reduce((acc, key) => {
        acc[key] = key === type ? !prev[key] : false;
        return acc;
      }, {});
      return newState;
    });
  }, []);

  const closeAllDropdowns = () => {
    setDropdownState({
      isStatusDropdownOpen: false,
      isTimeFrameDropdownOpen: false,
      isTagDropdownOpen: false,
      isHealthDropdownOpen: false,
      isAssigneeDropdownOpen: false,
    });
  };

  const handleRowClick = () => {
    dispatch(setActiveObjectiveId(objective._id));
    dispatch(setObjectiveShowDetailViewDrawer(!showDetailViewDrawer));
    dispatch(setDetailKeyResults(objectiveKeyResults ?? []));
  };

  const handleStatusOptionClick = (option) => {
    let { icon, ...restOption } = option;
    let isUpdated = "status";
    if (objective?.status?._id !== option?._id) {
      dispatch(
        updateObjectiveData(
          objective._id,
          { status: option._id },
          isUpdated,
          restOption,
          objective
        )
      );
    }
    closeAllDropdowns();
  };
  const handleTimeFrameOptionClick = (option) => {
    if (objective?.timeFrame?._id !== option?._id) {
      let { icon, ...restOption } = option;
      let isUpdated = "timeFrame";
      dispatch(
        updateObjectiveData(
          objective._id,
          { timeFrame: option._id },
          isUpdated,
          restOption,
          objective
        )
      );
    }
    closeAllDropdowns();
  };
  const handleHealthOptionClick = (option) => {
    if (objective?.health !== toSnakeCase(option.label)) {
      let { icon, ...restOption } = option;
      let isUpdated = "health";
      dispatch(
        updateObjectiveData(
          objective._id,
          { health: toSnakeCase(option.label) },
          isUpdated,
          restOption,
          objective
        )
      );
    }
    closeAllDropdowns();
  };

  const handleAssineeOptionClick = (option) => {
    if (objective?.assignee?._id !== option._id) {
      let { icon, ...restOption } = option;
      dispatch(
        updateObjectiveData(
          objective._id,
          { assignee: option._id },
          "assignee",
          restOption,
          objective,
        )
      );
    }
    closeAllDropdowns();
  };

  const handleTagDropdownOptionClick = (option) => {
    const tagId = option._id;
    const updatedTags = new Set(selectedTags);

    if (updatedTags.has(tagId)) {
      updatedTags.delete(tagId);
      setSelectTags((prevTags) => prevTags.filter((tag) => tag._id !== tagId));
    } else {
      updatedTags.add(tagId);
      setSelectTags((prevTags) => [...prevTags, option]);
    }

    setSelectedTags(updatedTags);
  };
  const getSelectedTagObjects = () => {
    return Array.from(selectedTags).map((id) =>
      selectTags.find((tag) => tag._id === id)
    );
  };

  const handleTagsOptionClick = (option) => {
    let fullTags = getSelectedTagObjects();
    dispatch(updateObjectiveTags(objective, option.itemId, fullTags));
    closeAllDropdowns();
  };

  const handleImportanceOptionClick = (option) => {
    dispatch(updateObjectiveData(objective._id, { importance: option }));
    closeAllDropdowns();
  };

  useRowOutsideClick({
    item: objective,
    selectedTags,
    initialSelectedTags,
    tagDropdownState: dropdownState.isTagDropdownOpen,
    dropdownRef,
    handleTagsOptionClick,
    getSelectedTagObjects,
    closeAllDropdowns,
  });

  return (
    <tr
      className={`group text-xs text-title_50 cursor-pointer hover:bg-row-background relative ${
        isSelected ? "bg-button-active_10" : ""
      }`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={handleRowClick}
      ref={dropdownRef}
    >
      <td
        className={`py-1 relative sticky top-0 left-0 z-20 group-hover:bg-row-background  group-hover:rounded-l-lg ${
          isSelected ? "bg-button-active_10" : "bg-white"
        }`}
      >
        <div className="flex items-center">
          <div
            className={`flex p-1 items-center ${
              !isSelected ? (isHovered ? "visible" : "invisible") : "visible"
            }`}
          >
            <input
              type="checkbox"
              checked={isSelected}
              onChange={() => {
                onSelect(objective);
              }}
              onClick={(e) => {
                e.stopPropagation();
              }}
              className="cursor-pointer"
            />
          </div>
          <div className={`w-full flex items-center justify-between pr-4`}>
            <IconText
              icon={React.createElement(fieldIconMapping.objective)}
              iconSize={14}
              truncateLength={68}
              text={objective?.title ?? "N/A"}
              textColor="text-title"
              iconColor="text-objectives-icon"
              isASidebarComponent={false}
              hoverable={false}
              px=""
            />
          </div>
          {(objective.type === "Key_result_based" &&
            showKeyResults.includes(objective._id)) ||
          (objective.type === "Initiative_based" &&
            showInitiatives.includes(objective._id)) ? (
            <div
              className="p-1 rounded hover:bg-button-hover  mr-4"
              onClick={(e) => {
                e.stopPropagation();
                if (objective.type === "Key_result_based") {
                  const array = showKeyResults.filter(
                    (idx) => idx !== objective._id
                  );
                  setShowKeyResults(array);
                } else {
                  const array = showInitiatives.filter(
                    (idx) => idx !== objective._id
                  );
                  setShowInitiatives(array);
                }
              }}
            >
              <AngleSmallUp size={12} />
            </div>
          ) : (
            <div
              className="p-1 rounded hover:bg-button-hover mr-4"
              onClick={(e) => {
                e.stopPropagation();
                if (objective.type === "Key_result_based") {
                  const array = [...showKeyResults, objective._id];
                  setShowKeyResults(array);
                } else {
                  const array = [...showInitiatives, objective._id];
                  setShowInitiatives(array);
                }
              }}
            >
              <AngleSmallDown size={12} />
            </div>
          )}
        </div>
      </td>
      {selectedColumns?.map((key, index) => {
        const snakeCaseKey = toSnakeCase(key);

        return (
          <td
            key={index}
            className={`${
              key === "tags" || key === "roadmap"
                ? "min-w-44 max-w-66"
                : "max-w-44"
            } truncate pr-1 px-3`}
          >
            {(() => {
              switch (snakeCaseKey) {
                case "status":
                  return (
                    <StatusRowSection
                      status={objective?.[snakeCaseKey] ?? {}}
                      dropdownState={dropdownState}
                      toggleDropdown={() => {
                        toggleDropdown("isStatusDropdownOpen");
                      }}
                      statusArray={statusArray}
                    />
                  );
                case "time_frame":
                  return (
                    <TimeFrameRowSection
                      timeFrame={objective?.timeFrame}
                      dropdownState={dropdownState}
                      toggleDropdown={() =>
                        toggleDropdown("isTimeFrameDropdownOpen")
                      }
                    />
                  );
                case "tags":
                  return (
                    <TagsRowSection
                      tags={selectTags ?? []}
                      dropdownState={dropdownState}
                      toggleDropdown={() => toggleDropdown("isTagDropdownOpen")}
                    />
                  );

                case "progress":
                  return (
                    <Progress
                      value={objectiveProgress ?? 0}
                      color={getHealthBgColor(objective?.health)}
                    />
                  );
                case "roadmap":
                  return (
                    <ModuleRowSection
                      moduleItems={objectiveRoadmaps ?? []}
                      icon={React.createElement(fieldIconMapping.roadmap)}
                      iconColor="text-roadmaps-icon"
                    />
                  );
                case "type":
                  return capitalizeFirstLetter(objective?.[snakeCaseKey] ?? "");
                case "importance":
                  return (
                    <span className="p-1 rounded">
                      <Importance
                        value={objective?.[snakeCaseKey]}
                        handleOptionClick={handleImportanceOptionClick}
                      />
                    </span>
                  );
                case "assignee":
                  return (
                    <AssigneeRowSection
                      name={objective?.[snakeCaseKey]?.name ?? ""}
                      dropdownState={dropdownState}
                      toggleDropdown={() =>
                        toggleDropdown("isAssigneeDropdownOpen")
                      }
                    />
                  );
                case "health":
                  return (
                    <HealthRowSection
                      health={objective?.[snakeCaseKey] ?? ""}
                      dropdownState={dropdownState}
                      toggleDropdown={() =>
                        toggleDropdown("isHealthDropdownOpen")
                      }
                    />
                  );
                default:
                  return objective?.[snakeCaseKey] ?? "";
              }
            })()}
            {snakeCaseKey === "status" && (
              <DropdownMenu
                options={statusArray ? statusArray : []}
                isOpen={dropdownState.isStatusDropdownOpen}
                position="top-full -mt-3 w-32"
                handleOptionClick={handleStatusOptionClick}
                item={objective}
                type="status"
              />
            )}
            {snakeCaseKey === "tags" && (
              <div className="absolute w-48 z-20 bg-white -ml-8">
                <TagsSearchDropdown
                  searchPlaceholder={"Search Tags..."}
                  items={tags ?? []}
                  selectedItems={selectedTags}
                  isDropdownOpen={dropdownState.isTagDropdownOpen}
                  onItemSelect={handleTagDropdownOptionClick}
                />
              </div>
            )}
            {snakeCaseKey === "time_frame" && (
              <DropdownMenu
                options={(timeFrames ?? []).map((timeFrame) => ({
                  ...timeFrame,
                  icon: fieldIconMapping.timeFrames,
                  color: timeFrame.color,
                }))}
                isOpen={dropdownState.isTimeFrameDropdownOpen}
                iconSize={12}
                handleOptionClick={handleTimeFrameOptionClick}
                withAddOption={true}
                handleAddOptionClick={() =>
                  navigate("/settings/customizations/time-Frame")
                }
                item={objective}
                type="timeFrame"
              />
            )}
            {snakeCaseKey === "health" && (
              <DropdownMenu
                options={objectiveHealths() ?? []}
                isOpen={dropdownState.isHealthDropdownOpen}
                handleOptionClick={handleHealthOptionClick}    
                item={objective}
                type="health"           
              />
            )}
              {snakeCaseKey === "assignee" && (
              <DropdownMenu
                options={users ?? []}
                isOpen={dropdownState.isAssigneeDropdownOpen}
                position="top-full -mt-3 w-32"
                handleOptionClick={handleAssineeOptionClick}
                withAddOption={true}
                handleAddOptionClick={() =>
                  navigate("/settings/customizations/product")
                }
                item={objective}
                type="assignee"
                isAssignee={true}
                
              />

            )}
          </td>
        );
      })}
    </tr>
  );
};

export default ObjectiveRowComponent;

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getCookie } from "utils/cookieUtils";
import { setLoading } from "reduxStore/slices/authSlice";
import Loader from "../common/Loader";
import { companyRegister } from "../../reduxStore/operations/authAPI";
import { useSearchParams } from "react-router-dom";
import { FaEyeSlash } from "react-icons/fa";
import { IoEyeSharp } from "react-icons/io5";

const CompanyRegister = () => {
  const [name, setName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessages, setErrorMessages] = useState({});
  const { loading } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const Usertoken = searchParams.get("token");

  const isStrongPassword = (password) => {
    const regex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*?&^_#])[A-Za-z\d@$!%*?&^_#]{8,}$/;
    return regex.test(password);
  };

  const validateForm = () => {
    let errors = {};

    if (!name) {
      errors.name = "Name is required.";
    }
    if (!companyName) {
      errors.companyName = "Company name is required.";
    }

    if (!password) {
      errors.password = "Password is required.";
    } else if (!isStrongPassword(password)) {
      errors.password =
        "Password require to contain at least 8 characters including one Capital letter, one small letter and one special character [@$!%*?&^_#].";
    }

    setErrorMessages(errors);

    return Object.keys(errors).length === 0;
  };

  const handleCompanyRegister = (e) => {
    e.preventDefault();
    if (validateForm()) {
      dispatch(setLoading(true));
      dispatch(
        companyRegister(name, companyName, Usertoken, password, navigate)
      );
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("jwt");
    if (token) {
      navigate("/"); // Redirect to home page if user is already logged in
    }
  }, [navigate]);

  return (
    <div className="flex flex-col gap-4 items-center justify-center min-h-screen bg-gray-100">
      <div className=" relative bg-white p-8 rounded shadow-md w-full max-w-sm">
        <div className="  absolute top-[-120px] right-0 w-full  flex items-center justify-center  ">
          <img
            src="/logo.png  "
            alt="logo"
            className=" w-0 h-0  xl:w-[200px] xl:h-[100px] "
          />{" "}
        </div>
        <h2 className="text-2xl mb-6 text-center">Welcome onboard!</h2>
        <form onSubmit={handleCompanyRegister}>
          <div className="mb-4">
            <label className="block text-gray-700">Name</label>
            <input
              type="text"
              className={`w-full p-2 border ${
                errorMessages.name ? "border-red-500" : "border-gray-300"
              } rounded mt-1`}
              value={name}
              placeholder="Name"
              onChange={(e) => setName(e.target.value)}
            />
            {errorMessages.name && (
              <p className="text-red-500 text-sm mt-1">{errorMessages.name}</p>
            )}
          </div>

          <div className="mb-4">
            <label className="block text-gray-700">Company Name</label>
            <input
              type="text"
              className={`w-full p-2 border ${
                errorMessages.companyName ? "border-red-500" : "border-gray-300"
              } rounded mt-1`}
              value={companyName}
              placeholder="Company Name"
              onChange={(e) => setCompanyName(e.target.value)}
            />
            {errorMessages.companyName && (
              <p className="text-red-500 text-sm mt-1">
                {errorMessages.companyName}
              </p>
            )}
          </div>

          <div className="mb-4">
            <label className="block text-gray-700">Password</label>
            <div className="relative">
              <input
                type={showPassword ? "text" : "password"}
                className="w-full p-2 border border-gray-300 rounded mt-1 pr-10" // Added padding-right for the icon
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Sshhhh…"
                required
              />
              <button
                type="button"
                className="absolute right-2 mt-1 inset-y-0 flex items-center text-gray-600"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? (
                  <IoEyeSharp className="h-5 w-5" />
                ) : (
                  <FaEyeSlash className="h-5 w-5" />
                )}
              </button>
            </div>
          </div>

          <button
            type="submit"
            className="w-full bg-blue-500 text-white p-2 rounded mt-4 hover:bg-blue-600"
          >
            {loading ? <Loader size="20" color="white" /> : "Take me in"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default CompanyRegister;

import { createSlice } from "@reduxjs/toolkit";
import { isObject, transformFiltersToObject } from "utils";

const initialState = {
  objectives: [],
  selectedColumns: ["progress", "health", "status", "time_frame", "tags"],
  filters: {},
  sortOption: { order: "asc", field: "default" },
  listViews: [],
  kanbanViews: [],
  groupByOption: "default",
  viewType: "classiclist",
  activeObjectiveId: null,
  showDetailViewDrawer: false,
  modernListExists: false,
  isLoading: false,
  isViewsLoading: false,
};

const priorityMap = {
  progress: 1,
  health: 2,
  status: 3,
  time_frame: 4,
  // importance: 5,
  tags: 6,
  assignee: 7,
  key_results: 8,
  features: 9,
  ideas: 10,
  roadmaps: 11,
};

const objectivesSlice = createSlice({
  name: "objectives",
  initialState,
  reducers: {
    setObjectives(state, action) {
      state.objectives = action.payload;
      state.isLoading = false;
    },
    setObjectiveProgress(state, action) {
      const { objectiveId, progress } = action.payload;
      const index = state.objectives.findIndex(
        (objective) => objective._id === objectiveId
      );
      if (index !== -1) {
        state.objectives[index].progress = progress;
      }
    },
    setObjectiveRoadmaps(state, action) {
      const { timeFrameIds, roadmaps } = action.payload;

      const timeFrameIdSet = new Set(timeFrameIds);

      state.objectives.forEach((objective, index) => {
        if (
          objective.timeFrame &&
          timeFrameIdSet.has(objective.timeFrame._id)
        ) {
          state.objectives[index].roadmaps = roadmaps[
            objective.timeFrame._id
          ].map((roadmap) => roadmap._id);
        } else if (objective.timeFrame === null) {
          state.objectives[index].roadmaps = [];
        }
      });
    },
    updateObjective(state, action) {
      const { updatedObjective, isUpdated } = action.payload;
      const index = state.objectives.findIndex(
        (objective) => objective._id === updatedObjective._id
      );

      if (index !== -1) {
        if (isUpdated === "features") {
          state.objectives[index].features = updatedObjective.features;
        } else if (isUpdated === "ideas") {
          state.objectives[index].ideas = updatedObjective.ideas;
        } else if (isUpdated === "keyResults") {
          state.objectives[index].keyResults = updatedObjective.keyResults;
        } else if (isUpdated === "tags") {
          state.objectives[index].tags = updatedObjective.tags;
        } else if (isUpdated === "status") {
          state.objectives[index].status = updatedObjective.status;
        } else if (isUpdated === "health") {
          state.objectives[index].health = updatedObjective.health;
        } else if (isUpdated === "timeFrame") {
          state.objectives[index].timeFrame = updatedObjective.timeFrame;
        }else if (isUpdated === "assignee") {
          state.objectives[index].assignee = updatedObjective.assignee;
        } else if (isUpdated === "title") {
          state.objectives[index].title = updatedObjective.title;
        } else if (isUpdated === "description") {
          state.objectives[index].description = updatedObjective.description;
        } else {
          state.objectives[index] = updatedObjective;
        }
      }
    },
    appendObjective(state, action) {
      const newObjective = action.payload;
      // state.objectives.unshift(newObjective);
      state.objectives.push(newObjective);

    },
    removeObjective(state, action) {
      const deletedObjectiveId = action.payload;
      const index = state.objectives.findIndex(
        (objective) => objective._id === deletedObjectiveId
      );
      if (index !== -1) {
        state.objectives.splice(index, 1);
      }
    },
    updateObjectives(state, action) {
      const { updatedObjectives, isUpdatedArray } = action.payload;

      updatedObjectives.forEach((updatedObjective) => {
        const index = state.objectives.findIndex(
          (objective) => objective._id === updatedObjective._id
        );

        if (index !== -1) {
          if (isUpdatedArray.length > 0) {
            isUpdatedArray.forEach((updateKey) => {
              state.objectives[index][updateKey] = updatedObjective[updateKey];
            });
          } else {
            state.objectives[index] = updatedObjective;
          }
        }
      });
    },
    removeObjectives(state, action) {
      const { objectiveIds } = action.payload;
      objectiveIds.forEach((objectiveId) => {
        const index = state.objectives.findIndex(
          (objective) => objective._id === objectiveId
        );
        if (index !== -1) {
          state.objectives.splice(index, 1);
        }
      });
    },
    toggleObjectiveColumn(state, action) {
      const column = action.payload;

      if (state.selectedColumns.includes(column)) {
        state.selectedColumns = state.selectedColumns.filter(
          (col) => col !== column
        );
      } else {
        const columnPriority = priorityMap[column];
        let insertIndex = state.selectedColumns.length;

        for (let i = 0; i < state.selectedColumns.length; i++) {
          const currentPriority = priorityMap[state.selectedColumns[i]];
          if (columnPriority < currentPriority) {
            insertIndex = i;
            break;
          }
        }

        state.selectedColumns.splice(insertIndex, 0, column);
      }
    },
    setObjectiveFilters(state, action) {
      state.filters = action.payload;
    },
    updateObjectiveFilter(state, action) {
      const { key, value } = action.payload;
      console.log(action.payload);

      if (!state.filters[key]) {
        state.filters[key] = [];
      }

      const existingIndex = state.filters[key].findIndex(
        (v) => JSON.stringify(v) === JSON.stringify(value)
      );

      if (existingIndex !== -1) {
        state.filters[key] = state.filters[key].filter(
          (v) => JSON.stringify(v) !== JSON.stringify(value)
        );
      } else {
        state.filters[key].push(value);
      }

      if (state.filters[key].length === 0) {
        delete state.filters[key];
      }
    },

    clearObjectiveFilters(state) {
      state.filters = {};
    },
    setObjectiveSortOption(state, action) {
      state.sortOption = action.payload;
    },
    setObjectiveActiveProperties(state, action) {
      const { filters, groupByOption, sortOption, selectedColumns } =
        action.payload;

      const finalFilters = isObject(filters)
        ? filters
        : transformFiltersToObject(filters);

      state.filters = finalFilters || state.filters;
      state.groupByOption = groupByOption || state.groupByOption;
      state.sortOption = sortOption || state.sortOption;
      state.selectedColumns = selectedColumns || state.selectedColumns;
    },
    setObjectiveViews(state, action) {
      const { views, viewTypeName } = action.payload;
      if (
        viewTypeName === "classiclist" ||
        viewTypeName === "modernlist" ||
        viewTypeName === "list"
      ) {
        state.listViews = views;
      } else {
        state.kanbanViews = views;
      }
      state.isViewsLoading = false;
    },
    appendObjectiveView(state, action) {
      const { newlyAddedView, viewTypeName } = action.payload;
      if (
        viewTypeName === "list" ||
        viewTypeName === "modernlist" ||
        viewTypeName === "classiclist"
      ) {
        state.listViews.push(newlyAddedView);
      } else {
        state.kanbanViews.push(newlyAddedView);
      }
    },
    removeObjectiveView(state, action) {
      const { deletedViewId, viewTypeName } = action.payload;
      let index = -1;
      if (
        viewTypeName === "list" ||
        viewTypeName === "modernlist" ||
        viewTypeName === "classiclist"
      ) {
        index = state.listViews.findIndex((view) => view._id === deletedViewId);
        if (index !== -1) {
          state.listViews.splice(index, 1);
        }
      } else {
        index = state.kanbanViews.findIndex(
          (view) => view._id === deletedViewId
        );
        if (index !== -1) {
          state.kanbanViews.splice(index, 1);
        }
      }
    },
    updateObjectiveView(state, action) {
      const { updatedView, viewTypeName } = action.payload;
      let index = -1;
      if (
        viewTypeName === "list" ||
        viewTypeName === "modernlist" ||
        viewTypeName === "classiclist"
      ) {
        index = state.listViews.findIndex(
          (view) => view._id === updatedView._id
        );
        if (index !== -1) {
          state.listViews[index] = updatedView;
        }
      } else {
        index = state.kanbanViews.findIndex(
          (view) => view._id === updatedView._id
        );
        if (index !== -1) {
          state.kanbanViews[index] = updatedView;
        }
      }
    },
    setObjectiveGroupByOption(state, action) {
      state.groupByOption = action.payload;
    },
    setObjectiveViewType(state, action) {
      state.viewType = action.payload;
      state.isViewsLoading = false;
    },
    setActiveObjectiveId(state, action) {
      state.activeObjectiveId = action.payload;
    },
    setObjectiveShowDetailViewDrawer(state, action) {
      state.showDetailViewDrawer = action.payload;
    },
    setIsObjectiveLoading(state, action) {
      state.isLoading = action.payload;
    },
    setIsObjectiveViewLoading(state, action) {
      state.isViewsLoading = action.payload;
    },
    updateObjectiveStatus(state, action) {
      const updatedStatus = action.payload;

      state.objectives?.forEach((objective) => {
        if (objective?.status?._id === updatedStatus._id) {
          objective.status = updatedStatus;
        }
      });
    },
    replaceObjectiveStatus(state, action) {
      const { statusId, replaceStatus } = action.payload;

      state.objectives?.forEach((objective) => {
        if (objective?.status?._id === statusId) {
          objective.status = replaceStatus;
        }
      });
    },
  },
});

export const {
  setObjectives,
  setObjectiveProgress,
  setObjectiveRoadmaps,
  updateObjective,
  appendObjective,
  removeObjective,
  removeObjectives,
  updateObjectives,
  toggleObjectiveColumn,
  setObjectiveFilters,
  updateObjectiveFilter,
  clearObjectiveFilters,
  setObjectiveSortOption,
  setObjectiveActiveProperties,
  setObjectiveViews,
  updateObjectiveView,
  removeObjectiveView,
  appendObjectiveView,
  setObjectiveGroupByOption,
  setObjectiveViewType,
  setActiveObjectiveId,
  setObjectiveShowDetailViewDrawer,
  setIsObjectiveLoading,
  setIsObjectiveViewLoading,
  updateObjectiveStatus,
  replaceObjectiveStatus,
} = objectivesSlice.actions;

export const objectivesReducer = objectivesSlice.reducer;

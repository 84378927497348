/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from "react";
import IconText from "components/common/IconText";
import { fieldIconMapping } from "utils";
import {
  AngleSmallDown,
  AngleSmallUp,
  Copy,
  MenuDotsVertical,
  Trash,
} from "react-flaticons";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import DropdDownItem from "components/common/DropdownItem";
import {
  deleteIdea,
  convertIdeaToFeature,
} from "reduxStore/operations/ideasAPI";
import { SiConvertio } from "react-icons/si";

const IdeaDetailViewTopbar = ({
  idea,
  onNext,
  onPrev,
  onTabClick,
  activeTab,
  updateIdeaData,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [opentMenuDotsDropdown, setOpenMenuDotsDropdown] = useState(false);
  const [isIdeaNameEditable, setIsIdeaNameEditable] = useState(false);
  const [title, setTitle] = useState(idea.title);
  const [linked, setLinked] = useState(idea.features.lenth);

  const features = useSelector((state) => state.features);
  const ideas = useSelector((state) => state.ideas);

  useEffect(() => {
    // Find the active idea
    const activeIdea = ideas.ideas.find(
      (idea) => idea._id === ideas.activeIdeaId
    );

    if (activeIdea && activeIdea.features) {
      // Check for matches between activeIdea.features and features by _id
      const matches = activeIdea.features.filter((activeFeature) => {
        return features.features.some(
          (feature) => feature._id === activeFeature
        );
      });
      setLinked(matches.length);
    }
  }, [features]);

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const saveTitle = () => {
    setIsIdeaNameEditable(false);
    if (title !== idea.title && title.trim() !== "") {
      updateIdeaData(title);
    } else {
      setTitle(idea.title);
    }
  };

  useEffect(() => {
    setTitle(idea.title);
  }, [idea.title]);

  const dropdownRef = useRef();
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setOpenMenuDotsDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <div className="flex items-center gap-x-2">
        <IconText
          icon={React.createElement(fieldIconMapping.idea)}
          text="Idea"
          iconColor={"text-ideas-icon"}
          isASidebarComponent={false}
          iconSize={12}
          textSize="text-xs"
          hoverable={false}
          px=""
        />
        <div className="flex">
          <button className="bg-[#ECECEC] p-0.5" onClick={onPrev}>
            <AngleSmallUp size={14} color="#696969" />
          </button>
          <button className="bg-[#ECECEC] p-0.5" onClick={onNext}>
            <AngleSmallDown size={14} color="#696969" />
          </button>
        </div>
      </div>
      <div className="flex gap-x-5 items-center text-primary">
        <div className="w-full rounded">
          {isIdeaNameEditable ? (
            <input
              type="text"
              value={title}
              onChange={handleTitleChange}
              onBlur={saveTitle}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  saveTitle();
                }
              }}
              className="rounded text-xl text-title_50 font-medium w-full focus:outline-none focus:border-b"
              autoFocus
            />
          ) : (
            <div
              className="border-b border-white text-xl text-title_50 font-medium cursor-text"
              onClick={() => setIsIdeaNameEditable(true)}
            >
              {title}
            </div>
          )}
        </div>
        <div className="flex gap-x-2 items-center">
          <Copy size={12} />
          <div
            className={`relative p-1 rounded-full hover:bg-topbar-button-hover cursor-pointer ${
              opentMenuDotsDropdown && "bg-button-active_10"
            }`}
            onClick={() => {
              setOpenMenuDotsDropdown(!opentMenuDotsDropdown);
            }}
            ref={dropdownRef}
          >
            <MenuDotsVertical size={12} />
            {opentMenuDotsDropdown && (
              <div className="absolute right-0 text-sm p-1 w-40 bg-white rounded border shadow-md mt-0.5">
                <DropdDownItem
                  option={{ label: "Delete", icon: <Trash /> }}
                  textSize="text-xs"
                  handleOptionClick={() => {
                    dispatch(deleteIdea(idea._id, navigate));
                  }}
                />
                <DropdDownItem
                  option={{
                    label: "Promote to feature",
                    icon: <SiConvertio />,
                  }}
                  textSize="text-xs"
                  truncateLength={18}
                  textbreak={true}
                  handleOptionClick={() => {
                    dispatch(convertIdeaToFeature(idea._id, navigate));
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="flex gap-x-8 mt-5 text-primary text-sm">
        <div
          className={` ${
            activeTab === "Overview" &&
            "border-b-2 border-button-active text-title font-[500]"
          } pb-2  cursor-pointer`}
          onClick={() => {
            onTabClick("Overview");
          }}
        >
          Overview
        </div>
        <div
          className={` ${
            activeTab === "Features" &&
            "border-b-2 border-button-active text-title font-[500]"
          } pb-2 cursor-pointer`}
          onClick={() => {
            onTabClick("Features");
          }}
        >
          Linked Features
          {idea.features.length > 0 && (
            <span className="ml-1 rounded-full px-1.5 py-0.5 bg-button-active_10">
              {linked}
            </span>
          )}
        </div>
      </div>
      <hr className="mb-2.5" />
    </>
  );
};

export default IdeaDetailViewTopbar;

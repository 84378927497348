import React, { useEffect } from "react";
import { EditorContent, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import BubbleMenuComponent from "./BubbleMenuComponent";
import FloatingMenuComponent from "./FloatingMenuComponent";
import Document from "@tiptap/extension-document";
import Paragraph from "@tiptap/extension-paragraph";
import Text from "@tiptap/extension-text";
import Underline from "@tiptap/extension-underline";
import Highlight from "@tiptap/extension-highlight";
import TextAlign from "@tiptap/extension-text-align";
import OrderedList from "@tiptap/extension-ordered-list";
import BulletList from "@tiptap/extension-bullet-list";
import "./style.css";
import ResizableImage from "./ResizableImage";

const Editor = ({ content, onChange }) => {
  const editor = useEditor({
    extensions: [
      StarterKit,
      Document,
      Paragraph,
      Text,
      Underline,
      Highlight,
      TextAlign.configure({
        types: ["heading", "paragraph"],
      }),
      OrderedList,
      BulletList,
      ResizableImage.configure({
        inline: false, 
        HTMLAttributes: {
          class: "custom-image-class",
        },
      }),
    ],
    editorProps: {
      attributes: {
        class:
          "prose prose-sm sm:prose lg:prose-lg xl:prose-2xl mx-auto focus:outline-none leading-tight ankur",
      },
    },
    content: content,
  });

  useEffect(() => {
    if (editor) {
      const saveContent = () => {
        const updatedContent = editor.getHTML();
        onChange(updatedContent);
      };

      editor.on("update", saveContent);

      return () => {
        editor.off("update", saveContent);
      };
    }
  }, [editor]);

  useEffect(() => {
    if (editor && content !== editor.getHTML()) {
      editor.commands.setContent(content);
    }
  }, [content, editor]);

  return (
    <>
      <BubbleMenuComponent editor={editor} />
      <FloatingMenuComponent editor={editor} />
      <EditorContent editor={editor} />
    </>
  );
};

export default Editor;

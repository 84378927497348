import React, { useState } from "react";
import { useSelector } from "react-redux";
import { userEndPoints } from "../../../api/api";
import { apiConnector } from "api/apiconnector";
import { toast } from "react-toastify";

const { RE_USERS } = userEndPoints;

const InvitedUsers = () => {
  const { invitedUsers } = useSelector((state) => state.invitedUsers);
  const [loadingEmail, setLoadingEmail] = useState(null); // Track loading state for each email

  const handleReInvite = async (email) => {
    setLoadingEmail(email); // Set loading state to the email being processed
    const data = { email: email };

    try {
      const response = await apiConnector("POST", RE_USERS, data);
      toast.success(`Invite sent to ${email}`);
    } catch (error) {
      console.log(error);
      toast.error(error.response?.data?.message ?? "Failed to re-invite user.");
    } finally {
      setLoadingEmail(null); // Reset loading state
    }
  };

  return (
    <div>
      {invitedUsers && invitedUsers.length > 0 ? (
        <div className="w-[60%]">
          <table className="min-w-full table-auto">
            <thead>
              <tr className="text-sm">
                <th className="text-primary text-start pr-1 px-3 font-normal w-[24rem]   ">
                  Email
                </th>
                <th className="text-primary text-start pr-1 px-3 font-normal">
                  {" "}
                </th>
              </tr>
            </thead>
            <tbody>
              {invitedUsers.map((user, index) => (
                <tr
                  key={index}
                  className="text-gray-700 text-[14px] rounded relative"
                >
                  <td className="truncate pr-1 px-3 py-2">{user.email}</td>
                  <td className="truncate pr-1 px-3 py-2 ">
                    {loadingEmail === user.email ? (
                      <span className="text-blue-500">Sending...</span>
                    ) : (
                      <span
                        className="cursor-pointer text-button-active  hover:underline"
                        onClick={() => handleReInvite(user.email)}
                      >
                        Resend invite
                      </span>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : ( 
        <div className="flex justify-center items-center h-[80vh]">
          <p className="text-lg text-gray-500">There are no invited users.</p>
        </div>
      )}
    </div>
  );
};

export default InvitedUsers;

import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { MenuDots } from "react-flaticons";

import IconText from "components/common/IconText";
import { fieldIconMapping } from "utils";
import { removeViewToFavorite } from "../../../reduxStore/operations/viewsAPI";
import EditDeleteDropdown from "../topbar/dropdowns/EditDeleteDropdown";
// import { setFeatureViewType } from "reduxStore/slices/featureSlice";
// import { setIdeaViewType } from "reduxStore/slices/ideaSlice";
// import { setObjectiveViewType } from "reduxStore/slices/objectiveSlice";

const FavoriteView = ({ view, isSearch = false }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const editViewDropdownRef = useRef(null);
  const [isHovered, setIsHovered] = useState(false);
  const [editViewDropdownId, setEditViewDropdownId] = useState(null);
  const searchParams = new URLSearchParams(location.search);
  const viewname = searchParams.get("view");
  const isFavoriteActive =
    (searchParams.get("favorite") === "true" && view._id === viewname) ||
    (view.module === "roadmapId" &&
      pathname.includes("/roadmaps/") &&
      pathname.split("/roadmaps/")[1] === view.roadmapId);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        editViewDropdownRef.current &&
        !editViewDropdownRef.current.contains(event.target)
      ) {
        setEditViewDropdownId(null);
        setIsHovered(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleFavoriteViewClick = () => {
    setEditViewDropdownId(null);
    setIsHovered(false);
    // Dispatch logic here
    // ...
    if (view.module === "roadmapId") {
      navigate(`/roadmaps/${view.roadmapId}`);
    } else {
      navigate(
        `/${view?.module + "s"}?viewType=${view?.viewType}&view=${
          view._id
        }&favorite=true`
      );
    }
  };

  return (
    <div
      className={`flex items-center justify-between cursor-pointer hover:bg-topbar-button-hover rounded ${
        isFavoriteActive ? "bg-topbar-button-hover" : ""
      } `}
      onMouseEnter={() => editViewDropdownId !== view._id && setIsHovered(true)}
      onMouseLeave={() =>
        editViewDropdownId !== view._id && setIsHovered(false)
      }
      onClick={() => {
        handleFavoriteViewClick();
      }}
      ref={editViewDropdownRef}
    >
      <IconText
        icon={
          view.module === "roadmapId"
            ? React.createElement(fieldIconMapping.roadmap)
            : React.createElement(fieldIconMapping[view?.module])
        }
        text={view.label}
        truncateLength={18}
        py="py-1"
        hoverColor={"hover:bg-topbar-button-hover"}
        isASidebarComponent={true}
        isTextActive={isFavoriteActive}
        iconColor={isFavoriteActive}
      />
      {isHovered && !isSearch && (
        <div
          className={`relative p-1.5 mr-1 ${
            editViewDropdownId === view._id
              ? "bg-button-active_10"
              : "hover:bg-button-hover"
          } cursor-pointer rounded-full`}
          onClick={(e) => {
            e.stopPropagation();
            if (editViewDropdownId === view._id) {
              setEditViewDropdownId(null);
            } else {
              setEditViewDropdownId(view._id);
            }
          }}
        >
          {/* Replace MenuDots with your new dropdown */}
          <MenuDots size={12} />
          {editViewDropdownId === view._id && (
            <div className="absolute bg-white top-full border-2 rounded-lg right-0 shadow-lg z-10">
              <EditDeleteDropdown
                isAFavoriteView={true}
                roadmapId={view.roadmapId}
                handleFavoriteClick={() => {
                  dispatch(removeViewToFavorite(view._id));
                }}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default FavoriteView;

import React, { useMemo, useState, useEffect, useRef } from "react";
import { fieldIconMapping, truncateText } from "utils";
import IconText from "components/common/IconText";
import Progress from "components/common/Progress";
import AssigneeSection from "../../../common/detailView/detailViewRightSidebarSections/AssigneeSection";
import EditDeleteDropdown from "../../../core/topbar/dropdowns/EditDeleteDropdown";
import { useDispatch, useSelector } from "react-redux";
import { MenuDots } from "react-flaticons";
import {
  setActiveKeyResultId,
  setKeyResultShowDetailViewDrawer,
} from "reduxStore/slices/keyResultSlice";
import { removeObjectiveKeyResults } from "reduxStore/operations/objectivesAPI";
import { updateKeyResultData } from "../../../../reduxStore/operations/keyResultsAPI";
import { getKeyResultProgress } from "utils/progressUtils";
import { getHealthBgColor } from "utils/objectiveUtils";

const ObjectiveDetailViewKeyResultRowComponent = ({ objective }) => {
  const dispatch = useDispatch();
  const { features, keyResults, ideas, users } = useSelector((state) => ({
    features: state.features.features,
    keyResults: state.keyResults.keyResults,
    ideas: state.ideas.ideas,
    users: state.users.users,
  }));
  const [dotDropDown, setDropDown] = useState(null); // Tracks the open dropdown by keyResult._id
  const dropdownRefs = useRef({}); // Store individual refs for each dropdown

  const objectiveKeyResults = useMemo(() => {
    return (keyResults ?? []).filter((keyResult) =>
      (objective?.keyResults ?? []).includes(keyResult._id)
    );
  }, [objective?.keyResults, keyResults]);

  const handleOutsideClick = (event) => {
    // Check if the clicked element is outside any open dropdown
    if (
      dotDropDown &&
      dropdownRefs.current[dotDropDown] &&
      !dropdownRefs.current[dotDropDown].contains(event.target)
    ) {
      setDropDown(null);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [dotDropDown]);

  const HandleItemRemove = (krId) => {
    dispatch(removeObjectiveKeyResults(objective._id, [krId]));
  };

  return (
    <>
      {(objectiveKeyResults ?? []).map((keyResult) => {
        const keyResultIdeas = (ideas ?? []).filter((idea) =>
          (keyResult?.ideas ?? []).includes(idea._id)
        );
        const keyResultFeatures = (features ?? []).filter((feature) =>
          (keyResult?.features ?? []).includes(feature._id)
        );

        const keyResultProgress = getKeyResultProgress(
          keyResultIdeas,
          keyResultFeatures
        );

        return (
          <div
            key={keyResult._id}
            className="flex items-center justify-between p-1 hover:bg-row-background rounded cursor-pointer group"
            onClick={() => {
              dispatch(setActiveKeyResultId(null));
              dispatch(setKeyResultShowDetailViewDrawer(true));
              dispatch(setActiveKeyResultId(keyResult._id));
            }}
          >
            <IconText
              icon={React.createElement(fieldIconMapping.key_results)}
              iconColor={"text-key_results-icon"}
              text={truncateText(keyResult.title, 40)}
              hoverable={false}
              px=""
              py=""
              gap_x="gap-x-2"
            />
            <div className="flex items-center gap-x-2">
              <Progress
                value={keyResultProgress}
                color={getHealthBgColor(keyResult?.health)}
              />
              <AssigneeSection
                item={keyResult}
                assignee={keyResult.assignee}
                itemLabel={keyResult?.assignee?.name ?? ""}
                assigneesArray={users}
                dropdownMenuPosition="top-full right-2 "
                handleAssineeOptionClick={(option) => {
                  if (keyResult?.assignee?._id !== option._id) {
                    const { icon, ...restOption } = option;
                    dispatch(
                      updateKeyResultData(
                        keyResult._id,
                        { assignee: option._id },
                        "assignee",
                        keyResult,
                        restOption
                      )
                    );
                  }
                }}
                with_title={false}
                with_text={false}
                textLength={4}
              />
              <div
                ref={(el) => (dropdownRefs.current[keyResult._id] = el)} // Assign ref for this dropdown
                className="rounded-full p-1 relative hover:bg-topbar-button-hover "
                onClick={(e) => {
                  e.stopPropagation();
                  setDropDown(dotDropDown === keyResult._id ? null : keyResult._id);
                }}
              >
                <MenuDots size={12} />
                {dotDropDown === keyResult._id && (
                  <div className="z-50 absolute right-[-15px] top-7 bg-white rounded-lg shadow-sm">
                    <EditDeleteDropdown
                      isKr={true}
                      krId={keyResult._id}
                      HandleItemRemove={HandleItemRemove}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default ObjectiveDetailViewKeyResultRowComponent;

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  previousUrl: null, // Stores the URL before navigating to settings
};

const previousUrlSlice = createSlice({
  name: "previousUrl",
  initialState,
  reducers: {
    setPreviousUrl: (state, action) => {
      state.previousUrl = action.payload;
    },
    clearPreviousUrl: (state) => {
      state.previousUrl = null;
    },
  },
});

// Export the actions
export const { setPreviousUrl, clearPreviousUrl } = previousUrlSlice.actions;

// Export the reducer for integration into the rootReducer
export const previousUrlReducer = previousUrlSlice.reducer;

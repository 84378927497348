/* eslint-disable react/prop-types */
import React, { useState, useEffect, useRef } from "react";
import QuillEditor from "components/common/QuillEditor";
import ObjectiveDetailViewTopbar from "./ObjectiveDetailViewTopbar";
import { fieldIconMapping } from "utils";
import { useDispatch, useSelector } from "react-redux";
import {
  setActiveObjectiveId,
  setObjectiveShowDetailViewDrawer,
} from "reduxStore/slices/objectiveSlice";
import { updateObjectiveData } from "reduxStore/operations/objectivesAPI";
import DOMPurify from "dompurify";
import DetailViewHeader from "components/common/detailView/DetailViewHeader";
import DescriptionDetailViewTab from "components/common/detailView/DescriptionDetailViewTab";
import { isEmptyDescription } from "utils/descriptionUtils";
import InitiativeBasedDetailSection from "../typeBasedSections/InitiativeBasedDetailSection";
import KeyResultsBasedDetailSection from "../typeBasedSections/KeyResultsBasedDetailSection";
import {
  removeObjectiveFeatures,
  removeObjectiveIdeas,
  updateObjectiveFeatures,
  updateObjectiveIdeas,
} from "../../../reduxStore/operations/objectivesAPI";

import Editor from "../../../tiptapEditor/Index";

const ObjectiveDetailViewComponent = ({
  item,
  showBreadcrumb = false,
  onNext = () => {},
  onPrev = () => {},
  shouldNavigate = true,
}) => {
  const dispatch = useDispatch();
  const { activeObjectiveId, showDetailViewDrawer } = useSelector(
    (state) => state.objectives
  );
  const [isQuillEditorActive, setIsQuillEditorActive] = useState(
    // isEmptyDescription(item?.description ?? "")
    false
  );
  const handleEditorClose = () => {
    setIsQuillEditorActive(false);
  };

  const handleEditorOpen = () => {
    setIsQuillEditorActive(true);
  };

  const [selectedIdeas, setSelectedIdeas] = useState(new Set());

  const handleIdeaSelect = (idea) => {
    setSelectedIdeas((prevSelected) => {
      const newSelected = new Set(prevSelected);
      if (newSelected.has(idea._id)) {
        newSelected.delete(idea._id);
      } else {
        newSelected.add(idea._id);
      }
      return newSelected;
    });
  };

  const removeSelectedIdeas = () => {
    setSelectedIdeas(new Set());
  };

  const [selectedFeatures, setSelectedFeatures] = useState(new Set());

  const handleFeatureSelect = (feature) => {
    setSelectedFeatures((prevSelected) => {
      const newSelected = new Set(prevSelected);
      if (newSelected.has(feature._id)) {
        newSelected.delete(feature._id);
      } else {
        newSelected.add(feature._id);
      }
      return newSelected;
    });
  };

  const removeSelectedFeatures = () => {
    setSelectedFeatures(new Set());
  };

  const handleFormSubmit = (content) => {
    // Sanitize the HTML content
    const sanitizedContent = DOMPurify.sanitize(content);
    dispatch(
      updateObjectiveData(
        item._id,
        { description: sanitizedContent },
        "description"
      )
    );
    setIsQuillEditorActive(false);
  };

  const [editorContent, setEditorContent] = useState("");
  const editorRef = useRef(null);

  useEffect(() => {
    setEditorContent(item.description);
  }, [item]);

  const handleSave = () => {
    dispatch(
      updateObjectiveData(
        item._id,
        { description: editorContent },
        "description"
      )
    );
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (editorRef.current && !editorRef.current.contains(event.target)) {
        if (editorContent !== item.description) {
          handleSave();
        }
      }
    };
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleSave]);

  return (
    <>
      {!item ? (
        <div className="w-full h-full m-auto">Loading....</div>
      ) : (
        <div className="flex flex-col h-full pb-5">
          <div className="flex-none pt-4 px-4 pb-7 text-primary sticky top-0 bg-white z-40">
            <DetailViewHeader
              type="Objective"
              item={item}
              showBreadcrumb={showBreadcrumb}
              showDetailViewDrawer={showDetailViewDrawer}
              activeItem={activeObjectiveId}
              setShowDetailViewDrawer={setObjectiveShowDetailViewDrawer}
              setActiveItemId={setActiveObjectiveId}
              Icon={fieldIconMapping.objective}
              shouldNavigate={shouldNavigate}
            />
          </div>

          <div className="flex-none px-6">
            <ObjectiveDetailViewTopbar
              objective={item}
              onNext={onNext}
              onPrev={onPrev}
              updateObjectiveData={(title) =>
                dispatch(
                  updateObjectiveData(item._id, { title: title }, "title")
                )
              }
            />
          </div>
          <div className="h-full w-full px-6 pt-3 text-primary overflow-auto custom-scrollbar">
            {/* {isQuillEditorActive ? (
              <QuillEditor
                initialContent={item.description}
                onClose={handleEditorClose}
                handleFormSubmit={handleFormSubmit}
              />
            ) : (
              <DescriptionDetailViewTab
                item={item}
                handleEditorOpen={handleEditorOpen}
              />
            )} */}

            <div ref={editorRef}>
              <Editor
                content={item.description}
                onChange={(content) => setEditorContent(content)}
              />
            </div>

            {item?.type === "Key_result_based" && (
              <KeyResultsBasedDetailSection
                objective={item}
                selectedIdeas={selectedIdeas}
                handleIdeaSelect={handleIdeaSelect}
                removeSelectedIdeas={removeSelectedIdeas}
                selectedFeatures={selectedFeatures}
                handleFeatureSelect={handleFeatureSelect}
                removeSelectedFeatures={removeSelectedFeatures}
              />
            )}
            {item?.type === "Initiative_based" && (
              <InitiativeBasedDetailSection
                item={item}
                selectedIdeas={selectedIdeas}
                handleIdeaSelect={handleIdeaSelect}
                removeSelectedIdeas={removeSelectedIdeas}
                selectedFeatures={selectedFeatures}
                handleFeatureSelect={handleFeatureSelect}
                removeSelectedFeatures={removeSelectedFeatures}
                updateFeatures={(features) => {
                  dispatch(updateObjectiveFeatures(item._id, features));
                }}
                updateIdeas={(ideas) => {
                  dispatch(updateObjectiveIdeas(item._id, ideas));
                }}
                removeFeatures={(features) => {
                  dispatch(removeObjectiveFeatures(item._id, features));
                }}
                removeIdeas={(ideas) => {
                  dispatch(removeObjectiveIdeas(item._id, ideas));
                }}
              />
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default ObjectiveDetailViewComponent;

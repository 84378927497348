/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { CrossSmall, Following, User, Users } from "react-flaticons";
import { apiConnector } from "../../api/apiconnector";
import { userEndPoints } from "../../api/api";
import { toast } from "react-toastify";
const { INVITE_USER } = userEndPoints;

const InviteUserPopup = ({ onClose, onSubmit }) => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false); // Loading state

  const isEmailValid = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmit = async () => {
    if (!isEmailValid(email)) {
      setError(true);
      // Show error for 3 seconds, then clear it
      setTimeout(() => setError(false), 3000);
      return;
    }

    setLoading(true); // Set loading to true

    try {
      const response = await apiConnector("POST", INVITE_USER, { email });

      if (response.status === 201 && response.data) {
        toast.success("User invited successfully!");
        onClose();
      } else {
        toast.error("Failed to invite the user. Please try again.");
      }
    } catch (error) {
      toast.error(
        error?.response?.data?.message ??
          "An error occurred while inviting the user. Please try again later."
      );
      console.error("Error inviting user:", error);
    } finally {
      setLoading(false); // Set loading to false
    }
  };

  const handleOverlayClick = (e) => {
    e.stopPropagation();
  };

  return (
    <div
      className="fixed inset-0 bg-add-popup-background bg-opacity-50 flex justify-center z-50"
      onClick={onClose}
    >
      <div
        className="border border-gray-300 h-fit top-24 bg-white p-5 rounded-md relative min-w-[400px] w-auto max-w-2xl"
        onClick={handleOverlayClick}
      >
        <div className="flex flex-col">
          <div className="flex justify-between pb-1">
            <h2 className="flex gap-2 items-center text-gray-600 pt-1 pb-2">
              <Users size={18} />
              <span>Invite</span>
            </h2>
            <button
              onClick={onClose}
              className="self-start rounded p-0.5 hover:bg-button-hover"
            >
              <CrossSmall color="#374957" />
            </button>
          </div>
          <div className="flex flex-col gap-2">
            <input
              type="email"
              placeholder={error ? "Enter a valid email" : "Email"}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className={`w-full text-[16px] py-2 px-1 rounded-sm focus:outline-none ${
                error ? "shadow-red-400 shadow-[0px_0px_3px_1px]" : ""
              }`}
            />
          </div>
        </div>
        <div className="w-[100%] h-[1px] bg-gray-200 mt-3 mb-2"></div>
        <div className="text-end">
          <button
            onClick={handleSubmit}
            className={`p-2 bg-add-popup-button rounded text-white w-fit text-sm ml-auto ${
              loading ? "opacity-50 cursor-not-allowed" : ""
            }`}
            disabled={loading} // Disable button during loading
          >
            {loading ? "Inviting..." : "Invite"} {/* Show loading text */}
          </button>
        </div>
      </div>
    </div>
  );
};

export default InviteUserPopup;

/* eslint-disable react/prop-types */
import DropdownItem from "components/common/DropdownItem";
import CheckboxDropdownItem from "components/common/CheckboxDropdownItem";
import { toSnakeCase, rgbColor } from "utils";
import "./FilterOptionsDropdown.css";
import IconText from "../../../common/IconText";
import { CrossSmall } from "react-flaticons";

const FilterOptionsDropdown = ({
  filterOptions = [],
  selectedFilterOption = null,
  subFilterDropdownOptions = null,
  handleFilterOptionClick = () => {},
  handleSubFilterOptionClick = () => {},
  handleResetFilters = () => {},
  filters = {},
  position = "right-[7.4rem] top-full",
  handleRemoveFilter = () => {},
}) => {
  const snakeCaseFilterKey = (label) => toSnakeCase(label);

  const getIconColor = (option, subOption) => {
    const textOption = option?.subOptions?.find(
      (item) => item?._id === subOption?._id || item.label === subOption.label
    );
    return textOption?.iconColor ? rgbColor(textOption?.iconColor) : "" ?? "";
  };

  const getIcon = (option, selectedSubOptions) => {
    if (selectedSubOptions?.length === 1) {
      const selectedSubOption = option?.subOptions?.find(
        (subOption) =>
          subOption._id === selectedSubOptions[0]._id &&
          subOption.label === selectedSubOptions[0].label
      );
      return selectedSubOption?.icon || option?.icon;
    }
    return option?.icon;
  };

  const hasFilters = Object.values(filters).some(
    (filter) => filter?.length > 0
  );

  const getTextBgColor = (option, subOption) => {
    const textOption = option?.subOptions?.find(
      (item) => item?._id === subOption?._id && item.label === subOption.label
    );
    return (textOption?.withBackgroundColor ?? true) && textOption?.iconColor
      ? rgbColor(textOption?.iconColor, 0.2)
      : "" ?? "";
  };

  const getOptionLabel = (option, subOptions) => {
    const selectedSubOptions =
      filters[snakeCaseFilterKey(option?.label || option?.name)];
    if (
      selectedSubOptions?.length > 0 &&
      (option?.withMoreWidth ?? false) === false
    ) {
      for (let i = 0; i < selectedSubOptions.length; i++) {
        const matchedSubOption = subOptions?.find((subOption) => {
          return (
            (subOption._id === selectedSubOptions[i]._id && subOption.label) ||
            option?.name === selectedSubOptions[i].label ||
            option?.name
          );
        });

        if (matchedSubOption) {
          return i === selectedSubOptions.length - 1
            ? selectedSubOptions[i].label || option?.name
            : option?.label || option?.name;
        }
      }
    }

    return option?.label || option?.name;
  };

  return (
    <div
      className={`absolute bg-white min-w-36 rounded-lg py-2 px-1 mx-1 border-2 shadow-lg ${position}`}
    >
      <ul>
        {(filterOptions ?? []).map((option, index) => {
          const key = snakeCaseFilterKey(option?.label);
          const selectedSubOptions = filters[key] || [];

          const isOptionSelected = selectedSubOptions?.length > 0;
          const subOptions = filterOptions
            ?.filter(
              (item) => snakeCaseFilterKey(item?.label || item?.name) === key
            )
            ?.flatMap((option) => option?.subOptions);

          return (
            <li
              key={index}
              className={`${
                isOptionSelected ? "border border-title rounded mb-1.5" : ""
              } ${
                selectedFilterOption?.label === option?.label &&
                !isOptionSelected
                  ? "bg-button-active_10 rounded"
                  : ""
              }`}
            >
              <DropdownItem
                option={{
                  ...option,
                  label: getOptionLabel(option, subOptions),
                  icon: getIcon(option, selectedSubOptions),
                }}
                handleOptionClick={() => handleFilterOptionClick(option)}
                textSize="text-sm"
                iconSize={14}
                iconColor={
                  selectedSubOptions?.length === 1
                    ? getIconColor(option, selectedSubOptions[0])
                    : "#696969"
                }
                selectedSubOptionLength={selectedSubOptions?.length}
                textBgColor={
                  selectedSubOptions?.length === 1
                    ? getTextBgColor(option, selectedSubOptions[0])
                    : ""
                }
                gap_x={selectedSubOptions?.length === 1 ? "gap-x-2" : "gap-x-3"}
              />
              {selectedFilterOption?.label === option?.label && (
                <ul
                  className={`${
                    option?.withMoreWidth
                      ? "w-96"
                      : option.withWidth
                      ? option.withWidth
                      : "w-40"
                  } custom-scrollbar absolute bg-white rounded-lg py-1 mx-1 border-2 right-[95%] -mt-8 shadow-lg max-h-[20rem] overflow-y-auto`}
                >
                  {(subFilterDropdownOptions?.subOptions ?? []).map(
                    (subOption, subIndex) => (
                      <li key={subIndex}>
                        <div className="flex p-1">
                          <CheckboxDropdownItem
                            option={subOption}
                            handleOptionClick={() =>
                              handleSubFilterOptionClick(subOption)
                            }
                            selected={selectedSubOptions?.find(
                              (item) =>
                                item?._id === subOption?._id &&
                                item?.label ===
                                  (subOption?.label || subOption.name)
                            )}
                            isImportanceType={option.label === "Importance"}
                            truncateLength={30}
                            isAssignee={
                              option.label === "Assignee" ? true : false
                            }
                          />
                        </div>
                      </li>
                    )
                  )}
                </ul>
              )}
            </li>
          );
        })}
        {hasFilters && (
          <>
            <hr className="my-1 mx-auto w-5/6" />
            <IconText
              icon={<CrossSmall />}
              text={"Clear"}
              textColor="text-title"
              iconColor="text-title"
              hoverColor="hover:bg-topbar-button-hover    "
              onClick={handleResetFilters}
              isClear={true}
            />
          </>
        )}
      </ul>
    </div>
  );
};

export default FilterOptionsDropdown;

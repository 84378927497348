import { createSlice } from "@reduxjs/toolkit";

// Initial state for favorite views
const initialState = {
  favoriteViews: [],
};

// Create a slice for favorite views
const favoriteViewSlice = createSlice({
  name: "favoriteView",
  initialState,
  reducers: {
    setFavoriteViews(state, action) {
      state.favoriteViews = action.payload;
    },
    appendFavoriteView(state, action) {
      const favoriteView = action.payload;
      const index = state.favoriteViews.findIndex(
        (view) => view._id === favoriteView._id
      );
      if (index === -1) {
        state.favoriteViews.push(action.payload);
      }
    },
    removeFavoriteView(state, action) {
      const removeViewId = action.payload;
      const index = state.favoriteViews.findIndex(
        (view) => view._id === removeViewId
      );
      if (index !== -1) {
        state.favoriteViews.splice(index, 1);
      }
    },
    reorderFavoriteViews(state, action) {
      const { sourceIndex, destinationIndex } = action.payload;

      // Reorder the array
      const updatedViews = [...state.favoriteViews];
      const [movedItem] = updatedViews.splice(sourceIndex, 1); // Remove item from source index
      updatedViews.splice(destinationIndex, 0, movedItem); // Insert item at destination index

      state.favoriteViews = updatedViews;
    },
    updateFavoriteView(state, action) {
      const { updatedView } = action.payload;
      const index = state.favoriteViews.findIndex(
        (view) => view._id === updatedView._id
      );
      if (index !== -1) {
        state.favoriteViews[index] = updatedView;
      }
    },
  },
});

// Export actions to use in components
export const {
  setFavoriteViews,
  removeFavoriteView,
  appendFavoriteView,
  updateFavoriteView,
  reorderFavoriteViews,
} = favoriteViewSlice.actions;

// Export the reducer to be included in the store
export const favoriteViewReducer = favoriteViewSlice.reducer;

/* eslint-disable react/prop-types */
import React, { useState, useRef, useEffect } from "react";
import QuillEditor from "components/common/QuillEditor";
import FeatureDetailViewTopbar from "./FeatureDetailViewTopbar";
import { fieldIconMapping } from "utils";
import { useDispatch, useSelector } from "react-redux";
import {
  setActiveFeatureId,
  setFeatureShowDetailViewDrawer,
} from "reduxStore/slices/featureSlice";
import {
  updateFeatureData,
  updateFeatureIdeas,
} from "reduxStore/operations/featuresAPI";
import DOMPurify from "dompurify";
import DetailViewHeader from "components/common/detailView/DetailViewHeader";
import DescriptionDetailViewTab from "components/common/detailView/DescriptionDetailViewTab";
import LinkIdeasDetailViewTab from "components/common/detailView/LinkIdeasDetailViewTab";
import { isEmptyDescription } from "utils/descriptionUtils";
import { removeFeatureIdeas } from "../../../reduxStore/operations/featuresAPI";
import Editor from "../../../tiptapEditor/Index";
const FeatureDetailViewComponent = ({
  item,
  showBreadcrumb = false,
  onNext = () => {},
  onPrev = () => {},
  shouldNavigate = true,
}) => {
  const dispatch = useDispatch();
  const { activeFeatureId, showDetailViewDrawer } = useSelector(
    (state) => state.features
  );
  const [activeTab, setActiveTab] = useState("Overview");
  const handleTabClick = (option) => {
    setActiveTab(option);
  };
  const [isQuillEditorActive, setIsQuillEditorActive] = useState(
    // isEmptyDescription(item?.description ?? "")
    false
  );

  const handleEditorClose = () => {
    setIsQuillEditorActive(false);
  };

  const handleEditorOpen = () => {
    setIsQuillEditorActive(true);
  };

  const [selectedIdeas, setSelectedIdeas] = useState(new Set());

  const handleIdeaSelect = (idea) => {
    setSelectedIdeas((prevSelected) => {
      const newSelected = new Set(prevSelected);
      if (newSelected.has(idea._id)) {
        newSelected.delete(idea._id);
      } else {
        newSelected.add(idea._id);
      }
      return newSelected;
    });
  };

  const removeSelectedIdeas = () => {
    setSelectedIdeas(new Set());
  };

  const handleFormSubmit = (content) => {
    // Sanitize the HTML content
    const sanitizedContent = DOMPurify.sanitize(content);
    dispatch(
      updateFeatureData(
        item._id,
        { description: sanitizedContent },
        "description"
      )
    );
    setIsQuillEditorActive(false);
  };

  const [editorContent, setEditorContent] = useState("");
  const editorRef = useRef(null);

  useEffect(() => {
    setEditorContent(item.description);
  }, [item]);

  const handleSave = () => {
    dispatch(
      updateFeatureData(item._id, { description: editorContent }, "description")
    );
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (editorRef.current && !editorRef.current.contains(event.target)) {
        if (editorContent !== item.description) {
          handleSave();
        }
      }
    };
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleSave]);

  return (
    <>
      {!item ? (
        <div className="w-full h-full m-auto">Loading....</div>
      ) : (
        <div className="flex flex-col h-full pb-5">
          <div className="flex-none pt-4 px-4 pb-7 text-primary sticky top-0 bg-white z-40">
            <DetailViewHeader
              type="Feature"
              item={item}
              showBreadcrumb={showBreadcrumb}
              showDetailViewDrawer={showDetailViewDrawer}
              activeItem={activeFeatureId}
              setShowDetailViewDrawer={setFeatureShowDetailViewDrawer}
              setActiveItemId={setActiveFeatureId}
              Icon={fieldIconMapping.feature}
              shouldNavigate={shouldNavigate}
            />
          </div>
          <div className="flex-none px-6">
            <FeatureDetailViewTopbar
              feature={item}
              onNext={onNext}
              onPrev={onPrev}
              onTabClick={handleTabClick}
              activeTab={activeTab}
              updateFeatureData={(title) =>
                dispatch(updateFeatureData(item._id, { title: title }, "title"))
              }
            />
          </div>
          <div className="overflow-auto h-full w-full px-6 pt-3 text-primary custom-scrollbar">
            {activeTab === "Overview" ? (
              // isQuillEditorActive ? (
              //   <EditorContent editor={editor} />
              // ) : (
              //   <DescriptionDetailViewTab
              //     item={item}
              //     handleEditorOpen={handleEditorOpen}
              //   />
              // )
              <div ref={editorRef}>
                <Editor
                  content={item.description}
                  onChange={(content) => setEditorContent(content)}
                />
              </div>
            ) : (
              activeTab === "Ideas" && (
                <LinkIdeasDetailViewTab
                  currentItem={item}
                  selectedItems={selectedIdeas}
                  handleItemSelect={handleIdeaSelect}
                  updateLinkedItems={(ideas) => {
                    dispatch(updateFeatureIdeas(item._id, { ideas: ideas }));
                  }}
                  removeLinkedItems={(ideas) =>
                    dispatch(removeFeatureIdeas(item._id, { ideas: ideas }))
                  }
                  clearSelectedItems={removeSelectedIdeas}
                />
              )
            )}
            {/* <div className="flex flex-col gap-y-5 py-5 text-sm px-6">
            <div>
            <div>PRD</div>
            <div className="text-xs underline italic text-button-active">
            {item.prd}
            </div>
            </div>
            <div>
            <div>Design</div>
            <div className="text-xs underline italic text-button-active">
            {item.design}
            </div>
            </div>
            </div> */}
          </div>
        </div>
      )}
    </>
  );
};

export default FeatureDetailViewComponent;

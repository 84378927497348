/* eslint-disable react/prop-types */
import DropdownItem from "components/common/DropdownItem";
import IconText from "components/common/IconText";
import { Plus } from "react-flaticons";
import CheckboxDropdownItem from "../../CheckboxDropdownItem";

const DropdownMenu = ({
  options,
  isOpen,
  position,
  isAssignee = false,
  handleOptionClick,
  withAddOption = false,
  handleAddOptionClick,
  multiSelect = false,
  selectedItems = new Set(),
  item,
  type,
  byDetail = false,
  selected,
}) => {
  if (!isOpen) return [];
  // Helper function to determine background color based on type and option
  const getBackgroundColor = (option) => {
    switch (type) {
      case "status":
        if (byDetail) {
          return item?.label === option?.label ? "bg-button-active_10" : "";
        } else {
          return item?.status?.label === option?.label
            ? "bg-button-active_10"
            : "";
        }
      case "Source":
      case "type":
        return selected === option?.label ? "bg-button-active_10" : "";

      // case "type":
      //   return selected === option?.label ? "bg-button-active_10" : "";

      case "health":
        if (byDetail) {
          return item === option?._id ? "bg-button-active_10" : "";
        } else {
          return item?.health === option?._id ? "bg-button-active_10" : "";
        }
      case "timeFrame":
        if (byDetail) {
          return item?.label === option?.label ? "bg-button-active_10" : "";
        } else {
          return item?.timeFrame?.label === option?.label
            ? "bg-button-active_10"
            : "";
        }
      case "assignee":
        return item?.assignee?.name === option?.name
          ? "bg-button-active_10"
          : "";

      case "release":
        if (byDetail) {
          if (!item && !option?._id) {
            return "bg-button-active_10";
          } else {
            return item === option?.label ? "bg-button-active_10" : "";
          }
        } else {
          if (!item?.release && !option?._id) {
            return "bg-button-active_10";
          } else {
            return item?.release?.label === option?.label
              ? "bg-button-active_10"
              : "";
          }
        }
      case "product":
        if (byDetail) {
          return item?.label === option?.label ? "bg-button-active_10" : "";
        } else {
          return item?.product?.label === option?.label
            ? "bg-button-active_10"
            : "";
        }
      default:
        return "";
    }
  };

  return (
    <div
      className={`absolute bg-white   border-2 rounded-lg z-10  ${
        isAssignee ? "px-1 py-2 " : "p-2"
      }   cursor-default shadow-lg max-h-[320px] overflow-auto custom-scrollbar ${position}`}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      {withAddOption && options?.length === 0 && (
        <IconText
          icon={<Plus />}
          text="Add"
          iconSize="10"
          onClick={handleAddOptionClick}
          textSize="text-xs"
          px="px-1"
          py="py-1.5"
          gap_x="gap-x-2"
        />
      )}
      {(multiSelect
        ? (options ?? []).sort((a, b) => {
            const isSelectedA = selectedItems.has(a._id);
            const isSelectedB = selectedItems.has(b._id);
            return isSelectedA === isSelectedB ? 0 : isSelectedA ? -1 : 1;
          })
        : options
      ).map((option, index) => {
        return (
          <div
            key={index}
            className={`${index !== options.length - 1 ? "mb-2  " : ""}`}
          >
            {!multiSelect ? (
              <DropdownItem
                option={option}
                py="py-1  "
                textSize="text-xs"
                px="px-1"
                gap_x="gap-x-1.5"
                hoverColor="hover:bg-topbar-button-hover"
                textColor="text-title_50"
                iconSize={12}
                iconColor={option?.color ?? ""}
                dropDownBgColor={
                  (option?.withBackgroundColor ?? true) && option?.color
                    ? option.color
                    : "" ?? ""
                }
                handleOptionClick={handleOptionClick}
                activeBg={getBackgroundColor(option)}
                isAssignee={isAssignee}
              />
            ) : (
              <CheckboxDropdownItem
                option={option}
                handleOptionClick={handleOptionClick}
                selected={selectedItems.has(option._id)}
                activeBg={getBackgroundColor(option)}
              />
            )}
          </div>
        );
      })}
    </div>
  );
};

export default DropdownMenu;

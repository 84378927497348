import {
  groupByAssignee,
  groupByFor,
  groupByHealth,
  groupByStatus,
  groupByTag,

} from "./grouping";

export const groupDataByRoadmap = (data = [], groupBy, statuses, tags  ,users) => {
  const groupByFunc = {
    status: () => groupByStatus(data, statuses),
    tags: () => groupByTag(data, tags),
    health: () => groupByHealth(data),
    for: () => groupByFor(data),
    assignee: () => groupByAssignee(data, users),
    default: () => ({ all: data }),
  };
  return (groupByFunc[groupBy] || groupByFunc.default)();
};

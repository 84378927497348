import { createSlice } from "@reduxjs/toolkit";

// Initial state for invited users
const initialState = {
  invitedUsers: [],
};

// Create a slice for invited users
const invitedUsersSlice = createSlice({
  name: "invitedUsers",
  initialState,
  reducers: {
    setInvitedUsers(state, action) {
      state.invitedUsers = action.payload;
    },
  },
});

// Export actions to use in components
export const { setInvitedUsers } = invitedUsersSlice.actions;

// Export the reducer to be included in the store
export const invitedUsersReducer = invitedUsersSlice.reducer;

import { useState, useEffect, useMemo } from "react";
import { filterGroupedData } from "utils/filtering";
import { sortGroupedData } from "utils/sorting";
import { groupDataByFeature } from "utils/featureUtils";
import { groupDataByIdea } from "utils/ideaUtils";
import { useSelector } from "react-redux";
import { groupDataByObjective } from "../utils/objectiveUtils";
import { groupDataByRoadmap } from "../utils/roadmapUtils";

// Helper function to get statuses by module
const getStatusesByModule = (statuses, module) => {
  if (!statuses) return {};

  const statusMap = {
    feature: statuses.feature,
    idea: statuses.idea,
    objective: statuses.objective,
    roadmap: statuses.roadmap,
  };

  return statusMap[module] || {};
};

// Helper function to group data based on the module
const groupDataByModule = (
  module,
  data,
  groupBy,
  statuses,
  releases,
  products,
  timeFrames,
  objectives,
  tags,
  accounts,
  users,
  user,
  sources
) => {
  switch (module) {
    case "feature":
      if (products === null) {
        return [];
      }
      return groupDataByFeature(
        data,
        groupBy,
        statuses,
        releases,
        products,
        objectives,
        tags,

        users
      );
    case "idea":
      const updatedData = data.map((item) => {
        if (!item.submittedBy && user?._id) {
          return { ...item, submittedBy: user._id };
        }
        return item;
      });
      return groupDataByIdea(
        updatedData,
        groupBy,
        statuses,
        products,
        timeFrames,
        objectives,
        tags,
        accounts,
        users,
        user,
        sources
      );
    case "objective":
      return groupDataByObjective(
        data,
        groupBy,
        statuses,
        timeFrames,
        tags,
        users
      );
    case "roadmap":
      return groupDataByRoadmap(data, groupBy, statuses, tags, users);
    default:
      return {};
  }
};

export const useDataProcessing = (data = [], module, roadmap, objectives) => {
  const [groupedData, setGroupedData] = useState({});
  const [loading, setLoading] = useState(true);
  const items = useSelector((state) => state[module + "s"]) || {};
  const company = useSelector((state) => state.company) || {};
  const { accounts } = useSelector((state) => state.accounts);
  const { users } = useSelector((state) => state.users);
  const { user } = useSelector((state) => state.user);
  const filters = items.filters || [];
  const sortOption = items.sortOption || {};
  const viewType = items.viewType || "list";
  const groupByOption = items.groupByOption || "default";

  const releases = company.releases;
  const scoreType = company.scoreType || null;
  const products = company.products;
  const statuses = getStatusesByModule(company.statuses, module);
  const timeFrames = company.timeFrames;
  const tags = company.tags;
  const sources = ["Internal", "External"];

  const filteredProducts = roadmap?.products ?? products;

  const filteredReleases = useMemo(() => {
    if (roadmap?.manualTimeFrame?.startDate) {
      return (releases ?? []).filter(
        (release) =>
          new Date(release.endDate) <
            new Date(roadmap.manualTimeFrame.endDate) &&
          new Date(release.endDate) >
            new Date(roadmap.manualTimeFrame.startDate)
      );
    } else {
      return roadmap?.releases || releases;
    }
  }, [roadmap?.manualTimeFrame, releases, roadmap?.releases]);

  const filteredTimeFrames = useMemo(() => {
    if (roadmap?.manualTimeFrame?.startDate) {
      return (timeFrames ?? []).filter(
        (timeFrame) =>
          new Date(timeFrame.endDate) <
            new Date(roadmap.manualTimeFrame.endDate) &&
          new Date(timeFrame.endDate) >
            new Date(roadmap.manualTimeFrame.startDate)
      );
    } else {
      return roadmap?.timeFrames || timeFrames;
    }
  }, [roadmap?.manualTimeFrame, timeFrames, roadmap?.timeFrames]);

  useEffect(() => {
    if (data !== null) {
      setLoading(true);
      // Ensure roadmap is not null before accessing its properties
      const groupBy =
        viewType === "kanban" && groupByOption === "default"
          ? roadmap && roadmap.for === "Release"
            ? "release"
            : roadmap && roadmap.for === "Ideas"
            ? "time_frame"
            : roadmap && roadmap.for === "Strategy"
            ? "time_frame"
            : "status"
          : groupByOption;

      const grouped = groupDataByModule(
        module,
        data,
        groupBy,
        statuses,
        filteredReleases,
        filteredProducts,
        filteredTimeFrames,
        objectives,
        tags,
        accounts,
        users,
        user,
        sources
      );

      const filteredGroupedData = filterGroupedData(
        grouped,
        filters,
        groupBy,
        viewType,
        roadmap,
        users
      );

      const sortedGroupedData = sortGroupedData(
        filteredGroupedData,
        sortOption,
        groupBy,
        scoreType,
        statuses,
        users,
        accounts
      );

      setGroupedData(sortedGroupedData || []);
      setLoading(false);
    }
  }, [
    data,
    filters,
    sortOption.field,
    sortOption.order,
    groupByOption,
    viewType,
    statuses,
    releases,
    products,
    timeFrames,
    scoreType,
    roadmap,
    tags,
    accounts,
    objectives,
  ]);

  return { groupedData, loading };
};

/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import IconText from "components/common/IconText";
import StatusSection from "components/common/detailView/detailViewRightSidebarSections/StatusSection";
import TagsSection from "components/common/detailView/detailViewRightSidebarSections/TagsSection";
import HealthSection from "components/common/detailView/detailViewRightSidebarSections/HealthSection";
import AssigneeSection from "components/common/detailView/detailViewRightSidebarSections/AssigneeSection";
import {
  updateRoadmapData,
  updateRoadmapTags,
  updateRoadmapReleases,
  updateRoadmapTimeFrames,
} from "reduxStore/operations/roadmapsAPI";
import { IoIosOptions } from "react-icons/io";
import { generateStatusArray } from "utils/status";
import { toSnakeCase } from "utils";
import RoadmapProductsSection from "./RoadmapProductsSection";
import { updateRoadmapProducts } from "reduxStore/operations/roadmapsAPI";
import RoadmapColumnsSection from "./RoadmapColumnsSection";
import { Interactive } from "react-flaticons";

const RoadmapDetailViewRightDetailSidebar = ({ item }) => {
  const { statuses, products, tags } = useSelector((state) => state.company);
  const { users } = useSelector((state) => state.users);
  const dispatch = useDispatch();
  const [allRoadmapStatuses, setAllRoadmapStatuses] = useState([]);
  const [statusArray, setStatusArray] = useState(null);
  const [allProducts, setAllProducts] = useState(null);

  useEffect(() => {
    setAllRoadmapStatuses(statuses.roadmap);
  }, [statuses]);

  useEffect(() => {
    if (products !== null) {
      setAllProducts(products);
    }
  }, [products]);

  useEffect(() => {
    if (allRoadmapStatuses !== null) {
      setStatusArray(generateStatusArray(allRoadmapStatuses));
    }
  }, [allRoadmapStatuses]);

  const handleColumnsOptionClick = (option) => {
    if (item.for === "Release") {
      dispatch(
        updateRoadmapReleases({ itemId: option.itemId, releases: option.items })
      );
    } else {
      dispatch(
        updateRoadmapTimeFrames({
          itemId: option.itemId,
          timeFrames: option.items,
        })
      );
    }
  };

  return (
    <div className="flex flex-col gap-y-2">
      <div className="flex items-center gap-x-[44px] text-sm">
        <div>
          <IconText
            icon={<Interactive />}
            text="Type"
            hoverable={false}
            gap_x="gap-x-2"
            iconColor="#696969"
            iconSize={16}
          />
        </div>
        <div className="ml-0.5">{item?.for} </div>
      </div>
      <StatusSection
        item={item?.status ?? {}}
        statusArray={statusArray}
        handleStatusOptionClick={(option) => {
          let { icon, ...restOption } = option;
          dispatch(
            updateRoadmapData(
              item._id,
              { status: option._id },
              "status",
              item,
              restOption
            )
          );
        }}
      />
      <HealthSection
        itemLabel={item?.health ?? ""}
        position="gap-x-9"
        handleHealthOptionClick={(option) => {
          let { icon, ...restOption } = option;
          dispatch(
            updateRoadmapData(
              item._id,
              {
                health: toSnakeCase(option.label),
              },
              "health",
              item,
              restOption
            )
          );
        }}
      />
      {item.for !== "Strategy" && (
        <RoadmapProductsSection
          item={item ?? {}}
          products={allProducts}
          handleProductOptionClick={(option) => {
            dispatch(updateRoadmapProducts(option));
          }}
          position="gap-x-4"
        />
      )}
      <RoadmapColumnsSection
        roadmap={item}
        handleColumnsOptionClick={handleColumnsOptionClick}
      />
      <TagsSection
        item={item}
        tags={tags ?? []}
        handleTagOptionClick={(option) => {
          dispatch(updateRoadmapTags(option));
        }}
      />
      <AssigneeSection
        item={item}
        assignee={item.assignee}
        itemLabel={item?.assignee?.name ?? ""}
        assigneesArray={users}
        handleAssineeOptionClick={(option) => {
          if (item?.assignee?._id !== option._id) {
            let { icon, ...restOption } = option;
            dispatch(
              updateRoadmapData(
                item._id,
                { assignee: option._id },
                "assignee",
                item,
                restOption
              )
            );
          }
        }}
      />
      <hr className="my-6" />
    </div>
  );
};

export default RoadmapDetailViewRightDetailSidebar;

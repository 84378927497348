/* eslint-disable react/prop-types */
import SimpleCheckboxItem from "./checkboxItem/SimpleCheckboxItem";
import SwitchCheckboxItem from "./checkboxItem/SwitchCheckboxItem";

const CheckboxDropdownItem = ({
  option,
  handleOptionClick,
  selected,
  typeOfCheckbox = "simple",
  checkboxTextSize = "text-xs",
  isImportanceType = false,
  truncateLength=40,
  activeBg="",
  isAssignee
}) => {
  return (
    <>
      {typeOfCheckbox === "simple" && (
        <SimpleCheckboxItem
          option={option}
          handleOptionClick={handleOptionClick}
          selected={selected}
          checkboxTextSize={checkboxTextSize}
          isImportanceType={isImportanceType}
          truncateLength={truncateLength}
          activeBg={activeBg}
          isAssignee={isAssignee}
        />
      )}
      {typeOfCheckbox === "switch" && (
        <SwitchCheckboxItem
          option={option}
          handleOptionClick={handleOptionClick}
          selected={selected}
          checkboxTextSize={checkboxTextSize}
        />
      )}
    </>
  );
};

export default CheckboxDropdownItem;

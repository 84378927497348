/* eslint-disable react/prop-types */
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  RiProgress5Line,
  RiProgress8Fill,
  RiProgress8Line,
} from "react-icons/ri";
import { Minus } from "react-flaticons";
import Sidebar from "components/core/Sidebar";
import Topbar from "components/core/topbar/Topbar";
import Loader from "components/common/Loader";
import IconWrapper from "components/common/IconWrapper";
import { capitalizeFirstLetter, fieldIconMapping, truncateText } from "utils";
import { featureTopbarButtonFunctions } from "utils/topbarButtonFunctions";
import {
  setFeatureActiveProperties,
  setFeatureViewType,
} from "reduxStore/slices/featureSlice";
import { transformFiltersToObject } from "utils";
import {
  setActiveRoadmapId,
  setRoadmapShowDetailViewDrawer,
} from "reduxStore/slices/roadmapsSlice";
import { TbCircleDashed } from "react-icons/tb";
import FeaturesRoadmapsComponent from "./FeaturesRoadmapsComponent";
import { particularRoadmapTopbarTabFunctions } from "utils/topbarTabFunctions";
import { fetchAllViews } from "reduxStore/operations/viewsAPI";
import AllDetailView from "components/common/AllDetailView";

const RoadmapReleaseDetailViewComponent = ({
  roadmap,
  showBreadcrumb = true,
}) => {
  const dispatch = useDispatch();
  const { statuses, releases, tags } = useSelector((state) => state.company);

  const { viewType, isLoading, isViewsLoading } = useSelector(
    (state) => state.features
  );

  const { users } = useSelector((state) => state.users);
  const { roadmapIdViews } = useSelector((state) => state.roadmaps);
  const { keyResults } = useSelector((state) => state.keyResults);
  const { objectives } = useSelector((state) => state.objectives);
  
  const [featureStatuses, setFeatureStatuses] = useState([]);
  const [searchbarTerm, setSearchbarTerm] = useState("");
  const [currentViewType, setCurrentViewType] = useState(null);
  const [tabs, setTabs] = useState(null);
  const crumbs = [
    {
      icon: (
        <IconWrapper
          icon={React.createElement(fieldIconMapping.roadmap)}
          iconColor="text-roadmaps-icon"
        />
      ),
      name: "Roadmaps",
      path: `/roadmaps`,
    },
    {
      name: truncateText(roadmap.title, 20),
      path: `/roadmaps/${roadmap._id}`,
    },
  ];

  useEffect(() => {
    setFeatureStatuses(statuses.feature);
  }, [statuses]);

  useEffect(() => {
    dispatch(fetchAllViews("roadmapId", null, roadmap._id));
  }, [dispatch, roadmap]);

  useEffect(() => {
    if (roadmapIdViews) {
      const transformedLocalViews = roadmapIdViews.map((view) => ({
        ...view,
        filters: transformFiltersToObject(view.filters),
      }));
      setTabs([...transformedLocalViews]);
    }
  }, [roadmapIdViews]);

  useEffect(() => {
    if (viewType !== "kanban") {
      dispatch(setFeatureViewType("kanban"));
      setCurrentViewType("kanban");
    }
  }, [viewType]);

  const topbarFunctions = featureTopbarButtonFunctions(dispatch);
  const topbarTabFunctions = particularRoadmapTopbarTabFunctions(dispatch);
  const setActiveProperties = (
    sortOption,
    selectedColumns,
    groupByOption,
    filters
  ) => {
    dispatch(
      setFeatureActiveProperties({
        sortOption,
        selectedColumns,
        groupByOption,
        filters,
      })
    );
  };

  const filteredReleases = useMemo(() => {
    if (roadmap?.manualTimeFrame?.startDate) {
      return (releases ?? []).filter(
        (release) =>
          new Date(release.endDate) <
            new Date(roadmap.manualTimeFrame.endDate) &&
          new Date(release.endDate) >
            new Date(roadmap.manualTimeFrame.startDate)
      );
    } else {
      return roadmap?.releases || releases;
    }
  }, [roadmap?.manualTimeFrame, releases, roadmap?.releases]);

  const statusParked =
    (featureStatuses ?? []).filter((item) => item.type === "parked") ?? [];
  const statusToDo =
    (featureStatuses ?? []).filter((item) => item.type === "to_do") ?? [];
  const statusInProgress =
    (featureStatuses ?? []).filter((item) => item.type === "in_progress") ?? [];
  const statusDone =
    (featureStatuses ?? []).filter((item) => item.type === "done") ?? [];

  const groupByDropdownOptions = [
    { label: "Default", icon: Minus },
    ...(viewType !== "kanban"
      ? [{ label: "Release", icon: fieldIconMapping.release }]
      : []),
    { label: "Status", icon: fieldIconMapping.status },
    { label: "Product", icon: fieldIconMapping.product },
  ];

  const getFilteredFeatures = () => {
    const predefinedFields = [
      "status",
      "release",
      "score",
      "product",
      "tags",
      "assignee",
      "idea",
      // "roadmap",
      "objective",
      "key_result",
    ];

    const filteredOptions = predefinedFields.map((field) => ({
      label: capitalizeFirstLetter(field),
      icon: fieldIconMapping[field] || Minus,
    }));
    return [...filteredOptions];
  };

  // Determine whether to show dropdown options based on features
  const showButtonDropdownOptions = getFilteredFeatures();

  const getFilterOptions = (releases) => [
    {
      label: "Status",
      icon: fieldIconMapping.status,
      subOptions: [
        ...statusParked.map((item) => ({
          ...item,
          iconColor: item.color,
          icon: TbCircleDashed,
        })),
        ...statusToDo.map((item) => ({
          ...item,
          iconColor: item.color,
          icon: RiProgress8Fill,
        })),
        ...statusInProgress.map((item) => ({
          ...item,
          iconColor: item.color,
          icon: RiProgress5Line,
        })),
        ...statusDone.map((item) => ({
          ...item,
          iconColor: item.color,
          icon: RiProgress8Line,
        })),
      ],
    },
    {
      label: "Release",
      icon: fieldIconMapping.release,
      subOptions: releases
        ? releases.filter(
            (release) =>
              !filteredReleases?.some(
                (filteredRelease) => filteredRelease._id === release._id
              )
          )
        : filteredReleases ?? [],
    },
    {
      label: "Product",
      icon: fieldIconMapping.product,
      subOptions: (roadmap?.products ?? []).map((product) => ({
        ...product,
        iconColor: product.color,
      })),
    },
    {
      label: "Assignee",
      icon: fieldIconMapping.assignee,
      subOptions: users ?? [],
    },
    {
      label: "Tags",
      icon: fieldIconMapping.tags,
      subOptions: tags ?? [],
    },
    {
      label: "Objectives",
      icon: fieldIconMapping.objective,
      subOptions: (objectives ?? []).map((item) => ({
        ...item,
        icon: fieldIconMapping.objective,
        iconColor: "text-objectives-icon",
        label: item.title,
        withBackgroundColor: false,
      })),
      withMoreWidth: true,
    },
    {
      label: "Key result",
      icon: fieldIconMapping.key_result,
      subOptions: (keyResults ?? []).map((item) => ({
        ...item,
        icon: fieldIconMapping.key_result,
        iconColor: "text-key_results-icon",
        label: item.title,
        withBackgroundColor: false,
      })),
      withMoreWidth: true,
    },
  ];

  const filterOptions = getFilterOptions();
  const allFilterOptions = getFilterOptions(releases);

  // const sortOptions = [
  //   "Default",
  //   "Status",
  //   "Release",
  //   "Product",
  //   "Assignee",
  //   "Score",
  // ].map((label) => ({ label }));

  const sortOptions = [
    { label: "Created", icon: Minus },
    { label: "Status", icon: fieldIconMapping.status },
    { label: "Release", icon: fieldIconMapping.release },
    { label: "Product", icon: fieldIconMapping.product },
    { label: "Assignee", icon: fieldIconMapping.assignee },
    { label: "Score", icon: fieldIconMapping.score },
  ];

  return (
    <div className="flex font-inter w-screen h-screen tracking-wide cursor-default">
      <Sidebar />
      {(isLoading && isViewsLoading && currentViewType !== null) ||
      tabs === null ? (
        <div className="absolute inset-0 flex items-center justify-center">
          <Loader />
        </div>
      ) : (
        <div className="flex flex-col min-w-[80%] w-[100%]">
          <Topbar
            title="Features"
            tabs={tabs}
            groupByDropdownOptions={groupByDropdownOptions}
            showButtonDropdownOptions={showButtonDropdownOptions}
            filterOptions={filterOptions}
            sortOptions={sortOptions}
            topbarFunctions={topbarFunctions}
            topbarTabFunctions={topbarTabFunctions}
            setActiveProperties={setActiveProperties}
            with_different_view_option={false}
            activeState="features"
            showBreadcrumb={showBreadcrumb}
            breadCrumbs={crumbs}
            handleRightSidebarClick={() => {
              dispatch(setActiveRoadmapId(roadmap._id));
              dispatch(setRoadmapShowDetailViewDrawer(true));
            }}
            withShareOption={true}
            withTopSidebarOption={true}
            with_group_by_option={false}
            searchTerm={searchbarTerm}
            onSearchChange={(value) => setSearchbarTerm(value)}
            roadmap={roadmap}
          />
          <FeaturesRoadmapsComponent
            searchTerm={searchbarTerm}
            roadmap={roadmap}
            filterOptions={allFilterOptions}
            filteredReleases={filteredReleases}
          />
        </div>
      )}
      <AllDetailView />
    </div>
  );
};

export default RoadmapReleaseDetailViewComponent;

/* eslint-disable react/prop-types */
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import StatusSection from "components/common/detailView/detailViewRightSidebarSections/StatusSection";
import ProductSection from "components/common/detailView/detailViewRightSidebarSections/ProductSection";
import ReleaseSection from "components/common/detailView/detailViewRightSidebarSections/ReleaseSection";
import TagsSection from "components/common/detailView/detailViewRightSidebarSections/TagsSection";
import ScoreSection from "components/common/detailView/detailViewRightSidebarSections/ScoreSection";
import AssigneeSection from "components/common/detailView/detailViewRightSidebarSections/AssigneeSection";
import {
  updateFeatureData,
  updateFeatureTags,
  updateFeatureScore,
} from "reduxStore/operations/featuresAPI";
import { generateStatusArray } from "utils/status";
import { fieldIconMapping, truncateText } from "utils";
import IconText from "components/common/IconText";
import RoadmapsSection from "components/common/detailView/detailViewRightSidebarSections/RoadmapsSection";
import {
  setActiveKeyResultId,
  setDetailKeyResults,
  setKeyResultShowDetailViewDrawer,
} from "reduxStore/slices/keyResultSlice";
import {
  setActiveObjectiveId,
  setObjectiveShowDetailViewDrawer,
} from "reduxStore/slices/objectiveSlice";
import { resetActiveIds } from "utils/resetActiveIds";
import { handleResetActiveIds } from "utils/handleResetActiveIds";
import JiraSection from "../../../../common/detailView/detailViewRightSidebarSections/JiraSection";

const FeatureDetailViewRightDetailSidebar = ({ item }) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { statuses, products, releases, tags } = useSelector(
    (state) => state.company
  );

  const integrations = useSelector((state) => state.integrations);
  const { jira } = integrations;
  const { objectives } = useSelector((state) => state.objectives);
  const { keyResults } = useSelector((state) => state.keyResults);
  const { users } = useSelector((state) => state.users);
  const [allFeatureStatuses, setAllFeatureStatuses] = useState([]);
  const [statusArray, setStatusArray] = useState(null);
  const [allProducts, setAllProducts] = useState(null);
  const [allreleases, setAllReleaass] = useState(null);

  const featureObjectives = useMemo(() => {
    return (objectives ?? []).filter((objective) =>
      (item?.objectives ?? []).includes(objective._id)
    );
  }, [item?.objectives, objectives]);

  const featureKeyResults = useMemo(() => {
    return (keyResults ?? []).filter((keyResult) =>
      (item?.keyResults ?? []).includes(keyResult._id)
    );
  }, [item?.keyResults, keyResults]);

  useEffect(() => {
    setAllFeatureStatuses(statuses.feature);
  }, [statuses]);

  useEffect(() => {
    if (products !== null) {
      setAllProducts(products);
    }
  }, [products]);

  useEffect(() => {
    if (releases !== null) {
      setAllReleaass(releases);
    }
  }, [releases]);

  useEffect(() => {
    if (allFeatureStatuses !== null) {
      setStatusArray(generateStatusArray(allFeatureStatuses));
    }
  }, [allFeatureStatuses]);

  return (
    <div className="flex flex-col gap-y-2 h-full overflow-auto custom-scrollbar">
      <StatusSection
        item={item?.status ?? {}}
        statusArray={statusArray}
        handleStatusOptionClick={(option) => {
          if (option._id !== item?.status?._id) {
            let { icon, ...restOption } = option;
            dispatch(
              updateFeatureData(
                item._id,
                { status: option._id },
                "status",
                item,
                restOption
              )
            );
          }
        }}
      />
      <ReleaseSection
        itemLabel={item.release?.label ?? "No Release"}
        releases={allreleases}
        handleReleaseOptionClick={(option) => {
          if (option._id !== item?.release?._id) {
            let { icon, ...restOption } = option;
            dispatch(
              updateFeatureData(
                item._id,
                { release: option._id },
                "release",
                item,
                restOption
              )
            );
          }
        }}
      />
      <ProductSection
        item={item.product}
        products={allProducts}
        handleProductOptionClick={(option) => {
          if (option._id !== item?.product?._id) {
            let { icon, ...restOption } = option;
            dispatch(
              updateFeatureData(
                item._id,
                { product: option._id },
                "product",
                item,
                restOption
              )
            );
          }
        }}
      />
      <TagsSection
        item={item}
        tags={tags ?? []}
        handleTagOptionClick={(option) => {
          let { fullTags } = option;
          dispatch(updateFeatureTags(option, fullTags, item));
        }}
      />
      <AssigneeSection
      item={item}
      assignee={item.assignee}
        itemLabel={item?.assignee?.name ?? ""}
        assigneesArray={users}
        handleAssineeOptionClick={(option) => {
          if (item?.assignee?._id !== option._id) {
            let { icon, ...restOption } = option;
            dispatch(
              updateFeatureData(
                item._id,
                { assignee: option._id },
                "assignee",
                item,
                restOption
              )
            );
          }
        }}
      />
      {(jira || item.jira?.key) && (
        <>
          <hr className="my-2" />
          <JiraSection item={item} />
        </>
      )}
      <hr className="my-2" />
      <ScoreSection
        item={item}
        updateScore={(option) => {
          dispatch(
            updateFeatureScore(
              item._id,
              item.score.type,
              option.value,
              option.effort,
              option.impact,
              option.reach,
              option.confidence
            )
          );
        }}
      />
      <hr className="my-4" />
      <div className="ml-1.5 text-sm mb-1">Roadmaps</div>
      {item?.roadmaps && item?.roadmaps?.length > 0 ? (
        <RoadmapsSection
          item={item}
          roadmaps={item?.roadmaps}
          releases={allreleases}
          with_release={false}
        />
      ) : (
        <div className="px-1.5 -mt-1 text-sm text-title">None</div>
      )}
      <div className="ml-1.5 text-sm mt-1.5 mb-1">OKRs</div>
      {item?.objectives?.length === 0 && item?.keyResults?.length === 0 && (
        <div className="px-1.5 -mt-1 text-sm text-title">None</div>
      )}
      {(featureObjectives ?? []).map((objective) => (
        <div
          key={objective._id}
          className="text-sm py-1 px-1.5 hover:bg-row-background rounded cursor-pointer -mt-1"
          onClick={() => {
            if (pathname === "/objectives") {
              resetActiveIds({
                dispatch,
                resetObjective: false,
              });
            }
            if (pathname.includes("/roadmaps")) {
              resetActiveIds({ dispatch });
            }
            handleResetActiveIds(dispatch, pathname);
            dispatch(setActiveObjectiveId(objective._id));
            dispatch(setObjectiveShowDetailViewDrawer(true));
          }}
        >
          <IconText
            icon={React.createElement(fieldIconMapping.objective)}
            text={truncateText(objective.title, 30)}
            gap_x="gap-x-2"
            hoverable={false}
            textSize="text-sm"
            textColor="text-title_50"
            iconColor="text-objectives-icon"
            iconSize={12}
            py=""
            px=""
          />
        </div>
      ))}
      {(featureKeyResults ?? []).map((keyResult) => (
        <div
          key={keyResult._id}
          className="text-sm py-1 px-1.5 hover:bg-row-background rounded cursor-pointer -mt-1"
          onClick={() => {
            if (pathname === "/objectives") {
              resetActiveIds({
                dispatch,
                resetObjective: false,
                resetKeyResult: false,
              });
            }
            if (pathname.includes("/roadmaps")) {
              resetActiveIds({ dispatch });
            }
            handleResetActiveIds(dispatch, pathname);
            dispatch(setDetailKeyResults(featureKeyResults));
            dispatch(setActiveKeyResultId(keyResult._id));
            dispatch(setKeyResultShowDetailViewDrawer(true));
          }}
        >
          <IconText
            icon={React.createElement(fieldIconMapping.key_results)}
            text={truncateText(keyResult.title, 30)}
            iconColor="text-key_results-icon"
            gap_x="gap-x-2"
            hoverable={false}
            textSize="text-sm"
            textColor="text-title_50"
            iconSize={12}
            py=""
            px=""
          />
        </div>
      ))}
    </div>
  );
};

export default FeatureDetailViewRightDetailSidebar;

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { signin, signUpWithGoogle } from "reduxStore/operations/authAPI";
import { setLoading } from "reduxStore/slices/authSlice";
import Loader from "../common/Loader";
import { FaEyeSlash } from "react-icons/fa";
import { IoEyeSharp } from "react-icons/io5";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";

const SignIn = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading } = useSelector((state) => state.auth);

  const handleSignIn = (e) => {
    e.preventDefault();
    dispatch(setLoading(true));
    dispatch(signin(email, password, navigate));
    setPassword("");
  };

  useEffect(() => {
    const token = localStorage.getItem("jwt");
    if (token) {
      navigate("/");
    }
  }, [navigate]);

  const clientId = import.meta.env.VITE_GOOGLE_CLIENT_ID;
  const handleLoginSuccess = (response) => {
    dispatch(signUpWithGoogle(response.credential, navigate));
  };

  const handleLoginFailure = (error) => {
    console.error("Login Failed", error);
  };

  return (
    <div className="flex flex-col gap-6 items-center justify-center min-h-screen bg-gray-100">
      <div className=" relative bg-white p-8 rounded shadow-md w-full max-w-sm">
        <div className="  absolute top-[-140px] right-0 w-full  flex items-center justify-center  ">
          <img src="/logo.png  " alt="logo" className=" w-[200px] h-[100px] " />
        </div>

        <h2 className="text-2xl mb-6 text-center">Welcome back</h2>
        <form onSubmit={handleSignIn}>
          <div className="mb-6">
            <GoogleOAuthProvider clientId={clientId}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <GoogleLogin
                  onSuccess={handleLoginSuccess}
                  onError={handleLoginFailure}
                  text="continue_with"
                  shape="rectangular"
                  size="large"
                  theme="outline"
                  logo_alignment="center"
                  width="320"
                />
              </div>
            </GoogleOAuthProvider>
          </div>

          <div className="flex items-center justify-center mb-4">
            <div className="flex-grow border-t border-gray-300"></div>
            <span className="mx-4 text-gray-500">OR</span>
            <div className="flex-grow border-t border-gray-300"></div>
          </div>
          <div className="mb-4">
            <label className="block text-gray-700">Email</label>
            <input
              type="email"
              className="w-full p-2 border border-gray-300 rounded mt-1"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="name@company.com"
              required
            />
          </div>

          <div className="mb-4">
            <label className="block text-gray-700">Password</label>
            <div className="relative">
              <input
                type={showPassword ? "text" : "password"}
                className="w-full p-2 border border-gray-300 rounded mt-1 pr-10" // Added padding-right for the icon
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Sshhhh…"
                required
              />
              <button
                type="button"
                className="absolute right-2 mt-1 inset-y-0 flex items-center text-gray-600"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? (
                  <IoEyeSharp className="h-5 w-5" />
                ) : (
                  <FaEyeSlash className="h-5 w-5" />
                )}
              </button>
            </div>
              <p className="text-end text-gray-600">
                <Link to="/forgot-password" className="text-blue-500">
                  Forgot password
                </Link>
              </p>
          </div>
          <button
            type="submit"
            className="w-full bg-blue-500 text-white p-2 rounded mt-4 hover:bg-blue-600"
          >
            {loading ? <Loader size="20" color="white" /> : "Sign In"}
          </button>
        </form>
      </div>
      <p className="text-center text-gray-600 ">
        Don't have an account?{" "}
        <Link to="/signup" className="text-blue-500">
          Sign Up
        </Link>
      </p>
    </div>
  );
};

export default SignIn;

/* eslint-disable react/prop-types */
import { useLocation } from "react-router-dom";
import IconWrapper from "./IconWrapper";
import { truncateText } from "utils";
import ProfileIcon from "./ProfileIcon";

const IconText = ({
  icon,
  text,
  onClick = () => {},
  hoverable = true,
  iconSize = 14,
  align = "items-center",
  iconColor = "",
  textColor = "text-primary",
  textSize = "text-sm",
  px = "px-2",
  py = "py-2",
  gap_x = "gap-x-3",
  isASidebarComponent = false,
  textBgColor = "",
  iconTextBgColor = "",
  isTextActive = false,
  hoverColor = "bg-topbar-button-hover",
  cursorType = "cursor-pointer",
  truncateLength = null,
  isIconActive = false,
  isKanban = false,
  addButton = false,
  option,
  handleOptionClick,
  isClear = false,
  textbreak=false,
  showPlus=false,

}) => {

  const { pathname } = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const isViewAll =
    searchParams.get("view") === "all" || searchParams.get("view") === null;
  const isActiveTab =
    !(
      pathname.includes("/roadmaps/") &&
      text === "Roadmaps" &&
      isASidebarComponent
    ) &&
    ((pathname.includes(typeof text === "string" ? text.toLowerCase() : "") &&
      isViewAll) ||
      (pathname === "/" && text === "Features") ||
      (pathname === "/settings" && text === "Profile")) &&
    isASidebarComponent;

  const handleAddNew = (e) => {
    e.stopPropagation();
    handleOptionClick(option);
  };

  return (
    <div
      className={`flex relative group  ${
        isClear && " hover:bg-button-hover  "
      }    ${align} ${py} ${px} ${gap_x} ${iconTextBgColor} ${
        isActiveTab ? " bg-topbar-button-hover rounded" : "  "
      } ${hoverable ? `hover:${hoverColor} rounded ${cursorType}` : ""}`}
      style={{ backgroundColor: iconTextBgColor }}
      onClick={onClick}
    >
      <span
        className={`${
          align === "items-start" ? (iconSize === 14 ? "py-[3.25px]" : "") : ""
        }`}
      >
     
     
        {text !== "Insights" ? (
          <IconWrapper
            icon={icon}
            size={iconSize}
            text={text}
            iconColor={iconColor}
            isASidebarComponent={isASidebarComponent}
            isIconActive={isActiveTab || isIconActive}
            // isIconActive={isTextActive}
          />
        ) : !isASidebarComponent ? (
          <IconWrapper
            icon={icon}
            size={iconSize}
            text={text}
            iconColor={iconColor}
            isASidebarComponent={isASidebarComponent}
            isIconActive={isActiveTab || isIconActive}
            // isIconActive={isTextActive}
          />
        ) : (
          <>
            <img
              src="/insights.svg"
              height={14}
              width={14}
              style={{ filter: "grayscale(100%) brightness(60%)" }}
            />
          </>
        )}
      </span>

      <span
        className={`${textSize} ${textColor} overflow-hidden  ${textbreak?"text-nowrap":"break-words"} ${
          textBgColor && "rounded px-1"
        } ${textBgColor} ${isActiveTab || isTextActive ? "text-title_50" : ""}`}
        style={{ backgroundColor: textBgColor }}
        title={truncateLength !== null && truncateLength < text?.length && text}
      >
                   
        {truncateLength === null ? text : truncateText(text, truncateLength)}
      </span>
      {text === "Insights" && isASidebarComponent && (
        <span>
          <div className="text-[10px] rounded-md bg-[#d7e9ff] py-0.5 px-2">
            soon
          </div>
        </span>
      )}
      {/* {addButton && (
        <button
          onClick={handleAddNew}
          className="ml-auto hover:bg-button-hover group-hover:opacity-100 opacity-0  transition-all ease-in-out duration-300    p-1.5 rounded-lg  absolute right-1   "
        >
          <svg
            width="8"
            height="8"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11 5H7V1C7 0.734784 6.89464 0.48043 6.70711 0.292893C6.51957 0.105357 6.26522 0 6 0C5.73478 0 5.48043 0.105357 5.29289 0.292893C5.10536 0.48043 5 0.734784 5 1V5H1C0.734784 5 0.48043 5.10536 0.292893 5.29289C0.105357 5.48043 0 5.73478 0 6C0 6.26522 0.105357 6.51957 0.292893 6.70711C0.48043 6.89464 0.734784 7 1 7H5V11C5 11.2652 5.10536 11.5196 5.29289 11.7071C5.48043 11.8946 5.73478 12 6 12C6.26522 12 6.51957 11.8946 6.70711 11.7071C6.89464 11.5196 7 11.2652 7 11V7H11C11.2652 7 11.5196 6.89464 11.7071 6.70711C11.8946 6.51957 12 6.26522 12 6C12 5.73478 11.8946 5.48043 11.7071 5.29289C11.5196 5.10536 11.2652 5 11 5Z"
              fill="#000000"
              // fill="#a8a7a7"
              // fill="#696969"
            />
          </svg>
        </button>
      )}
      {isActiveTab && (
        <button
          onClick={handleAddNew}
          className="ml-auto hover:bg-button-hover   transition-all ease-in-out duration-300    p-1.5 rounded-lg  absolute right-1   "
        >
          <svg
            width="8"
            height="8"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11 5H7V1C7 0.734784 6.89464 0.48043 6.70711 0.292893C6.51957 0.105357 6.26522 0 6 0C5.73478 0 5.48043 0.105357 5.29289 0.292893C5.10536 0.48043 5 0.734784 5 1V5H1C0.734784 5 0.48043 5.10536 0.292893 5.29289C0.105357 5.48043 0 5.73478 0 6C0 6.26522 0.105357 6.51957 0.292893 6.70711C0.48043 6.89464 0.734784 7 1 7H5V11C5 11.2652 5.10536 11.5196 5.29289 11.7071C5.48043 11.8946 5.73478 12 6 12C6.26522 12 6.51957 11.8946 6.70711 11.7071C6.89464 11.5196 7 11.2652 7 11V7H11C11.2652 7 11.5196 6.89464 11.7071 6.70711C11.8946 6.51957 12 6.26522 12 6C12 5.73478 11.8946 5.48043 11.7071 5.29289C11.5196 5.10536 11.2652 5 11 5Z"
              fill="#000000"
              // fill="#a8a7a7"
              // fill="#696969"
            />
          </svg>
        </button>
      )} */}

      {(addButton || isActiveTab)&&showPlus && (
        <button
          onClick={handleAddNew}
          className={`ml-auto hover:bg-button-hover ${isActiveTab?"":" group-hover:opacity-100 opacity-0 transition-all ease-in-out duration-300"} p-1.5 rounded-lg absolute right-1 
          }`}
        >
          <svg
            width="8"
            height="8"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11 5H7V1C7 0.734784 6.89464 0.48043 6.70711 0.292893C6.51957 0.105357 6.26522 0 6 0C5.73478 0 5.48043 0.105357 5.29289 0.292893C5.10536 0.48043 5 0.734784 5 1V5H1C0.734784 5 0.48043 5.10536 0.292893 5.29289C0.105357 5.48043 0 5.73478 0 6C0 6.26522 0.105357 6.51957 0.292893 6.70711C0.48043 6.89464 0.734784 7 1 7H5V11C5 11.2652 5.10536 11.5196 5.29289 11.7071C5.48043 11.8946 5.73478 12 6 12C6.26522 12 6.51957 11.8946 6.70711 11.7071C6.89464 11.5196 7 11.2652 7 11V7H11C11.2652 7 11.5196 6.89464 11.7071 6.70711C11.8946 6.51957 12 6.26522 12 6C12 5.73478 11.89464 5.48043 11.7071 5.29289C11.5196 5.10536 11.2652 5 11 5Z"
              fill="#000000"
              // fill="#a8a7a7"
              // fill="#696969"
            />
          </svg>
        </button>
      )}
    </div>
  );
};

export default IconText;

/* eslint-disable react/prop-types */
import React, { useState, useEffect, useRef } from "react";
import IconText from "components/common/IconText";
import { capitalizeFirstLetter, fieldIconMapping } from "utils";
import DropdownMenu from "../dropdown/DropdownMenu";
import { Info } from "react-flaticons";

const TypeSection = ({
  itemLabel,
  types,
  handleTypeOptionClick,
  position = "gap-x-6",
  with_title = true,
  with_dropdown = true,
  with_border = true,
  showHover = false,
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef();

  const handleLocalTypeOptionClick = (option) => {
    handleTypeOptionClick(option);
    setIsDropdownOpen(false);
  };

  const toggleDropdown = (e) => {
    e.stopPropagation();
    setIsDropdownOpen(!isDropdownOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className={`flex ${position} items-center text-title_50`}>
      {with_title && (
        <IconText
          icon={React.createElement(fieldIconMapping.type)}
          text="Type"
          iconColor="#696969"
          iconSize={16}
          hoverable={false}
          gap_x="gap-x-2"
        />
      )}
      <div className="relative" ref={dropdownRef}>
        <div className="flex items-center gap-x-2">
          <div
            className={`${with_border && "border rounded"} ${
              itemLabel ? "p-1" : "px-7 py-1 w-[55px]"
            } text-xs cursor-default ${
              with_dropdown &&
              (isDropdownOpen
                ? "rounded cursor-pointer bg-button-active_10"
                : "rounded cursor-pointer hover:bg-button-hover")
            }`}
            onClick={toggleDropdown}
          >
            {capitalizeFirstLetter(itemLabel ?? "")}
          </div>
          {showHover && (
            <div className=" group">
              <Info size={14} className="cursor-pointer" />
              <div className="absolute  hidden  group-hover:block  text-black   w-[18rem] py-3   top-4 right-[-3rem]  z-50  ">
                {/* <div className="absolute hidden group-hover:block  text-black rounded-md shadow-md p-3 top-3"> */}
                <div className=" bg-white p-3 px-4  rounded-md shadow-md text-gray-600   text-sm ">
                  <p>
                    {" "}
                    <b className="  text-title_50  ">Outcome-driven </b>{" "}
                    objective aim at measurable results or key outcomes that
                    reflects progress toward broader goals.
                  </p>
                  <p className=" pt-3 ">
                    {" "}
                    <b className=" text-title_50 "> Execution-driven </b>{" "}
                    objective focus on completing specific tasks or initiatives
                    directly related to business actions.
                  </p>
                  <p className=" pt-3 ">
                    Once selected, the objective type cannot be changes.
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
        {with_dropdown && isDropdownOpen && (
          <DropdownMenu
            options={types ?? []}
            isOpen={isDropdownOpen}
            position="top-full left-0"
            handleOptionClick={handleLocalTypeOptionClick}
            type={"type"}
            selected={itemLabel}
          />
        )}
      </div>
    </div>
  );
};

export default TypeSection;

/* eslint-disable react/prop-types */
import React from "react";
import ProfileIcon from "components/common/ProfileIcon";

const AssigneeRowSection = ({ name, dropdownState, toggleDropdown }) => {
  return (
    <div
      className={` flex items-center gap-x-2 w-fit  p-1 rounded hover:bg-gray-200 ${
        dropdownState.isAssigneeDropdownOpen && "bg-button-active_10"
      }`}
      onClick={(e) => {
        e.stopPropagation();
        toggleDropdown();
      }}
    >
      <ProfileIcon name={name ?? ""} size={"22px"} />
      <span  className=" text-title_50 "  >{name ?? ""}</span>
    </div>
  );
};

export default AssigneeRowSection;

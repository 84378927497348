const baseURL = import.meta.env.VITE_BACKEND_URL + "/api";

export const featureEndPoints = {
  // FEATURE Endpoints
  ADD_FEATURE: () => `${baseURL}/features`,
  GET_ALL_FEATURES: () => `${baseURL}/features`,
  ADD_FEATURE_TAGS: (featureId) => `${baseURL}/features/${featureId}/tags`,
  UPDATE_FEATURE_NON_ENTITY: (featureId) => `${baseURL}/features/${featureId}`,
  UPDATE_MULTIPLE_FEATURES: () => `${baseURL}/features/features`,
  UPDATE_FEATURE_PRIORITIZATION: (featureId) =>
    `${baseURL}/features/${featureId}/score`,
  UPDATE_FEATURE_IDEAS: (featureId) => `${baseURL}/features/${featureId}/ideas`,
  DELETE_FEATURE: (featureId) => `${baseURL}/features/${featureId}`,
  DELETE_FEATURES: () => `${baseURL}/features/features`,
};

export const ideaEndPoints = {
  // IDEA Endpoints
  ADD_IDEA: () => `${baseURL}/ideas`,
  GET_ALL_IDEAS: () => `${baseURL}/ideas`,
  UPDATE_IDEA_NON_ENTITY: (ideaId) => `${baseURL}/ideas/${ideaId}`,
  UPDATE_MULTIPLE_IDEAS: () => `${baseURL}/ideas/ideas`,
  UPDATE_IDEA_PRIORITIZATION: (ideaId) => `${baseURL}/ideas/${ideaId}/score`,
  UPDATE_IDEA_FEATURES: (ideaId) => `${baseURL}/ideas/${ideaId}/features`,
  DELETE_IDEA: (ideaId) => `${baseURL}/ideas/${ideaId}`,
  UPDATE_IDEA_TAGS: (ideaId) => `${baseURL}/ideas/${ideaId}/tags`,
  ADD_IDEA_TO_ACCOUNTS: (ideaId) => `${baseURL}/ideas/${ideaId}/accounts`,
  DELETE_IDEAS: () => `${baseURL}/ideas/ideas`,
  CONVERT_IDEA_TO_FEATURE: (ideaId) =>
    `${baseURL}/ideas/${ideaId}/convert-idea-to-feature`,
};

export const objectiveEndPoints = {
  // OBJECTIVE Endpoints
  ADD_OBJECTIVE: () => `${baseURL}/objectives`,
  GET_ALL_OBJECTIVES: () => `${baseURL}/objectives`,
  UPDATE_OBJECTIVE: (objectiveId) => `${baseURL}/objectives/${objectiveId}`,
  UPDATE_OBJECTIVES: () => `${baseURL}/objectives/objectives`,
  DELETE_OBJECTIVE: (objectiveId) => `${baseURL}/objectives/${objectiveId}`,
  UPDATE_OBJECTIVE_FEATURES: (objectiveId) =>
    `${baseURL}/objectives/${objectiveId}/features`,
  REMOVE_OBJECTIVE_FEATURES: (objectiveId) =>
    `${baseURL}/objectives/${objectiveId}/features`,
  UPDATE_OBJECTIVE_IDEAS: (objectiveId) =>
    `${baseURL}/objectives/${objectiveId}/ideas`,
  REMOVE_OBJECTIVE_IDEAS: (objectiveId) =>
    `${baseURL}/objectives/${objectiveId}/ideas`,
  UPDATE_OBJECTIVE_KEY_RESULTS: (objectiveId) =>
    `${baseURL}/objectives/${objectiveId}/keyResults`,
  REMOVE_OBJECTIVE_KEY_RESULTS: (objectiveId) =>
    `${baseURL}/objectives/${objectiveId}/keyResults`,
  ADD_OBJECTIVE_TAGS: (objectiveId) =>
    `${baseURL}/objectives/${objectiveId}/tags`,
  REMOVE_OBJECTIVE_TAGS: (objectiveId) =>
    `${baseURL}/objectives/${objectiveId}/tags`,
  DELETE_OBJECTIVES: () => `${baseURL}/objectives/objectives`,
};

export const roadmapEndPoints = {
  // ROADMAP Endpoints
  ADD_ROADMAP: () => `${baseURL}/roadmaps`,
  GET_ALL_ROADMAPS: () => `${baseURL}/roadmaps`,
  UPDATE_ROADMAP: (roadmapId) => `${baseURL}/roadmaps/${roadmapId}`,
  UPDATE_ROADMAPS: () => `${baseURL}/roadmaps/roadmaps`,
  DELETE_ROADMAP: (roadmapId) => `${baseURL}/roadmaps/${roadmapId}`,
  ADD_ROADMAP_TAGS: (roadmapId) => `${baseURL}/roadmaps/${roadmapId}/tags`,
  ADD_ROADMAP_RELEASES: (roadmapId) =>
    `${baseURL}/roadmaps/${roadmapId}/releases`,
  ADD_ROADMAP_TIME_FRAMES: (roadmapId) =>
    `${baseURL}/roadmaps/${roadmapId}/timeFrames`,
  ADD_ROADMAP_PRODUCTS: (roadmapId) =>
    `${baseURL}/roadmaps/${roadmapId}/products`,
  UPDATE_ROADMAP_IDEAS: (roadmapId, timeFrameId) =>
    `${baseURL}/roadmaps/${roadmapId}/${timeFrameId}/ideas`,
  REMOVE_ROADMAP_IDEAS: (roadmapId, timeFrameId) =>
    `${baseURL}/roadmaps/${roadmapId}/${timeFrameId}/ideas`,
  DELETE_ROADMAPS: () => `${baseURL}/roadmaps/roadmaps`,
};

export const keyResultEndPoints = {
  ADD_KEY_RESULT: () => `${baseURL}/keyresults`,
  GET_ALL_KEY_RESULTS: () => `${baseURL}/keyresults`,
  UPDATE_KEY_RESULT: (keyResultId) => `${baseURL}/keyresults/${keyResultId}`,
  DELETE_KEY_RESULT: (keyResultId) => `${baseURL}/keyresults/${keyResultId}`,
  UPDATE_KEY_RESULT_FEATURES: (keyResultId) =>
    `${baseURL}/keyresults/${keyResultId}/features`,
  REMOVE_KEY_RESULT_FEATURES: (keyResultId) =>
    `${baseURL}/keyresults/${keyResultId}/features`,
  UPDATE_KEY_RESULT_IDEAS: (keyResultId) =>
    `${baseURL}/keyresults/${keyResultId}/ideas`,
  REMOVE_KEY_RESULT_IDEAS: (keyResultId) =>
    `${baseURL}/keyresults/${keyResultId}/ideas`,
  ADD_KEY_RESULT_TAGS: (keyResultId) =>
    `${baseURL}/keyresults/${keyResultId}/tags`,
};

export const authEndPoints = {
  // USER Endpoints
  SIGNIN: baseURL + `/auth/signin`,
  SIGNUP: baseURL + `/auth/signup`,
  LOGOUT: baseURL + `/auth/logout`,
  CHANGE_PASSWORD: baseURL + `/auth/change-password`,
  REQUEST_PASSWORD_RESET: baseURL + `/auth/request-password-reset`,
  VERIFY_OTP: baseURL + `/auth/verify-otp`,
  RESET_PASSWORD: baseURL + `/auth/reset-password`,
  COMPANY_REGISTER: baseURL + `/auth/company-register`,
  SIGNUP_WITH_GOOGLE: baseURL + `/auth/google-register`,
};

export const companyEndPoints = {
  // Company Endpoints
  GET_COMPANY_DETAILS: (companyId) => `${baseURL}/companies/${companyId}`,
  GET_COMPANY_FEATURE_STATUSES: (companyId) =>
    `${baseURL}/companies/${companyId}/features/statuses`,
  GET_COMPANY_IDEA_STATUSES: (companyId) =>
    `${baseURL}/companies/${companyId}/ideas/statuses`,
  ADD_COMPANY_STATUS: (companyId) =>
    `${baseURL}/companies/${companyId}/statuses`,
  EDIT_COMPANY_STATUS: (companyId, statusId) =>
    `${baseURL}/companies/${companyId}/statuses/${statusId}`,
  DELETE_COMPANY_STATUS: (companyId, statusId) =>
    `${baseURL}/companies/${companyId}/statuses/${statusId}`,
};

export const statusEndPoints = {
  // status Endpoints
  GET_STATUSES: `${baseURL}/statuses`,
  ADD_STATUS: `${baseURL}/statuses`,
  EDIT_STATUS: (statusId) => `${baseURL}/statuses/${statusId}`,
  DELETE_STATUS: (statusId) => `${baseURL}/statuses/${statusId}`,
};

export const tagEndPoints = {
  GET_COMPANY_TAGS: () => `${baseURL}/tags`,
  ADD_COMPANY_TAG: () => `${baseURL}/tags`,
  DELETE_COMPANY_TAG: (tagId) => `${baseURL}/tags/${tagId}`,
  EDIT_COMPANY_TAG: (tagId) => `${baseURL}/tags/${tagId}`,
};

export const productEndPoints = {
  GET_COMPANY_PRODUCTS: () => `${baseURL}/products`,
  ADD_COMPANY_PRODUCT: () => `${baseURL}/products`,
  EDIT_COMPANY_PRODUCT: (productId) => `${baseURL}/products/${productId}`,
  DELETE_COMPANY_PRODUCT: (productId) => `${baseURL}/products/${productId}`,
};

export const releaseEndPoints = {
  GET_COMPANY_RELEASES: () => `${baseURL}/releases`,
  ADD_COMPANY_RELEASE: () => `${baseURL}/releases`,
  EDIT_COMPANY_RELEASE: (releaseId) => `${baseURL}/releases/${releaseId}`,
  DELETE_COMPANY_RELEASE: (releaseId) => `${baseURL}/releases/${releaseId}`,
  GET_RELEASE_ROADMAPS: (releaseIds) =>
    `${baseURL}/roadmaps/releases?releaseIds=${releaseIds}`,
};

export const timeFrameEndPoints = {
  GET_COMPANY_TIME_FRAMES: () => `${baseURL}/timeframes`,
  ADD_COMPANY_TIME_FRAME: () => `${baseURL}/timeframes`,
  EDIT_COMPANY_TIME_FRAME: (timeframeId) =>
    `${baseURL}/timeframes/${timeframeId}`,
  DELETE_COMPANY_TIME_FRAME: (timeframeId) =>
    `${baseURL}/timeframes/${timeframeId}`,
  GET_TIME_FRAME_ROADMAPS: (timeFrameIds) =>
    `${baseURL}/roadmaps/timeFrames?timeFrameIds=${timeFrameIds}`,
};

export const userEndPoints = {
  // User Endpoints
  GET_USER_DETAILS: baseURL + `/users/getUserDetailsById`,
  UPDATE_USER_DETAILS: baseURL + `/users/profile-update`,
  INVITE_USER: baseURL + `/users/invite`,
  INVITE_PROCESS: baseURL + `/auth/invite/process`,
  GET_ALL_USERS: baseURL + `/users/by-company`,
  REORDER_PERSONAL_VIEWS: baseURL + `/users/reorder-personal-views`,
  REORDER_FAVORITE_VIEWS: baseURL + `/users/reorder-favorite-views`,
  INVITED_USERS: baseURL + `/users/invite`,
  RE_USERS: baseURL + `/users/re-invite`,
};

export const viewEndPoints = {
  // View Endpoints
  ADD_VIEW: baseURL + `/views`,
  GET_VIEWS: (module, viewTypeName, roadmapId) =>
    baseURL +
    `/views/${module}?viewTypeName=${viewTypeName}&roadmapId=${roadmapId}`,
  DELETE_VIEW: (viewId) => baseURL + `/views/${viewId}`,
  EDIT_VIEW: (viewId) => baseURL + `/views/${viewId}`,
  ADD_TO_PERSONAL_VIEW: (viewId) => baseURL + `/views/${viewId}/personal-views`,
  ADD_TO_FAVORITE_VIEW: (viewId) => baseURL + `/views/${viewId}/favorite-views`,
  GET_PERSONAL_VIEWS: () => baseURL + `/views/personal-views`,
  GET_FAVORITE_VIEWS: () => baseURL + `/views/favorite-views`,
  REMOVE_PERSONAL_VIEW: (viewId) => baseURL + `/views/${viewId}/personal-views`,
  REMOVE_FAVORITE_VIEW: (viewId) => baseURL + `/views/${viewId}/favorite-views`,
  UPDATE_PARTICULAR_ROADMAP_VIEW: (viewId) =>
    baseURL + `/views/${viewId}/single-roadmap`,
};

export const accountEndPoints = {
  // Account Endpoints
  ADD_ACCOUNT: baseURL + `/accounts`,
  GET_ACCOUNTS: baseURL + `/accounts`,
  UPDATE_ACCOUNT: (id) => `${baseURL}/accounts/${id}`,
  DELETE_ACCOUNT: (id) => `${baseURL}/accounts/${id}`,
};

export const activityLogEndPoints = {
  // Activity Log End Points
  GET_IDEA_ACTIVITY_LOGS: (ideaId) => `${baseURL}/activityLogs/${ideaId}/ideas`,
  GET_FEATURE_ACTIVITY_LOGS: (featureId) =>
    `${baseURL}/activityLogs/${featureId}/features`,
  GET_OBJECTIVE_ACTIVITY_LOGS: (objectiveId) =>
    `${baseURL}/activityLogs/${objectiveId}/objectives`,
  GET_ROADMAP_ACTIVITY_LOGS: (roadmapId) =>
    `${baseURL}/activityLogs/${roadmapId}/roadmaps`,
};

export const integrationsEndPoints = {
  // Integrations Endpoints
  CREATE_INTEGRATION: () => `${baseURL}/integrations`,
  GET_INTEGRATION: (name) => `${baseURL}/integrations/${name}`,
  GET_ALL_INTEGRATIONS: () => `${baseURL}/integrations`,
  UPDATE_INTEGRATION: () => `${baseURL}/integrations`,
  DELETE_INTEGRATION: (name) => `${baseURL}/integrations/${name}`,
};

export const jiraEndpoints = {
  FETCH_JIRA_ISSUES: () => `${baseURL}/jira/issues`,
  FETCH_JIRA_ISSUE: (key) => `${baseURL}/jira/issues/${key}`,
  FETCH_JIRA_PROJECTS: () => `${baseURL}/jira/projects`,
  IMPORT_JIRA_ISSUES: () => `${baseURL}/jira/issues/import`,
  PUSH_JIRA_ISSUE: () => `${baseURL}/jira/issues`,
  BULK_PUSH_JIRA_ISSUES: () => `${baseURL}/jira/issues/bulk`,
};

/* eslint-disable react/prop-types */
import React, {
  useState,
  useCallback,
  useEffect,
  useRef,
  useMemo,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import IconText from "components/common/IconText";
import DropdownMenu from "components/common/detailView/dropdown/DropdownMenu";
import TagsSearchDropdown from "components/common/detailView/dropdown/TagsSearchDropdown";

import TagsRowSection from "components/common/classicList/TagsRowSection";
import StatusRowSection from "components/common/classicList/StatusRowSection";
import AssigneeRowSection from "components/common/classicList/AssigneeRowSection";
import HealthRowSection from "components/common/classicList/HealthRowSection";
import TimeFrameRowSection from "components/common/classicList/TimeFrameRowSection";
import ColumnsRowSection from "components/common/classicList/ColumnsRowSection";

import { toSnakeCase, fieldIconMapping } from "utils";
import { objectiveHealths } from "utils/objectiveUtils";
import { generateStatusArray } from "utils/status";

import {
  updateRoadmapData,
  updateRoadmapTags,
  updateRoadmapReleases,
  updateRoadmapTimeFrames,
} from "reduxStore/operations/roadmapsAPI";
import { setRoadmapViews } from "reduxStore/slices/roadmapsSlice";

import useRowOutsideClick from "hooks/useRowOutsideClick";

const RoadmapsRowComponent = ({
  roadmap,
  onSelect,
  isSelected,
  selectedColumns,
  pl = "pl-0",
  py = "py-2",
  textSize = "text-sm",
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { releases, tags, statuses, timeFrames } = useSelector(
    (state) => state.company
  );
  const { users } = useSelector((state) => state.users);
  const [isHovered, setIsHovered] = useState(false);
  const [roadmapStatuses, setRoadmapStatuses] = useState([]);
  const [dropdownState, setDropdownState] = useState({
    isStatusDropdownOpen: false,
    isColumnsDropdownOpen: false,
    isHealthDropdownOpen: false,
    isAssigneeDropdownOpen: false,
    isTagDropdownOpen: false,
  });
  const dropdownRef = useRef(null);

  const [selectedRoadmapColumns, setSelectedRoadmapColumns] = useState(
    new Set()
  );
  const [selectRoadmapColumns, setSelectRoadmapColumns] = useState([]);
  const [initialSelectedRoadmapColumns, setInitialSelectedRoadmapColumns] =
    useState(new Set());

  const [selectedTags, setSelectedTags] = useState(new Set());
  const [selectTags, setSelectTags] = useState([]);
  const [initialSelectedTags, setInitialSelectedTags] = useState(new Set());

  useEffect(() => {
    setSelectTags(roadmap?.tags ?? []);
    setSelectedTags(new Set(roadmap?.tags?.map((tag) => tag._id)));
    setInitialSelectedTags(
      new Set((roadmap?.tags ?? []).map((tag) => tag._id))
    );
    setSelectRoadmapColumns(
      roadmap.for === "Release" ? roadmap?.releases : roadmap?.timeFrames ?? []
    );
    setSelectedRoadmapColumns(
      roadmap.for === "Release"
        ? new Set(roadmap?.releases?.map((release) => release._id))
        : new Set(roadmap?.timeFrames?.map((timeFrame) => timeFrame._id))
    );
    setInitialSelectedRoadmapColumns(
      roadmap.for === "Release"
        ? new Set(roadmap?.releases?.map((release) => release._id))
        : new Set(roadmap?.timeFrames?.map((timeFrame) => timeFrame._id))
    );
  }, [roadmap]);

  useEffect(() => {
    setRoadmapStatuses(statuses.roadmap);
  }, [statuses]);

  const statusArray = useMemo(
    () => generateStatusArray(roadmapStatuses),
    [roadmapStatuses]
  );

  const closeAllDropdowns = () => {
    setDropdownState({
      isStatusDropdownOpen: false,
      isHealthDropdownOpen: false,
      isTagDropdownOpen: false,
      isColumnsDropdownOpen: false,
      isAssigneeDropdownOpen: false,
    });
  };

  const handleRowClick = () => {
    dispatch(setRoadmapViews({ views: [] }));
    navigate(`/roadmaps/${roadmap._id}`);
  };

  const handleStatusOptionClick = (option) => {
    let { icon, ...restOption } = option;
    dispatch(
      updateRoadmapData(
        roadmap._id,
        { status: option._id },
        "status",
        roadmap,
        restOption
      )
    );
    closeAllDropdowns();
  };

  const handleHealthOptionClick = (option) => {
    let { icon, ...restOption } = option;
    dispatch(
      updateRoadmapData(
        roadmap._id,
        { health: toSnakeCase(option.label) },
        "health",
        roadmap,
        restOption
      )
    );
    closeAllDropdowns();
  };

  const handleAssineeOptionClick = (option) => {
    if (roadmap?.assignee?._id !== option._id) {
      let { icon, ...restOption } = option;
      dispatch(
        updateRoadmapData(
          roadmap._id,
          { assignee: option._id },
          "assignee",
          roadmap,
          restOption
        )
      );
    }
    closeAllDropdowns();
  };

  const handleTagDropdownOptionClick = (option) => {
    const tagId = option._id;
    const updatedTags = new Set(selectedTags);

    if (updatedTags.has(tagId)) {
      updatedTags.delete(tagId);
      setSelectTags((prevTags) => prevTags.filter((tag) => tag._id !== tagId));
    } else {
      updatedTags.add(tagId);
      setSelectTags((prevTags) => [...prevTags, option]);
    }

    setSelectedTags(updatedTags);
  };

  const handleColumnDropdownOptionClick = (option) => {
    const columnId = option._id;
    const updatedColumns = new Set(selectedRoadmapColumns);

    if (updatedColumns.has(columnId)) {
      updatedColumns.delete(columnId);
      setSelectRoadmapColumns((prevColumns) =>
        prevColumns.filter((column) => column._id !== columnId)
      );
    } else {
      updatedColumns.add(columnId);
      setSelectRoadmapColumns((prevColumns) => [...prevColumns, option]);
    }

    setSelectedRoadmapColumns(updatedColumns);
  };

  const getSelectedTagObjects = () => {
    return Array.from(selectedTags).map((id) =>
      selectTags.find((tag) => tag._id === id)
    );
  };

  const handleTagsOptionClick = (option) => {
    let fullTags = getSelectedTagObjects();
    dispatch(updateRoadmapTags(option, fullTags, roadmap));
    closeAllDropdowns();
  };

  const handleColumnsOptionClick = (option) => {
    if (roadmap.for === "Release") {
      dispatch(
        updateRoadmapReleases({ itemId: option.itemId, releases: option.items })
      );
    } else {
      dispatch(
        updateRoadmapTimeFrames({
          itemId: option.itemId,
          timeFrames: option.items,
        })
      );
    }
    closeAllDropdowns();
  };

  const toggleDropdown = useCallback((type) => {
    setDropdownState((prev) => {
      const newState = Object.keys(prev).reduce((acc, key) => {
        acc[key] = key === type ? !prev[key] : false;
        return acc;
      }, {});
      return newState;
    });
  }, []);

  useRowOutsideClick({
    item: roadmap,
    selectedTags,
    initialSelectedTags,
    tagDropdownState: dropdownState.isTagDropdownOpen,
    selectedColumns: selectedRoadmapColumns,
    initialSelectedColumns: initialSelectedRoadmapColumns,
    columnsDropdownState: dropdownState.isColumnsDropdownOpen,
    dropdownRef,
    handleTagsOptionClick,
    handleColumnsOptionClick,
    closeAllDropdowns,
    getSelectedTagObjects,
  });

  return (
    <tr
      className={` group text-xs text-title_50 cursor-pointer hover:bg-row-background relative ${
        isSelected ? "bg-button-active_10" : ""
      }`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={handleRowClick}
      ref={dropdownRef}
    >
      <td
        className={`flex py-1 ${pl} relative sticky top-0 left-0 z-20 group-hover:bg-row-background  group-hover:rounded-l-lg ${
          isSelected ? "bg-button-active_10" : "bg-white"
        }`}
      >
        <div
          className={`flex p-1 items-center ${
            !isSelected ? (isHovered ? "visible" : "invisible") : "visible"
          }`}
        >
          <input
            type="checkbox"
            checked={isSelected}
            onChange={() => {
              onSelect(roadmap);
            }}
            onClick={(e) => {
              e.stopPropagation();
            }}
            className="cursor-pointer"
          />
        </div>
        <IconText
          icon={React.createElement(fieldIconMapping.roadmap)}
          iconSize={14}
          text={roadmap?.title ?? "N/A"}
          textColor="text-title"
          textSize={textSize}
          isASidebarComponent={false}
          hoverable={false}
          iconColor="text-roadmaps-icon"
          px=""
          py={py}
        />
      </td>
      {selectedColumns?.map((key, index) => {
        const snakeCaseKey = toSnakeCase(key);

        return (
          <td
            key={index}
            className={`${
              key === "tags" || key === "columns"
                ? "min-w-44 max-w-66"
                : "max-w-44"
            } truncate pr-1 px-3`}
          >
            {(() => {
              switch (snakeCaseKey) {
                case "status":
                  return (
                    <StatusRowSection
                      status={roadmap?.[snakeCaseKey] ?? ""}
                      dropdownState={dropdownState}
                      toggleDropdown={() => {
                        toggleDropdown("isStatusDropdownOpen");
                      }}
                      statusArray={statusArray}
                    />
                  );
                case "columns":
                  return (
                    <ColumnsRowSection
                      roadmap={roadmap}
                      columns={selectRoadmapColumns}
                      dropdownState={dropdownState}
                      toggleDropdown={() =>
                        toggleDropdown("isColumnsDropdownOpen")
                      }
                    />
                  );
                case "health":
                  return (
                    <HealthRowSection
                      health={roadmap?.[snakeCaseKey] ?? ""}
                      dropdownState={dropdownState}
                      toggleDropdown={() =>
                        toggleDropdown("isHealthDropdownOpen")
                      }
                    />
                  );
                case "tags":
                  return (
                    <TagsRowSection
                      tags={selectTags ?? []}
                      dropdownState={dropdownState}
                      toggleDropdown={() => toggleDropdown("isTagDropdownOpen")}
                    />
                  );
                case "assignee":
                  return (
                    <AssigneeRowSection
                    name={roadmap?.[snakeCaseKey]?.name ?? ""}
                    dropdownState={dropdownState}
                    toggleDropdown={() =>
                      toggleDropdown("isAssigneeDropdownOpen")
                    }
                  />
                  );
                case "for":
                  return (
                    <IconText
                      icon={React.createElement(fieldIconMapping.type)}
                      text={roadmap?.for}
                      gap_x="gap-x-1.5"
                      px=""
                      py=""
                      iconColor="text-primary"
                      textColor="text-title_50"
                      textSize="text-xs"
                      iconSize="12"
                      hoverable={false}
                    />
                  );
                case "time_frame":
                  return (
                    <TimeFrameRowSection
                      timeFrame={roadmap?.timeFrame}
                      dropdownState={dropdownState}
                      toggleDropdown={() =>
                        toggleDropdown("isTimeFrameDropdownOpen")
                      }
                    />
                  );
                default:
                  return roadmap?.[snakeCaseKey] ?? "";
              }
            })()}
            {snakeCaseKey === "status" && (
              <DropdownMenu
                options={statusArray ? statusArray : []}
                isOpen={dropdownState.isStatusDropdownOpen}
                position="top-full -mt-3 w-32"
                handleOptionClick={handleStatusOptionClick}
                item={roadmap}
                type="status"
              />
            )}
            {snakeCaseKey === "health" && (
              <DropdownMenu
                options={objectiveHealths() ?? []}
                isOpen={dropdownState.isHealthDropdownOpen}
                handleOptionClick={handleHealthOptionClick}
                item={roadmap}
                type="health"
              />
            )}
              {snakeCaseKey === "assignee" && (
              <DropdownMenu
                options={users ?? []}
                isOpen={dropdownState.isAssigneeDropdownOpen}
                position="top-full -mt-3 w-32"
                handleOptionClick={handleAssineeOptionClick}
                withAddOption={true}
                handleAddOptionClick={() =>
                  navigate("/settings/customizations/product")
                }
                item={roadmap}
                type="assignee"
              />
            )}
            {snakeCaseKey === "columns" && (
              <DropdownMenu
                options={(roadmap.for !== "Release"
                  ? timeFrames
                  : releases
                )?.map((item) => ({
                  _id: item._id,
                  label: item.label,
                  icon:
                    roadmap.for !== "Release"
                      ? fieldIconMapping.timeFrames
                      : fieldIconMapping.release,
                  iconColor: roadmap.for !== "Release" ? item.color : undefined,
                }))}
                isOpen={dropdownState.isColumnsDropdownOpen}
                iconSize={12}
                handleOptionClick={handleColumnDropdownOptionClick}
                multiSelect={true}
                selectedItems={selectedRoadmapColumns}
              />
            )}
            {snakeCaseKey === "tags" && (
              <div className="absolute w-48 z-20 bg-white -ml-8">
                <TagsSearchDropdown
                  searchPlaceholder={"Search Tags..."}
                  items={tags ?? []}
                  selectedItems={selectedTags}
                  isDropdownOpen={dropdownState.isTagDropdownOpen}
                  onItemSelect={handleTagDropdownOptionClick}
                />
              </div>
            )}
          </td>
        );
      })}
    </tr>
  );
};

export default RoadmapsRowComponent;

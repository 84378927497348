import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "components/common/Loader";
import { toast } from "react-toastify";
import { isObjectEmpty } from "utils";
import SearchDropdown from "../dropdown/SearchDropdown";
import LinkedItemList from "./LinkedItemList";

const LinkDetailViewTab = ({
  currentItem, // e.g., currentFeature, currentIdea
  selectedItems, // e.g., selectedFeatures, selectedIdeas
  handleItemSelect, // e.g., handleFeatureSelect, handleIdeaSelect
  updateLinkedItems, // e.g., updateFeatureIdeas, updateIdeaFeatures
  removeLinkedItems, // e.g., updateFeatureIdeas, updateIdeaFeatures
  clearSelectedItems, // e.g., clearSelectedFeatures, clearSelectedIdeas
  fetchAllItems, // e.g., fetchAllFeatures, fetchAllIdeas
  itemIconComponent, // e.g., FeatureIcon, IdeaIcon
  linkedItemsKey, // e.g., "features", "ideas"
  searchPlaceholder, // e.g., "Search features...", "Search ideas..."
  onLinkedItemClick, // Function to handle click on linked item (e.g., open detail view)
  with_linked_item_list = true,
  with_different_tabs = [],
}) => {
  const dispatch = useDispatch();
  const itemsFromStore = useSelector((state) => state[linkedItemsKey]);
  const [allItems, setAllItems] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [linkedItemIds, setLinkedItemIds] = useState([]);

  useEffect(() => {
    if (!isObjectEmpty(itemsFromStore[linkedItemsKey])) {
      setAllItems(itemsFromStore[linkedItemsKey]);
    } else {
      dispatch(fetchAllItems());
    }
  }, [linkedItemsKey, itemsFromStore, dispatch, fetchAllItems]);

  useEffect(() => {
    if (currentItem) {
      const ids =
        Array.isArray(currentItem?.[linkedItemsKey]) &&
        currentItem?.[linkedItemsKey]?.[0]?._id
          ? currentItem[linkedItemsKey].map((item) => item._id)
          : currentItem[linkedItemsKey] || [];
      setLinkedItemIds(ids);
    }
  }, [currentItem, linkedItemsKey]);

  const filteredItems = useMemo(() => {
    return allItems
      .filter((item) => !linkedItemIds.includes(item._id))
      .filter((item) =>
        item.title.toLowerCase().includes(searchTerm.trim().toLowerCase())
      );
  }, [allItems, searchTerm, linkedItemIds]);

  const handleUpdateLinkedItems = () => {
    const selectedItemsArray = Array.from(selectedItems);
    if (selectedItemsArray.length === 0) {
      toast.warning("No item selected");
      return;
    }
    updateLinkedItems(selectedItemsArray);
    closeDropdownAndReset();
  };

  const closeDropdownAndReset = () => {
    setDropdownOpen(false);
    setSearchTerm("");
    clearSelectedItems();
  };

  const openDropdownAndReset = () => {
    setDropdownOpen(true);
    setSearchTerm("");
    clearSelectedItems();
  };

  const handleRemoveLinkedItem = (linkedItem) => {
    removeLinkedItems([linkedItem._id]);
  };

  return (
    <div>
      {!allItems ? (
        <div className="flex items-center justify-center h-full">
          <Loader />
        </div>
      ) : (
        <div className="flex flex-col gap-y-4">
          <div className="flex justify-between">
            <SearchDropdown
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
              searchPlaceholder={searchPlaceholder}
              allItems={allItems}
              filteredItems={filteredItems}
              isDropdownOpen={isDropdownOpen}
              handleItemSelect={handleItemSelect}
              selectedItems={selectedItems}
              linkedItemsKey={linkedItemsKey}
              handleUpdateLinkedItems={handleUpdateLinkedItems}
              itemIconComponent={itemIconComponent}
              closeDropdownAndReset={closeDropdownAndReset}
              openDropdownAndReset={openDropdownAndReset}
              with_different_tabs={with_different_tabs}
            />
          </div>
          {with_linked_item_list && (
            <LinkedItemList
              linkedItemIds={linkedItemIds}
              allItems={allItems}
              itemIconComponent={itemIconComponent}
              linkedItemsKey={linkedItemsKey}
              handleRemoveLinkedItem={handleRemoveLinkedItem}
              onLinkedItemClick={onLinkedItemClick}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default LinkDetailViewTab;

// /* eslint-disable react/prop-types */
// import React, { useEffect, useMemo, useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import Loader from "components/common/Loader";
// import { toast } from "react-toastify";
// import { isObjectEmpty } from "utils";
// import SearchDropdown from "../dropdown/SearchDropdown";
// import LinkedItemList from "./LinkedItemList";

// const LinkDetailViewTab = ({
//   currentItem, // e.g., currentFeature, currentIdea
//   selectedItems, // e.g., selectedFeatures, selectedIdeas
//   handleItemSelect, // e.g., handleFeatureSelect, handleIdeaSelect
//   updateLinkedItems, // e.g., updateFeatureIdeas, updateIdeaFeatures
//   removeLinkedItems, // e.g., updateFeatureIdeas, updateIdeaFeatures
//   clearSelectedItems, // e.g., clearSelectedFeatures, clearSelectedIdeas
//   fetchAllItems, // e.g., fetchAllFeatures, fetchAllIdeas
//   itemIconComponent, // e.g., FeatureIcon, IdeaIcon
//   linkedItemsKey, // e.g., "features", "ideas"
//   searchPlaceholder, // e.g., "Search features...", "Search ideas..."
//   onLinkedItemClick, // Function to handle click on linked item (e.g., open detail view)
//   with_linked_item_list = true,
//   with_different_tabs = [],
// }) => {
//   const dispatch = useDispatch();
//   const itemsFromStore = useSelector((state) => state[linkedItemsKey]);
//   const [allItems, setAllItems] = useState([]);
//   const [searchTerm, setSearchTerm] = useState("");
//   const [isDropdownOpen, setDropdownOpen] = useState(false);

//   useEffect(() => {
//     if (!isObjectEmpty(itemsFromStore[linkedItemsKey])) {
//       setAllItems(itemsFromStore[linkedItemsKey]);
//     } else {
//       dispatch(fetchAllItems());
//     }
//   }, [linkedItemsKey]);

//   const linkedItemIds =
//     currentItem &&
//     Array.isArray(currentItem?.[linkedItemsKey]) &&
//     currentItem?.[linkedItemsKey]?.[0]?._id
//       ? (currentItem?.[linkedItemsKey] ?? []).map((item) => item._id)
//       : currentItem?.[linkedItemsKey] ?? [];

//   const filteredItems = useMemo(() => {
//     return allItems
//       .filter((item) => !linkedItemIds.includes(item._id))
//       .filter((item) =>
//         item.title.toLowerCase().includes(searchTerm.trim().toLowerCase())
//       );
//   }, [allItems, searchTerm]);

//   const handleUpdateLinkedItems = () => {
//     const selectedItemsArray = Array.from(selectedItems);
//     if (selectedItemsArray.length === 0) {
//       toast.warning("No item selected");
//       return;
//     }
//     updateLinkedItems(selectedItemsArray);
//     closeDropdownAndReset();
//   };

//   const closeDropdownAndReset = () => {
//     setDropdownOpen(false);
//     setSearchTerm("");
//     clearSelectedItems();
//   };

//   const openDropdownAndReset = () => {
//     setDropdownOpen(true);
//     setSearchTerm("");
//     clearSelectedItems();
//   };

//   const handleRemoveLinkedItem = (linkedItem) => {
//     removeLinkedItems([linkedItem._id]);
//   };

//   return (
//     <div>
//       {!allItems ? (
//         <div className="flex items-center justify-center h-full">
//           <Loader />
//         </div>
//       ) : (
//         <div className="flex flex-col gap-y-4">

//           <div className="flex justify-between">
//             <SearchDropdown
//               searchTerm={searchTerm}
//               setSearchTerm={setSearchTerm}
//               searchPlaceholder={searchPlaceholder}
//               allItems={allItems}
//               filteredItems={filteredItems}
//               isDropdownOpen={isDropdownOpen}
//               handleItemSelect={handleItemSelect}
//               selectedItems={selectedItems}
//               linkedItemsKey={linkedItemsKey}
//               handleUpdateLinkedItems={handleUpdateLinkedItems}
//               itemIconComponent={itemIconComponent}
//               closeDropdownAndReset={closeDropdownAndReset}
//               openDropdownAndReset={openDropdownAndReset}
//               with_different_tabs={with_different_tabs}
//             />
//           </div>
//           {with_linked_item_list && (
//             <LinkedItemList
//               linkedItemIds={linkedItemIds}
//               allItems={allItems}
//               itemIconComponent={itemIconComponent}
//               linkedItemsKey={linkedItemsKey}
//               handleRemoveLinkedItem={handleRemoveLinkedItem}
//               onLinkedItemClick={onLinkedItemClick}
//             />
//           )}
//         </div>
//       )}
//     </div>
//   );
// };

// export default LinkDetailViewTab;

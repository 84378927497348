import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { getCookie } from "utils/cookieUtils";
import { signUp } from "reduxStore/operations/authAPI";
import { setLoading } from "reduxStore/slices/authSlice";
import Loader from "../common/Loader";
import { CiEdit } from "react-icons/ci";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
import { signUpWithGoogle } from "../../reduxStore/operations/authAPI";

const Register = () => {
  const [email, setEmail] = useState("");
  const [errorMessages, setErrorMessages] = useState({});
  const [step, setStep] = useState(1);

  const { loading } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isValidEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const validateForm = () => {
    let errors = {};

    if (!email) {
      errors.email = "Email is required.";
    } else if (!isValidEmail(email)) {
      errors.email = "Enter a valid email.";
    }

    setErrorMessages(errors);

    return Object.keys(errors).length === 0; // Return true if no errors
  };

  const handleRegister = (e) => {
    e.preventDefault();
    if (validateForm()) {
      dispatch(setLoading(true));
      dispatch(signUp(email, setStep));
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("jwt");
    if (token) {
      navigate("/"); // Redirect to home page if user is already logged in
    }
  }, [navigate]);

  const clientId = import.meta.env.VITE_GOOGLE_CLIENT_ID;
  const handleLoginSuccess = (response) => {
    dispatch(signUpWithGoogle(response.credential, navigate));
  };

  const handleLoginFailure = (error) => {
    console.error("Login Failed", error);
  };

  return (
    <div className="flex flex-col gap-4 items-center justify-center min-h-screen bg-gray-100">
      <div className=" relative bg-white p-8 rounded shadow-md w-full max-w-sm">
        <div className="  absolute top-[-120px] right-0 w-full  flex items-center justify-center  ">
          <img
            src="/logo.png  "
            alt="logo"
            className=" w-0 h-0  xl:w-[200px] xl:h-[100px] "
          />{" "}
        </div>

        {step === 1 && (
          <>
            <h2 className="text-2xl mb-6 text-center">Create your account</h2>
            <form onSubmit={handleRegister}>
              {/* Email Field */}

              <div className="mb-6">
                <GoogleOAuthProvider clientId={clientId}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <GoogleLogin
                      onSuccess={handleLoginSuccess}
                      onError={handleLoginFailure}
                      text="continue_with"
                      shape="rectangular"
                      size="large"
                      theme="outline"
                      logo_alignment="center"
                      width="320"
                    />
                  </div>
                </GoogleOAuthProvider>
              </div>

              <div className="flex items-center justify-center mb-4">
                <div className="flex-grow border-t border-gray-300"></div>
                <span className="mx-4 text-gray-500">OR</span>
                <div className="flex-grow border-t border-gray-300"></div>
              </div>

              <div className="mb-4">
                <label className="block text-gray-700">Work email</label>
                <input
                  type="email"
                  className={`w-full p-2 border ${
                    errorMessages.email ? "border-red-500" : "border-gray-300"
                  } rounded mt-1`}
                  value={email}
                  placeholder="name@company.com"
                  onChange={(e) => setEmail(e.target.value)}
                />
                {errorMessages.email && (
                  <p className="text-red-500 text-sm mt-1">
                    {errorMessages.email}
                  </p>
                )}
              </div>

              {/* Submit Button */}
              <button
                type="submit"
                className="w-full bg-blue-500 text-white p-2 rounded mt-4 hover:bg-blue-600"
              >
                {loading ? <Loader size="20" color="white" /> : "Sign Up"}
              </button>
            </form>
            <p className=" text-[14px] mt-4">
              By signing up, you agree to our{" "}
              <a
                className=" text-blue-500 hover:underline "
                href="https://www.laneapp.co/termsofuse"
                target="_blank"
                rel="noopener noreferrer"
              >
                {" "}
                Terms of Use
              </a>{" "}
              and{" "}
              <a
                className=" text-blue-500 hover:underline "
                href="https://www.laneapp.co/privacypolicy"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Policy.
              </a>
            </p>
            {/* Already have an account */}
          </>
        )}

        {step === 2 && (
          <>
            <p className="text-gray-600 text-center mt-2 text-[1.1rem] mb-10">
              We’ve sent a magic link to:{" "}
              <span className="underline decoration-solid decoration-1">
                {email}
                {""}
                <CiEdit
                  className="ml-2 inline cursor-pointer"
                  onClick={() => setStep(1)}
                />
              </span>
              {/* <p className="text-center text-gray-600">
                <button className="text-blue-500">Change email</button>
              </p> */}
            </p>

            <p className="text-gray-600 text-center text-[1rem] mb-16">
              <i>(It might take upto 5 minutes - check spam if needed)</i>
            </p>

            <p className="text-gray-600 text-center text-[1.1rem]">
              Click the link to confirm your email and continue setting up your
              account.🙌
            </p>
          </>
        )}
      </div>
      {step === 1 && (
        <p className="text-center text-gray-600 mt-1">
          Already have an account?{" "}
          <Link to="/" className="text-blue-500">
            Sign In
          </Link>
        </p>
      )}
    </div>
  );
};

export default Register;

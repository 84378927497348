import React, { useEffect, useState } from "react";
import SettingsTopbar from "./SettingsTopbar";
import { CreditCard, Users as UsersIcon } from "react-flaticons";
import { useSelector } from "react-redux";
import Default from "./Users/Default";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { toSnakeCase } from "../../utils";

const Users = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const tabs = [
    { label: "Added", path: "users" },
    { label: " Invited", path: "users/invited-users" },
  ];

  const getInitialTab = () => {
    const currentPath = location.pathname.split("/").pop();
    const foundTab = tabs.find((tab) => toSnakeCase(tab.label) === currentPath);
    return foundTab || tabs[0];
  };

  const [activeTab, setActiveTab] = useState(getInitialTab());

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  useEffect(() => {
    const newPath = toSnakeCase(activeTab.path);
    navigate(`/settings/${newPath}`);
  }, [activeTab, navigate]);

  return (
    <div>
      <SettingsTopbar
        title="Users"
        icon={<UsersIcon />}
        tabs={tabs}
        activeTab={activeTab}
        handleTabClick={handleTabClick}
        isUsers={true}
      />
      <div className="px-2 pt-5">
        <Outlet />
      </div>
    </div>
  );
};

export default Users;

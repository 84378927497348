import { toast } from "react-toastify";
import { roadmapEndPoints } from "api/api";
import { apiConnector } from "api/apiconnector";
import {
  appendRoadmap,
  removeRoadmap,
  removeRoadmaps,
  setIsRoadmapLoading,
  setRoadmaps,
  updateRoadmap,
  updateRoadmaps,
} from "../slices/roadmapsSlice";
import { removeIdeaRoadmaps, updateIdeaRoadmaps } from "../slices/ideaSlice";
import React from "react";
import CustomToast from "../../components/common/CustomToast";
import { getReleaseRoadmaps } from "./releaseAPI";
import { getTimeFrameRoadmaps } from "./timeFramesAPI";
import { updateRoadmapIdPersonalView } from "../slices/personalViewSlice";

const {
  ADD_ROADMAP,
  GET_ALL_ROADMAPS,
  UPDATE_ROADMAP,
  UPDATE_ROADMAPS,
  ADD_ROADMAP_TAGS,
  ADD_ROADMAP_RELEASES,
  ADD_ROADMAP_TIME_FRAMES,
  ADD_ROADMAP_PRODUCTS,
  UPDATE_ROADMAP_IDEAS,
  DELETE_ROADMAP,
  DELETE_ROADMAPS,
  REMOVE_ROADMAP_IDEAS,
} = roadmapEndPoints;

export const createRoadmap = (
  title,
  forRoadmap,
  products,
  releases,
  timeFrames,
  manualTimeFrame,
  status,
  assignee
) => {
  return async (dispatch) => {
    try {
      const response = await apiConnector("POST", ADD_ROADMAP(), {
        title,
        forRoadmap,
        products,
        releases,
        timeFrames,
        manualTimeFrame,
        status,
        assignee,
      });

      if (response.status === 201 && response.data) {
        dispatch(appendRoadmap(response.data.newRoadmap));
        if (forRoadmap === "Release") {
          dispatch(getReleaseRoadmaps(releases));
        }
        if (forRoadmap === "Strategy") {
          dispatch(getTimeFrameRoadmaps(timeFrames));
        }
        toast(
          React.createElement(CustomToast, {
            linkAddress: "/roadmaps",
            title,
            module: "Roadmap",
            idModule: response.data.newRoadmap,
          })
        );
      } else {
        toast.error("Failed to add roadmaps");
      }
    } catch (error) {
      console.error("Error adding roadmaps:", error);
      toast.error("Error adding roadmaps");
    }
  };
};

export const getAllRoadmaps = (page = 1, limit = 100) => {
  return async (dispatch) => {
    try {
      dispatch(setIsRoadmapLoading(true));
      const queryParams = new URLSearchParams({ page, limit }).toString();
      const response = await apiConnector(
        "GET",
        `${GET_ALL_ROADMAPS()}?${queryParams}`
      );
      if (response.status === 200 && response.data) {
        dispatch(setRoadmaps(response.data.roadmaps));
      } else {
        toast.error("Failed to fetch roadmaps");
      }
    } catch (error) {
      console.error("Error fetching roadmaps:", error);
      toast.error("Error fetching roadmaps");
    }
  };
};

export const updateRoadmapData = (
  roadmapId,
  updatedData,
  isUpdated,
  roadmap,
  restOption
) => {
  return async (dispatch) => {
    let newRoadmap = {};
    if (roadmap && restOption) {
      // Creating a deep copy of the objective to avoid mutating the original object
      newRoadmap = JSON.parse(JSON.stringify(roadmap));
      switch (isUpdated) {
        case "status":
          newRoadmap.status = restOption;
          break;
        case "health":
          newRoadmap.health = updatedData.health;
          break;
        case "product":
          newRoadmap.product = restOption;
          break;
        case "assignee":
          newRoadmap.assignee = restOption;
          break;
        default:
          break;
      }
      dispatch(updateRoadmap({ updatedRoadmap: newRoadmap }));
    }
    try {
      const response = await apiConnector(
        "PUT",
        UPDATE_ROADMAP(roadmapId),
        updatedData
      );

      if (response.status === 200 && response.data) {
        dispatch(updateRoadmap({ updatedRoadmap: response.data.roadmap }));
        if (isUpdated === "title") {
          dispatch(
            updateRoadmapIdPersonalView({
              updatedView: { label: response.data.roadmap.title },
              roadmapId: roadmapId,
              isUpdated: "label",
            })
          );
        }
        // toast.success("Roadmap updated successfully");
      }
    } catch (error) {
      toast.error(`Error updating feature: ${error.message}`);
      dispatch(updateRoadmap({ updatedRoadmap: roadmap }));
    }
  };
};

export const updateRoadmapsData = (roadmaps, update, isUpdatedArray) => {
  return async (dispatch) => {
    try {
      const response = await apiConnector("PUT", UPDATE_ROADMAPS(), {
        roadmaps,
        update,
      });
      if (response.status === 200) {
        dispatch(
          updateRoadmaps({
            updatedRoadmaps: response.data.roadmaps,
            isUpdatedArray: isUpdatedArray,
          })
        );
      }
    } catch (error) {
      console.log("Error updating Roadmaps", error);
    }
  };
};

export const updateRoadmapTags =
  (option, fullTags, roadmap) => async (dispatch) => {
    let newRoadmap = {};
    if (roadmap) {
      // Creating a deep copy of the objective to avoid mutating the original object
      newRoadmap = JSON.parse(JSON.stringify(roadmap));
      newRoadmap.tags = fullTags;
      dispatch(
        updateRoadmap({
          updatedRoadmap: newRoadmap,
          isUpdated: "tags",
        })
      );
    }

    try {
      const response = await apiConnector(
        "PATCH",
        ADD_ROADMAP_TAGS(option.itemId),
        {
          tags: option.tags,
        }
      );
      if (response.status === 200) {
        dispatch(
          updateRoadmap({
            updatedRoadmap: response.data.roadmap,
            isUpdated: "tags",
          })
        );
        toast.success("tags added successfully");
      } else {
        dispatch(
          updateRoadmap({
            updatedRoadmap: roadmap,
            isUpdated: "tags",
          })
        );
        console.error("Failed to update tag:", response.statusText);
      }
    } catch (error) {
      console.error("Error adding tag:", error);
      toast.error("Error adding tag:");
      dispatch(
        updateRoadmap({
          updatedRoadmap: roadmap,
          isUpdated: "tags",
        })
      );
    }
  };

export const updateRoadmapReleases = (option) => async (dispatch) => {
  try {
    const response = await apiConnector(
      "PATCH",
      ADD_ROADMAP_RELEASES(option.itemId),
      {
        releases: option.releases,
      }
    );
    if (response.status === 200) {
      dispatch(
        updateRoadmap({
          updatedRoadmap: response.data.roadmap,
          isUpdated: "releases",
        })
      );
      if (option.releases?.length > 0) {
        dispatch(getReleaseRoadmaps(option.releases));
      }
      toast.success("Columns added successfully");
    } else {
      console.error("Failed to update columns:", response.statusText);
    }
  } catch (error) {
    console.error("Error adding columns:", error);
    toast.error("Error adding columns:");
  }
};

export const updateRoadmapTimeFrames = (option) => async (dispatch) => {
  try {
    const response = await apiConnector(
      "PATCH",
      ADD_ROADMAP_TIME_FRAMES(option.itemId),
      {
        timeFrames: option.timeFrames,
      }
    );
    if (response.status === 200) {
      dispatch(
        updateRoadmap({
          updatedRoadmap: response.data.roadmap,
          isUpdated: "timeFrames",
        })
      );
      if (option.timeFrames?.length > 0) {
        dispatch(getTimeFrameRoadmaps(option.timeFrames));
      }
      toast.success("Columns added successfully");
    } else {
      console.error("Failed to update columns:", response.statusText);
    }
  } catch (error) {
    console.error("Error adding columns:", error);
    toast.error("Error adding columns:");
  }
};
export const updateRoadmapProducts = (option) => async (dispatch) => {
  try {
    const response = await apiConnector(
      "PATCH",
      ADD_ROADMAP_PRODUCTS(option.itemId),
      {
        products: option.products,
      }
    );
    if (response.status === 200) {
      dispatch(
        updateRoadmap({
          updatedRoadmap: response.data.roadmap,
          isUpdated: "products",
        })
      );
      dispatch(getReleaseRoadmaps(response.data.roadmap.releases));

      toast.success("Products added successfully");
    } else {
      console.error("Failed to update products:", response.statusText);
    }
  } catch (error) {
    console.error("Error adding products:", error);
    toast.error("Error adding products:");
  }
};

export const updateRoadmapIdeas =
  (roadmapId, timeFrameId, ideas) => async (dispatch) => {
    console.log(roadmapId, timeFrameId, ideas);
    try {
      const response = await apiConnector(
        "PATCH",
        UPDATE_ROADMAP_IDEAS(roadmapId, timeFrameId),
        {
          ideas,
        }
      );
      if (response.status === 200) {
        dispatch(
          updateRoadmap({
            updatedRoadmap: response.data.roadmap,
            isUpdated: "ideas",
          })
        );
        dispatch(
          updateIdeaRoadmaps({
            updatedIdeas: response.data.roadmap.ideas,
            roadmap: response.data.roadmap,
          })
        );

        // toast.success("Ideas added successfully");
      } else {
        console.error("Failed to update ideas:", response.statusText);
      }
    } catch (error) {
      console.error("Error adding ideas:", error);
      toast.error("Error adding ideas:");
    }
  };

export const removeRoadmapIdeas =
  (roadmapId, timeFrameId, ideas) => async (dispatch) => {
    // console.log(roadmapId, timeFrameId, ideaId);
    // console.log(ideaId);

    try {
      const response = await apiConnector(
        "DELETE",
        REMOVE_ROADMAP_IDEAS(roadmapId, timeFrameId),
        {
          ideas,
        }
      );

      if (response.status === 200) {
        console.log(response.data.roadmap.ideas);
        dispatch(
          updateRoadmap({
            updatedRoadmap: response.data.roadmap,
            isUpdated: "ideas",
          })
        );
        dispatch(
          removeIdeaRoadmaps({
            updatedIdeas: response.data.roadmap.ideas,
            roadmap: response.data.roadmap,
          })
        );

        toast.success("Ideas removed successfully");
      } else {
        console.error("Failed to remove ideas:", response.statusText);
      }
    } catch (error) {
      console.error("Error removing ideas:", error);
      toast.error("Error removing ideas");
    }
  };

export const deleteRoadmap = (roadmapId, navigate, releases, timeFrames) => {
  return async (dispatch) => {
    try {
      const response = await apiConnector("DELETE", DELETE_ROADMAP(roadmapId));

      if (response.status === 200 && response.data) {
        dispatch(removeRoadmap(roadmapId));
        if (releases?.length > 0) {
          dispatch(getReleaseRoadmaps(releases));
        }
        if (timeFrames?.length > 0) {
          dispatch(getTimeFrameRoadmaps(timeFrames));
        }
        toast.success("Roadmap deleted successfully");
        navigate("/roadmaps");
      }
    } catch (error) {
      toast.error(`Error deleting roadmap: ${error.message}`);
    }
  };
};

export const deleteRoadmaps = (roadmaps, releases, timeFrames) => {
  return async (dispatch) => {
    try {
      const response = await apiConnector("DELETE", DELETE_ROADMAPS(), {
        roadmaps,
      });

      if (response.status === 200 && response.data) {
        dispatch(removeRoadmaps({ roadmapIds: roadmaps }));
        if (releases?.length > 0) {
          dispatch(getReleaseRoadmaps(releases));
        }
        if (timeFrames?.length > 0) {
          dispatch(getTimeFrameRoadmaps(timeFrames));
        }
        toast.success("Roadmaps deleted successfully");
      }
    } catch (error) {
      toast.error(`Error deleting roadmaps: ${error.message}`);
    }
  };
};

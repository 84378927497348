/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import IconText from "components/common/IconText";
import ProfileIcon from "components/common/ProfileIcon";
import {
  setActiveIdeaId,
  setIdeaShowDetailViewDrawer,
} from "reduxStore/slices/ideaSlice";
import TagsSection from "components/common/detailView/detailViewRightSidebarSections/TagsSection";
import AccountsSection from "components/common/detailView/detailViewRightSidebarSections/AccountsSection";
import ProductSection from "components/common/detailView/detailViewRightSidebarSections/ProductSection";
import StatusSection from "components/common/detailView/detailViewRightSidebarSections/StatusSection";
import HTMLReactParser from "html-react-parser/lib/index";
import { fieldIconMapping } from "utils";
import Importance from "components/common/Importance";
import {
  updateIdeaData,
  updateIdeaTags,
  updateIdeaAccounts,
} from "reduxStore/operations/ideasAPI";
import AssigneeSection from "../../../../common/detailView/detailViewRightSidebarSections/AssigneeSection";

const IdeasModernListCard = ({ idea, onSelect, isSelected, statusArray }) => {
  const dispatch = useDispatch();
  const { products, tags } = useSelector((state) => state.company);
  const { accounts } = useSelector((state) => state.accounts);
  const { users } = useSelector((state) => state.users);
  const { selectedColumns, showDetailViewDrawer } = useSelector(
    (state) => state.ideas
  );

  const [isHovered, setIsHovered] = useState(false);

  const handleItemClick = () => {
    dispatch(setActiveIdeaId(idea._id));
    dispatch(setIdeaShowDetailViewDrawer(!showDetailViewDrawer));
  };

  const handleImportanceOptionClick = (option) => {
    dispatch(updateIdeaData(idea._id, { importance: option }));
  };

  return (
    <div
      className={`flex cursor-pointer rounded-lg hover:bg-row-background p-1 ${
        isSelected ? "bg-button-active_10" : "bg-white"
      }`}
      onClick={handleItemClick}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className="w-5/6 grow-0">
        <div className="flex flex-col">
          <div className="flex items-center gap-x-1">
            <div
              className={`${
                !isSelected ? (isHovered ? "visible" : "invisible") : "visible"
              }`}
            >
              <input
                type="checkbox"
                checked={isSelected}
                onChange={() => {
                  onSelect(idea);
                }}
                onClick={(e) => {
                  e.stopPropagation();
                }}
                className="cursor-pointer"
              />
            </div>
            <IconText
              icon={React.createElement(fieldIconMapping.idea)}
              text={idea?.title ?? ""}
              iconSize={14}
              textColor={"text-title font-[550]"}
              hoverable={false}
              px=""
              py=""
              iconColor="text-ideas-icon"
              align="items-center"
            />
          </div>
          <div className="break-words text-sm mx-11 line-clamp-2">
            {HTMLReactParser(idea?.description ?? "")}
          </div>
          {selectedColumns.includes("tags") && (
            <div className="flex flex-wrap items-center ml-11 mt-2.5 gap-x-2">
              <TagsSection
                item={idea}
                position=""
                with_title={false}
                with_icon_before_tags={true}
                tags={tags ?? []}
                handleTagOptionClick={(option) => {
                  dispatch(updateIdeaTags(option));
                }}
                py="py-2"
                dropdownPosition="top-full left-0"
              />
            </div>
          )}
          {selectedColumns.includes("accounts") && (
            <div className="flex flex-wrap items-center ml-11 gap-x-2">
              <AccountsSection
                item={idea}
                position=""
                with_title={false}
                with_icon_before_accounts={true}
                accounts={accounts ?? []}
                handleAccountOptionClick={(option) => {
                  dispatch(updateIdeaAccounts(option));
                }}
                py="py-2"
                dropdownPosition="top-full left-0"
              />
            </div>
          )}

          <div
            className={`flex items-center gap-x-3 ml-11 ${
              selectedColumns.includes("tags") ||
              selectedColumns.includes("accounts")
                ? ""
                : "mt-2.5"
            }`}
          >
            {selectedColumns.includes("importance") && (
              <div className="py-1">
                <Importance
                  value={idea?.importance ?? 0}
                  handleOptionClick={handleImportanceOptionClick}
                />
              </div>
            )}
            {selectedColumns.includes("score") && (
              <IconText
                icon={React.createElement(fieldIconMapping.score)}
                text={
                  idea?.score?.type === "Value/Effort"
                    ? idea?.score?.value_effort_score?.toFixed(1)
                    : idea?.score?.type === "RICE"
                    ? idea?.score?.rice_score?.toFixed(1)
                    : idea?.score?.type === "Impact/Effort" &&
                      idea?.score?.impact_effort_score?.toFixed(1)
                }
                hoverable={false}
                gap_x="gap-x-1"
                textSize="text-xs"
                iconColor="text-primary"
                px=""
                py=""
              />
            )}
            {selectedColumns.includes("insights") && (
              <IconText
                icon={React.createElement(fieldIconMapping.insights)}
                text={idea?.insights?.length?.toString() ?? "0"}
                iconColor="#FF8A00"
                gap_x="gap-x-1"
                hoverable={false}
              />
            )}
            {selectedColumns.includes("roadmap") && (
              <IconText
                icon={React.createElement(fieldIconMapping.roadmap)}
                text={idea?.roadmaps?.length?.toString() ?? "0"}
                iconColor="text-roadmaps-icon"
                gap_x="gap-x-1"
                hoverable={false}
              />
            )}
            {selectedColumns.includes("feature") && (
              <IconText
                icon={React.createElement(fieldIconMapping.feature)}
                text={idea?.features?.length?.toString() ?? "0"}
                iconColor="text-features-icon"
                gap_x="gap-x-1"
                hoverable={false}
              />
            )}
            {selectedColumns.includes("product") && (
              <ProductSection
                item={idea?.product ?? "No Product"}
                products={products ?? []}
                handleProductOptionClick={(option) => {
                  dispatch(updateIdeaData(idea._id, { product: option._id }));
                }}
                position=""
                with_title={false}
              />
            )}
            {selectedColumns.includes("assignee") && (
              <AssigneeSection
                item={idea}
                assignee={idea.assignee}
                itemLabel={idea?.assignee?.name ?? ""}
                assigneesArray={users}
                handleAssineeOptionClick={(option) => {
                  if (idea?.assignee?._id !== option._id) {
                    let { icon, ...restOption } = option;
                    dispatch(
                      updateIdeaData(
                        idea._id,
                        { assignee: option._id },
                        idea,
                        "assignee",
                        restOption
                      )
                    );
                  }
                }}
                with_title={false}
                with_text={false}
              />
            )}
          </div>
        </div>
      </div>
      {selectedColumns.includes("status") && (
        <div className="w-1/6 grow-0 mt-2">
          <StatusSection
            item={idea?.status ?? {}}
            statusArray={statusArray}
            handleStatusOptionClick={(option) => {
              let isUpdated = "status";
              let { icon, ...restOption } = option;
              dispatch(
                updateIdeaData(
                  idea._id,
                  { status: option._id },
                  idea,
                  isUpdated,
                  restOption
                )
              );
              // dispatch(updateIdeaData(idea._id, { status: option._id }))
            }}
            position=""
            with_title={false}
            dropdownMenuWidth="w-36"
          />
        </div>
      )}
    </div>
  );
};

export default IdeasModernListCard;

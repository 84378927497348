import React from "react"; // Add this import
import { toast } from "react-toastify";
import { objectiveEndPoints } from "api/api";
import { apiConnector } from "api/apiconnector";
import {
  appendObjective,
  removeObjective,
  setActiveObjectiveId,
  setObjectives,
  setObjectiveShowDetailViewDrawer,
  setIsObjectiveLoading,
  updateObjective,
  setObjectiveRoadmaps,
  removeObjectives,
  updateObjectives,
} from "../slices/objectiveSlice";
import { removeKeyResult } from "../slices/keyResultSlice";
import CustomToast from "components/common/CustomToast";
import { getTimeFrameRoadmaps } from "./timeFramesAPI";
import { updateFeatureObjective } from "../slices/featureSlice";

const {
  ADD_OBJECTIVE,
  GET_ALL_OBJECTIVES,
  UPDATE_OBJECTIVE,
  UPDATE_OBJECTIVES,
  DELETE_OBJECTIVE,
  UPDATE_OBJECTIVE_FEATURES,
  REMOVE_OBJECTIVE_FEATURES,
  UPDATE_OBJECTIVE_IDEAS,
  REMOVE_OBJECTIVE_IDEAS,
  ADD_OBJECTIVE_TAGS,
  UPDATE_OBJECTIVE_KEY_RESULTS,
  REMOVE_OBJECTIVE_KEY_RESULTS,
  DELETE_OBJECTIVES,
} = objectiveEndPoints;

export const createObjective = (title, timeFrame, type, status) => {
  return async (dispatch) => {
    try {
      const response = await apiConnector("POST", ADD_OBJECTIVE(), {
        title,
        timeFrame,
        type,
        status,
      });

      if (response.status === 201 && response.data) {
        dispatch(appendObjective(response.data.newObjective));
        toast(
          React.createElement(CustomToast, {
            linkAddress: "/objectives",
            title,
            module: "Objective",
            idModule: response.data.newObjective,
          })
        );
        // toast.success("Objective added successfully");
      } else {
        toast.error("Failed to add objective");
      }
    } catch (error) {
      console.error("Error adding objective:", error);
      toast.error("Error adding objective");
    }
  };
};

export const getAllObjectives = (page = 1, limit = 100) => {
  return async (dispatch) => {
    try {
      dispatch(setIsObjectiveLoading(true));
      const queryParams = new URLSearchParams({ page, limit }).toString();
      const response = await apiConnector(
        "GET",
        `${GET_ALL_OBJECTIVES()}?${queryParams}`
      );
      if (response.status === 200 && response.data) {
        dispatch(setObjectives(response.data.objectives));
      } else {
        toast.error("Failed to fetch objectives");
      }
    } catch (error) {
      console.error("Error fetching objectives:", error);
      toast.error("Error fetching objectives");
    }
  };
};

export const updateObjectiveData = (
  objectiveId,
  updatedData,
  isUpdated,
  restOption,
  objective
) => {
  return async (dispatch) => {
    let newObjective = {};
    if (objective && restOption) {
      // Creating a deep copy of the objective to avoid mutating the original object
      newObjective = JSON.parse(JSON.stringify(objective));
      switch (isUpdated) {
        case "status":
          newObjective.status = restOption;
          break;
        case "timeFrame":
          newObjective.timeFrame = restOption;
          break;
        case "health":
          newObjective.health = updatedData.health;
          break;
        case "assignee":
          newObjective.assignee = restOption;
          break;

        default:
          break;
      }
      // Dispatch an optimistic update to the UI
      dispatch(
        updateObjective({
          updatedObjective: newObjective,
          isUpdated: isUpdated,
        })
      );
    }

    try {
      // Make the API call to update the objective on the server
      const response = await apiConnector(
        "PUT",
        `${UPDATE_OBJECTIVE(objectiveId)}`,
        updatedData
      );

      // Handle server response
      if (response.status === 200 && response.data) {
        // Update state with the data from the server
        dispatch(
          updateObjective({
            updatedObjective: response.data.objective,
            isUpdated: isUpdated,
          })
        );

        if (isUpdated === "timeFrame") {
          if (updatedData.timeFrame === null) {
            dispatch(setObjectiveRoadmaps({ timeFrameIds: [], roadmaps: [] }));
          } else {
            dispatch(
              getTimeFrameRoadmaps([response.data.objective.timeFrame._id])
            );
          }
        }

        // toast.success("Objective updated successfully");
      } else {
        dispatch(
          updateObjective({
            updatedObjective: objective,
          })
        );
        toast.error("Failed to update objective on server");
      }
    } catch (error) {
      // Handle error case
      console.error("Error updating objectives:", error);
      // Revert to the original objective in case of server failure
      dispatch(
        updateObjective({
          updatedObjective: objective,
        })
      );
      toast.error("Error updating objectives");
    }
  };
};

export const updateObjectivesData = (objectives, update, isUpdatedArray) => {
  return async (dispatch) => {
    try {
      const response = await apiConnector("PUT", UPDATE_OBJECTIVES(), {
        objectives,
        update,
      });
      if (response.status === 200) {
        dispatch(
          updateObjectives({
            updatedObjectives: response.data.objectives,
            isUpdatedArray: isUpdatedArray,
          })
        );
      }
    } catch (error) {
      console.log("Error updating Objectives", error);
    }
  };
};

export const updateObjectiveFeatures = (objectiveId, features) => {
  return async (dispatch) => {
    try {
      const response = await apiConnector(
        "PUT",
        `${UPDATE_OBJECTIVE_FEATURES(objectiveId)}`,
        {
          features,
        }
      );
      if (response.status === 200 && response.data) {
        dispatch(
          updateObjective({
            updatedObjective: response.data.objective,
            isUpdated: "features",
          })
        );
        dispatch(
          updateFeatureObjective({
            updatedFeatureIds: response.data.objective.features,
            updatedObjectiveId: objectiveId,
          })
        );
        // toast.success("Objective updated successfully");
      } else {
        toast.error("Failed to update objectives");
      }
    } catch (error) {
      console.error("Error updating objectives:", error);
      toast.error("Error updating objectives");
    }
  };
};

export const removeObjectiveFeatures = (objectiveId, features) => {
  return async (dispatch) => {
    try {
      const response = await apiConnector(
        "DELETE",
        `${REMOVE_OBJECTIVE_FEATURES(objectiveId)}`,
        {
          features,
        }
      );
      if (response.status === 200 && response.data) {
        dispatch(
          updateObjective({
            updatedObjective: response.data.objective,
            isUpdated: "features",
          })
        );
        dispatch(
          updateFeatureObjective({
            updatedFeatureIds: response.data.objective.features,
            updatedObjectiveId: objectiveId,
          })
        );
        // toast.success("Objective updated successfully");
      } else {
        toast.error("Failed to update objectives");
      }
    } catch (error) {
      console.error("Error updating objectives:", error);
      toast.error("Error updating objectives");
    }
  };
};

export const updateObjectiveIdeas = (objectiveId, ideas) => {
  return async (dispatch) => {
    try {
      const response = await apiConnector(
        "PUT",
        `${UPDATE_OBJECTIVE_IDEAS(objectiveId)}`,
        {
          ideas,
        }
      );
      if (response.status === 200 && response.data) {
        dispatch(
          updateObjective({
            updatedObjective: response.data.objective,
            isUpdated: "ideas",
          })
        );
        toast.success("Objective updated successfully");
      } else {
        toast.error("Failed to update objectives");
      }
    } catch (error) {
      console.error("Error updating objectives:", error);
      toast.error("Error updating objectives");
    }
  };
};

export const removeObjectiveIdeas = (objectiveId, ideas) => {
  return async (dispatch) => {
    try {
      const response = await apiConnector(
        "DELETE",
        `${REMOVE_OBJECTIVE_IDEAS(objectiveId)}`,
        {
          ideas,
        }
      );
      if (response.status === 200 && response.data) {
        dispatch(
          updateObjective({
            updatedObjective: response.data.objective,
            isUpdated: "ideas",
          })
        );
        toast.success("Objective updated successfully");
      } else {
        toast.error("Failed to update objectives");
      }
    } catch (error) {
      console.error("Error updating objectives:", error);
      toast.error("Error updating objectives");
    }
  };
};

export const addObjectiveKeyResults = (objectiveId, keyResults) => {
  return async (dispatch) => {
    try {
      const response = await apiConnector(
        "PUT",
        `${UPDATE_OBJECTIVE_KEY_RESULTS(objectiveId)}`,
        {
          keyResults,
        }
      );
      if (response.status === 200 && response.data) {
        dispatch(
          updateObjective({
            updatedObjective: response.data.objective,
            isUpdated: "keyResults",
          })
        );
      } else {
        toast.error("Failed to update objectives");
      }
    } catch (error) {
      console.error("Error updating objectives:", error);
      toast.error("Error updating objectives");
    }
  };
};

export const removeObjectiveKeyResults = (objectiveId, keyResults) => {
  return async (dispatch) => {
    try {
      const response = await apiConnector(
        "DELETE",
        `${REMOVE_OBJECTIVE_KEY_RESULTS(objectiveId)}`,
        {
          keyResults,
        }
      );
      if (response.status === 200 && response.data) {
        dispatch(
          updateObjective({
            updatedObjective: response.data.objective,
            isUpdated: "keyResults",
          })
        );
        dispatch(removeKeyResult(keyResults[0]));
        toast.success("Objective updated successfully");
      } else {
        toast.error("Failed to update objectives");
      }
    } catch (error) {
      console.error("Error updating objectives:", error);
      toast.error("Error updating objectives");
    }
  };
};

export const updateObjectiveTags = (objective, objectiveId, tags) => {
  return async (dispatch) => {
    let newObjective = {};
    if (objective) {
      newObjective = JSON.parse(JSON.stringify(objective));
      newObjective.tags = tags;
      // Dispatch an optimistic update to the UI
      dispatch(
        updateObjective({
          updatedObjective: newObjective,
          isUpdated: "tags",
        })
      );
    }
    try {
      const response = await apiConnector(
        "PUT",
        ADD_OBJECTIVE_TAGS(objectiveId),
        {
          tags,
        }
      );
      if (response.status === 200 && response.data) {
        dispatch(
          updateObjective({
            updatedObjective: response.data.objective,
            isUpdated: "tags",
          })
        );
        // toast.success("Objective updated successfully");
      } else {
        dispatch(
          updateObjective({
            updatedObjective: objective,
            isUpdated: "tags",
          })
        );
        toast.error("Failed to update objectives");
      }
    } catch (error) {
      console.error("Error updating objective tags:", error);
      toast.error("Error updating objective tags");
      dispatch(
        updateObjective({
          updatedObjective: objective,
          isUpdated: "tags",
        })
      );
    }
  };
};

export const deleteObjective = (objectiveId, navigate) => {
  return async (dispatch) => {
    try {
      const response = await apiConnector(
        "DELETE",
        DELETE_OBJECTIVE(objectiveId)
      );

      if (response.status === 200 && response.data) {
        dispatch(setActiveObjectiveId(null));
        dispatch(setObjectiveShowDetailViewDrawer(false));
        dispatch(removeObjective(objectiveId));
        toast.success("Objective deleted successfully");
        navigate("/objectives");
      }
    } catch (error) {
      toast.error(`Error deleting objective: ${error.message}`);
    }
  };
};

export const deleteObjectives = (objectives) => {
  return async (dispatch) => {
    try {
      const response = await apiConnector("DELETE", DELETE_OBJECTIVES(), {
        objectives,
      });

      if (response.status === 200 && response.data) {
        dispatch(removeObjectives({ objectiveIds: objectives }));
        toast.success("Objectives deleted successfully");
      }
    } catch (error) {
      toast.error(`Error deleting objectives: ${error.message}`);
    }
  };
};

/* eslint-disable react/prop-types */
import React, { useEffect, useMemo, useState } from "react";
import DemoProfileImage from "assets/demo_profile_image.webp";
import { useSelector } from "react-redux";
import { generateStatusArray } from "utils/status";
import TypeSection from "../detailView/detailViewRightSidebarSections/TypeSection";
import TimeFrameSection from "../detailView/detailViewRightSidebarSections/TimeFrameSection";
import StatusSection from "../detailView/detailViewRightSidebarSections/StatusSection";
import { fieldIconMapping } from "utils";
import { objectiveTypes } from "utils/objectiveUtils";

const AddObjectivePopupOverlay = ({ option, name, setIsWarn }) => {
  console.log(option);
  const [status, setStatus] = useState(null);
  const [timeFrame, setTimeFrame] = useState(null);

  const [type, setType] = useState(objectiveTypes()[0]);
  const { statuses, timeFrames } = useSelector((state) => state.company);
  const { users } = useSelector((state) => state.users);

  const handleStatusOptionClick = (option) => {
    setStatus(option);
  };
  const handleTimeFrameOptionClick = (option) => {
    setTimeFrame(option);
  };
  const handleTypeOptionClick = (option) => {
    setType(option);
  };




  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === "Enter") {
        handleAddObjective()
      }
    };

    window.addEventListener("keydown", handleKeyPress);

    // Cleanup function to remove the event listener
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [name]);


  const handleAddObjective = () => {
    if (name === "") {
      setIsWarn(true);
      setTimeout(() => {
        setIsWarn(false);
      }, 3000);
      return;
    }
    option.onAddClick({
      title: name,
      timeFrame: timeFrame?._id ?? null,
      status: status?._id ?? null,
      type: type._id,
    });
  };

  const [objectiveStatuses, setObjectiveStatuses] = useState([]);
  useEffect(() => {
    setObjectiveStatuses(statuses.objective);
  }, [statuses]);

  const statusArray = useMemo(
    () => generateStatusArray(objectiveStatuses),
    [objectiveStatuses]
  );

  useEffect(() => {
    setStatus(statusArray[0]);
  }, [statusArray]);

  useEffect(() => {
    setTimeFrame(timeFrames[0]);
  }, [timeFrames]);

  return (
    <div className="flex flex-col gap-y-2">
      {option.addOptions.includes("type") && (
        <div className="flex items-center gap-x-1.5 mb-2">
          <div className="text-primary">Type</div>
          <TypeSection
            itemLabel={type.label}
            types={objectiveTypes() ?? []}
            handleTypeOptionClick={(option) => {
              handleTypeOptionClick(option);
            }}
            position=""
            with_title={false}
          />
        </div>
      )}
      <div className="flex items-center gap-x-2 -ml-1">
        {option.addOptions.includes("status") && (
          <StatusSection
            item={status ?? {}}
            statusArray={statusArray}
            handleStatusOptionClick={(option) =>
              handleStatusOptionClick(option)
            }
            position=""
            with_title={false}
          />
        )}

        {option.addOptions.includes("timeFrame") && (
          <TimeFrameSection
            item={{
              ...timeFrame,
              icon: React.createElement(fieldIconMapping.timeFrames),
            }}
            timeFrames={timeFrames}
            handleTimeFrameOptionClick={(option) => {
              handleTimeFrameOptionClick(option);
            }}
            position=""
            with_title={false}
            for_add={true}
          />
        )}
        {/* {option.addOptions.includes("assignee") && (
        <AssigneeSection
        item={option}
        isOwner={true}
        assignee={item.assignee[0]}
        itemLabel={"Owner" ?? ""}
        assigneesArray={users}
        handleAssineeOptionClick={(option) => {
          console.log(option);
          if (item?.assignee?._id !== option._id) {
            let { icon, ...restOption } = option;
            dispatch(
              updateObjectiveData(
                item._id,
                { assignee: option._id },
                "assignee",
                restOption,
                item
              )
            );
          }
        }}
      />
        )} */}
      </div>
      <hr />
      <button
        className="p-2 bg-add-popup-button rounded text-white w-fit text-sm ml-auto"
        onClick={handleAddObjective}
      >
        Add {option.label}
      </button>
    </div>
  );
};

export default AddObjectivePopupOverlay;

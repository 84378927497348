/* eslint-disable react/prop-types */
import React from "react";
import ProfileIcon from "components/common/ProfileIcon";
import { formatDateWithTime } from "utils/timeUtils";
import { capitalizeFirstLetter } from "utils";

const ActivityLogSection = ({ log }) => {
  return (
    <>
      <ProfileIcon name={log?.user?.name ?? ""} />
      <div>
        <div className="text-sm text-title_50">
          {log.field === "health"
            ? "Health"
            : log.fieldRef ?? capitalizeFirstLetter(log.field)}{" "}
          updated to{" "}
          {log.field === "importance"
            ? capitalizeFirstLetter(log.updatedText)
            : log.field === "health"
            ? capitalizeFirstLetter(log.updatedText)
            : log.field === "assignee"
            ? capitalizeFirstLetter(log.updatedText)
            : log.field === "source"
            ? capitalizeFirstLetter(log.updatedText)
            : log.field === "type"
            ? capitalizeFirstLetter(log.updatedText)
            : log.updatedValue?.label ?? ""}
        </div>
        <div className="text-xs text-primary">
          {formatDateWithTime(log.updatedAt)}
        </div>
      </div>
    </>
  );
};

export default ActivityLogSection;

import React, { useRef } from "react";
import { BubbleMenu } from "@tiptap/react";
import * as Dropdown from "@radix-ui/react-dropdown-menu";
import {
  // BiBold,
  // BiItalic,
  BiUnderline,
  // BiLink,
  BiCode,
  BiListUl,
  BiListOl,
  // BiAlignLeft,
  // BiAlignMiddle,
  // BiAlignRight,
  BiDotsVerticalRounded,
  BiHighlight,
  BiImage,
} from "react-icons/bi";
import { MdOutlineLocalParking } from "react-icons/md";
import { LuHeading1, LuHeading3, LuHeading2 } from "react-icons/lu";
import { toast } from "react-toastify";
import axios from "axios";
import { PiParagraphThin } from "react-icons/pi";
import { VscBold } from "react-icons/vsc";
import { GoItalic } from "react-icons/go";
import { BsBlockquoteLeft } from "react-icons/bs";

const BubbleMenuComponent = ({ editor }) => {
  const fileInputRef = useRef(null);

  if (!editor) return null;

  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await axios.post(
        `${import.meta.env.VITE_BACKEND_URL + "/api/storages"}`,
        formData,
        {
          withCredentials: true,
          headers: { "Content-Type": "multipart/form-data" },
        }
      );

      const imageUrl = response.data.url;

      editor.chain().focus().setImage({ src: imageUrl }).run();
    } catch (error) {
      console.error("Image upload failed:", error);
      toast.error(
        error?.response?.data?.message ??
          "Failed to upload image. Please try again."
      );
    }
  };

  return (
    <BubbleMenu
      editor={editor}
      className="flex items-center w-fit space-x-2 bg-white border border-gray-300 rounded-md shadow-md p-1"
    >
      {/* Basic Formatting */}
      <button
        onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
        className={`p-1 rounded-md hover:bg-gray-200 ${
          editor.isActive("heading", { level: 1 }) ? "bg-gray-300" : ""
        }`}
      >
        <LuHeading1 />
      </button>
      <button
        onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
        className={`p-1 rounded-md hover:bg-gray-200 ${
          editor.isActive("heading", { level: 2 }) ? "bg-gray-300" : ""
        }`}
      >
        <LuHeading2 />
      </button>
      <button
        onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()}
        className={`p-1 rounded-md hover:bg-gray-200 ${
          editor.isActive("heading", { level: 3 }) ? "bg-gray-300" : ""
        }`}
      >
        <LuHeading3 />
      </button>
      <button
        onClick={() => editor.chain().focus().setParagraph().run()}
        className={`p-1 rounded-md hover:bg-gray-200 ${
          editor.isActive("paragraph") ? "bg-gray-300" : ""
        }`}
      >
        <PiParagraphThin />
      </button>
      <span class="text-gray-400">|</span>
      <button
        onClick={() => editor.chain().focus().toggleBold().run()}
        className={`p-1 rounded-md hover:bg-gray-200 ${
          editor.isActive("bold") ? "bg-gray-300" : ""
        }`}
      >
        <VscBold />
      </button>
      <button
        onClick={() => editor.chain().focus().toggleItalic().run()}
        className={`p-1 rounded-md hover:bg-gray-200 ${
          editor.isActive("italic") ? "bg-gray-300" : ""
        }`}
      >
        <GoItalic />
      </button>
      <button
        onClick={() => editor.chain().focus().toggleUnderline().run()}
        className={`p-1 rounded-md hover:bg-gray-200 ${
          editor.isActive("underline") ? "bg-gray-300" : ""
        }`}
      >
        <BiUnderline />
      </button>
      <button
        onClick={() => editor.chain().focus().setHighlight().run()}
        className={`p-1 rounded-md hover:bg-gray-200 ${
          editor.isActive("highlight") ? "bg-gray-300" : ""
        }`}
      >
        <BiHighlight />
      </button>

      <span class="text-gray-400">|</span>
      {/* List Tools */}
      <button
        onClick={() => editor.chain().focus().toggleBulletList().run()}
        className={`p-1 rounded-md hover:bg-gray-200 ${
          editor.isActive("bulletList") ? "bg-gray-300" : ""
        }`}
      >
        <BiListUl />
      </button>
      <button
        onClick={() => editor.chain().focus().toggleOrderedList().run()}
        className={`p-1 rounded-md hover:bg-gray-200 ${
          editor.isActive("orderedList") ? "bg-gray-300" : ""
        }`}
      >
        <BiListOl />
      </button>

      <button
        onClick={() => editor.chain().focus().toggleCode().run()}
        className={`p-1 rounded-md hover:bg-gray-200 ${
          editor.isActive("code") ? "bg-gray-300" : ""
        }`}
      >
        <BiCode />
      </button>

      <button
        onClick={() => editor.chain().focus().toggleBlockquote().run()}
        className={`p-1 rounded-md hover:bg-gray-200 ${
          editor.isActive("blockquote") ? "bg-gray-300" : ""
        }`}
      >
        <BsBlockquoteLeft />
      </button>
      <span class="text-gray-400">|</span>
      {/* Add image */}
      <button
        onClick={() => fileInputRef.current.click()} // Trigger file input
        className={`p-1 rounded-md hover:bg-gray-200 ${
          editor.isActive("image", { level: 1 }) ? "bg-gray-300" : ""
        }`}
      >
        <BiImage />
      </button>
      <input
        type="file"
        accept="image/*"
        ref={fileInputRef}
        className="hidden" // Hide the input
        onChange={handleImageUpload}
      />
      {/* <span class="text-gray-400">|</span> */}
      {/* Alignment */}
      {/* <button
        onClick={() => editor.chain().focus().setTextAlign("left").run()}
        className={`p-1 rounded-md hover:bg-gray-200 ${
          editor.isActive({ textAlign: "left" }) ? "bg-gray-300" : ""
        }`}
      >
        <BiAlignLeft />
      </button>
      <button
        onClick={() => editor.chain().focus().setTextAlign("center").run()}
        className={`p-1 rounded-md hover:bg-gray-200 ${
          editor.isActive({ textAlign: "center" }) ? "bg-gray-300" : ""
        }`}
      >
        <BiAlignMiddle />
      </button>
      <button
        onClick={() => editor.chain().focus().setTextAlign("right").run()}
        className={`p-1 rounded-md hover:bg-gray-200 ${
          editor.isActive({ textAlign: "right" }) ? "bg-gray-300" : ""
        }`}
      >
        <BiAlignRight />
      </button> */}
      {/* Dropdown Menu for Advanced Tools */}
      {/* <Dropdown.Root>
        <Dropdown.Trigger asChild>
          <button className="p-1 rounded-md hover:bg-gray-200">
            <BiDotsVerticalRounded />
          </button>
        </Dropdown.Trigger>
        <Dropdown.Content
          align="end"
          className="bg-white border border-gray-300 rounded-md shadow-md p-1 space-y-1"
        >
          <Dropdown.Item
            onSelect={() => editor.chain().focus().setBlockquote().run()}
            className="p-1 text-sm hover:bg-gray-100 rounded-md cursor-pointer"
          >
            Blockquote
          </Dropdown.Item>
          <Dropdown.Separator className="h-px bg-gray-200 my-1" />
          <Dropdown.Item
            onSelect={() => editor.chain().focus().unsetAllMarks().run()}
            className="p-1 text-sm text-red-500 hover:bg-red-50 rounded-md cursor-pointer"
          >
            Clear Formatting
          </Dropdown.Item>
        </Dropdown.Content>
      </Dropdown.Root> */}
    </BubbleMenu>
  );
};

export default BubbleMenuComponent;

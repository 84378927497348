import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import PersonalView from "../PersonalView";
import { useDispatch, useSelector } from "react-redux";
import { reorderPersonalViews } from "../../../reduxStore/slices/personalViewSlice";
import { apiConnector } from "api/apiconnector";
import { userEndPoints } from "../../../api/api";
import { useState } from "react";

const { REORDER_PERSONAL_VIEWS } = userEndPoints;

const PersonalViewsList = () => {
  const dispatch = useDispatch();
  const { personalViews } = useSelector((state) => state.personalViews);
  const [isLoading, setIsLoading] = useState(false); // State to manage loading

  // Function to handle drag and drop
  const onDragEnd = async (result) => {
    const { source, destination } = result;

    // If dropped outside the list or no destination, do nothing
    if (!destination) return;

    // Create a local copy of personalViews to reorder
    const updatedViews = [...personalViews];
    const [movedItem] = updatedViews.splice(source.index, 1); // Remove item from source index
    updatedViews.splice(destination.index, 0, movedItem); // Insert item at destination index

    // Extract reordered IDs
    const reorderedIds = updatedViews.map((view) => view._id);

    // Dispatch the action to update Redux
    dispatch(
      reorderPersonalViews({
        sourceIndex: source.index,
        destinationIndex: destination.index,
      })
    );

    // Make the API call to persist the new order
    setIsLoading(true); // Set loading state to true
    try {
      const response = await apiConnector("POST", REORDER_PERSONAL_VIEWS, {
        reorderedIds,
      });

      if (response.status === 200) {
        // console.log("Order updated successfully!");
        // Optionally, show a toast notification
        // toast.success("Order updated successfully!");
      } else {
        console.error("Failed to update order:", response.statusText);
        // toast.error("Failed to update order");
      }
    } catch (error) {
      console.error("Error updating order:", error);
      // toast.error("An error occurred while updating the order");
    } finally {
      setIsLoading(false); // Reset loading state
    }
  };

  return (
    <DragDropContext
      onDragEnd={isLoading ? () => {} : onDragEnd} // Disable drag-and-drop while loading
    >
      <Droppable droppableId="personalViews">
        {(provided) => (
          <div
            className="flex flex-col gap-y-1"
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            {(personalViews ?? []).map((view, index) => (
              <Draggable
                key={view._id}
                draggableId={view._id}
                index={index}
                isDragDisabled={isLoading} // Disable individual items while loading
              >
                {(provided) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  >
                    <PersonalView view={view} py="py-1" />
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
      {/* {isLoading && (
        <div className="mt-4 text-center text-blue-500">
          Loading, please wait...
        </div>
      )} */}
    </DragDropContext>
  );
};

export default PersonalViewsList;

/* eslint-disable react/prop-types */
import { NameInitialsAvatar } from "react-name-initials-avatar";

const ProfileIcon = ({ name,size="25px" }) => {
  return (
    <NameInitialsAvatar
      name={name??"Assignee"}
      size={size}
      textSize="10px"
      borderWidth="1px"
      bgColor="#0A7Aff1A"
    />
  );
};

export default ProfileIcon;

import React from "react";
import { useSelector } from "react-redux";

const Default = () => {
  const { users } = useSelector((state) => state.users);
  return (
    <div>
      {" "}
      {users && users.length > 0 ? (
        <div className="w-[60%]">
          <table className="min-w-full table-auto">
            <thead>
              <tr className="text-sm">
                <th className="text-primary text-start pr-1 px-3 font-normal">
                  <span className="flex items-center gap-x-1">Name</span>
                </th>
                <th className="text-primary text-start pr-1 px-3 font-normal">
                  Email
                </th>
              </tr>
            </thead>
            <tbody>
              {users.map((user, index) => (
                <tr
                  key={index}
                  className="text-gray-700 text-[14px] rounded relative"
                >
                  <td className="truncate pr-1 px-3 py-2">{user.name}</td>
                  <td className="truncate pr-1 px-3 py-2">{user.email}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="flex justify-center items-center h-[80vh] ">
          <p className=" text-lg text-gray-500 ">There are no users.</p>
        </div>
      )}
    </div>
  );
};

export default Default;

import { Image } from "@tiptap/extension-image";
import { mergeAttributes } from "@tiptap/core";

const ResizableImage = Image.extend({
  name: "resizableImage",

  addAttributes() {
    return {
      ...this.parent?.(),
      width: {
        default: "300px",
      },
      height: {
        default: "auto",
      },
    };
  },

  renderHTML({ HTMLAttributes }) {
    return [
      "img",
      mergeAttributes(HTMLAttributes, {
        style: `width: ${HTMLAttributes.width}; height: ${HTMLAttributes.height};`,
      }),
    ];
  },

addNodeView() {
    return ({ node, getPos, editor }) => {
      const container = document.createElement("div");
      container.style.position = "relative";
      container.style.display = "inline-block";
      container.style.textAlign = node.attrs.alignment;

      const img = document.createElement("img");
      img.setAttribute("src", node.attrs.src);
      img.setAttribute("alt", node.attrs.alt || "");
      img.setAttribute("title", node.attrs.title || "");
      img.style.width = node.attrs.width;
      img.style.height = node.attrs.height;
      img.style.cursor = "pointer";

      // Overlay container for resize handles
      const overlay = document.createElement("div");
      overlay.style.position = "absolute";
      overlay.style.top = "0";
      overlay.style.left = "0";
      overlay.style.width = "100%";
      overlay.style.height = "100%";
      overlay.style.display = "none";
      overlay.style.pointerEvents = "none";
      overlay.style.border = "2px dashed rgba(0, 0, 0, 0.2)";
      overlay.style.borderRadius = "4px";

      const updateImageSize = () => {
        editor.commands.updateAttributes("resizableImage", {
          width: img.style.width,
          height: img.style.height,
        });
      };

      // Create resize handles
      const createHandle = (position, cursor) => {
        const handle = document.createElement("div");
        handle.style.width = "10px";
        handle.style.height = "10px";
        handle.style.backgroundColor = "#007BFF";
        handle.style.position = "absolute";
        handle.style.pointerEvents = "all";
        handle.style.cursor = cursor;
        handle.style.borderRadius = "50%";

        const [vertical, horizontal] = position.split("-");
        handle.style[vertical] = "-5px";
        handle.style[horizontal] = "-5px";

        handle.addEventListener("mousedown", (event) => {
          event.preventDefault();

          const startX = event.clientX;
          const startY = event.clientY;
          const startWidth = img.offsetWidth;
          const startHeight = img.offsetHeight;

          const onMouseMove = (e) => {
            const newWidth =
              startWidth +
              (horizontal === "right" ? e.clientX - startX : startX - e.clientX);
            const newHeight =
              startHeight +
              (vertical === "bottom" ? e.clientY - startY : startY - e.clientY);

            img.style.width = `${Math.max(newWidth, 100)}px`; // Minimum width
            img.style.height = `${Math.max(newHeight, 100)}px`; // Minimum height
          };

          const onMouseUp = () => {
            document.removeEventListener("mousemove", onMouseMove);
            document.removeEventListener("mouseup", onMouseUp);
            updateImageSize(); // Update size in editor
          };

          document.addEventListener("mousemove", onMouseMove);
          document.addEventListener("mouseup", onMouseUp);
        });

        return handle;
      };

      const positions = [
        { position: "top-left", cursor: "nwse-resize" },
        { position: "top-right", cursor: "nesw-resize" },
        { position: "bottom-left", cursor: "nesw-resize" },
        { position: "bottom-right", cursor: "nwse-resize" },
      ];

      positions.forEach(({ position, cursor }) => {
        const handle = createHandle(position, cursor);
        overlay.appendChild(handle);
      });

      container.appendChild(img);
      container.appendChild(overlay);

      // Toggle overlay visibility on click
      img.addEventListener("click", (event) => {
        event.stopPropagation();
        const isVisible = overlay.style.display === "block";
        overlay.style.display = isVisible ? "none" : "block";
      });

      // Hide overlay when clicking outside
      document.addEventListener("click", () => {
        overlay.style.display = "none";
      });

      return {
        dom: container,
        update: (updatedNode) => {
          if (updatedNode.type.name !== "resizableImage") return false;

          img.setAttribute("src", updatedNode.attrs.src);
          img.style.width = updatedNode.attrs.width;
          img.style.height = updatedNode.attrs.height;
          container.style.textAlign = updatedNode.attrs.alignment;
          return true;
        },
      };
    };
  },
});

export default ResizableImage;


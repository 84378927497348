/* eslint-disable react/prop-types */
import React, { useState, useEffect, useRef } from "react";
import IconText from "components/common/IconText";
import { capitalizeFirstLetter, fieldIconMapping } from "utils";
import DropdownMenu from "../dropdown/DropdownMenu";
import { objectiveHealths, getHealthBgColor } from "utils/objectiveUtils";

const HealthSection = ({
  itemLabel,
  handleHealthOptionClick,
  position = "gap-x-6",
  with_title = true,
  with_dropdown = true,
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef();

  const handleLocalHealthOptionClick = (option) => {
    handleHealthOptionClick(option);
    setIsDropdownOpen(false);
  };

  const toggleDropdown = (e) => {
    e.stopPropagation();
    setIsDropdownOpen(!isDropdownOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className={`flex ${position} items-center text-title_50`}>
      {with_title && (
        <IconText
          icon={React.createElement(fieldIconMapping.health)}
          text="Health"
          iconColor="#696969"
          iconSize={16}
          hoverable={false}
          gap_x="gap-x-2"
        />
      )}
      <div className="relative" ref={dropdownRef}>
        <div
          className={`w-fit rounded  ${
            isDropdownOpen && "bg-button-active_10"
          }`}
          onClick={toggleDropdown}
        >
          <IconText
            icon={React.createElement(fieldIconMapping.timeFrames)}
            text={capitalizeFirstLetter(itemLabel)}
            gap_x="gap-x-1.5"
            px="px-1"
            py="py-1"
            textSize="text-xs"
            textColor="text-title_50"
            iconColor={getHealthBgColor(itemLabel)}
          />
        </div>
        {with_dropdown && isDropdownOpen && (
          <DropdownMenu
            options={objectiveHealths() ?? []}
            isOpen={isDropdownOpen}
            position="top-full left-0 min-w-28"
            handleOptionClick={handleLocalHealthOptionClick}
            item={itemLabel}
            type="health"
            byDetail={true}
          />
        )}
      </div>
    </div>
  );
};

export default HealthSection;

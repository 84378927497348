import { setUser } from "../slices/userSlice";
import { apiConnector } from "api/apiconnector";
import { userEndPoints } from "api/api";
import { toast } from "react-toastify";
import { setLoading } from "../slices/authSlice";
import { setUsers } from "../slices/UsersFromCompanySlice";
import { setInvitedUsers } from "../slices/invitedUsers";
const {
  GET_USER_DETAILS,
  UPDATE_USER_DETAILS,
  INVITE_PROCESS,
  GET_ALL_USERS,
  INVITED_USERS,
} = userEndPoints;

export const fetchUserDetails = (userId) => {
  return async (dispatch) => {
    try {
      const response = await apiConnector(
        `GET`,
        `${GET_USER_DETAILS}/${userId}`
      );

      if (response.status === 200) {
        dispatch(setUser(response.data.user));
      } else {
        console.error("Failed to fetch user details:", response.statusText);
        toast.error("Failed to fetch user details");
      }
    } catch (error) {
      console.error("Error fetching user details:", error);
      toast.error("An error occurred while fetching user details");
    }
  };
};

export const updateUserProfile = (user) => async (dispatch) => {
  try {
    const response = await apiConnector("PUT", UPDATE_USER_DETAILS, user);

    if (response.status === 200) {
      dispatch(setUser(response.data.user));

      // toast.success("Profile updated successfully");
    } else {
      console.error("Failed to update profile:", response.statusText);
      toast.error("Failed to update profile");
    }
  } catch (error) {
    console.error("Error updating profile:", error);
    toast.error("An error occurred while updating the profile");
  }
};

export const inviteProcess = (name, password, token, navigate) => {
  return async (dispatch) => {
    try {
      const { data } = await apiConnector(`POST`, INVITE_PROCESS, {
        name,
        password,
        token,
      });
      // toast.success(
      //   data?.message ?? "User created successfully. You can now log in."
      // );
      dispatch(setLoading(false));
      navigate("/");
    } catch (error) {
      console.log(error);
      dispatch(setLoading(false));
      toast.error(
        error?.response?.data?.message ??
          "An error occurred while processing the invitation link. Please try again later."
      );
    }
  };
};

// export const fetchUsersByCompanyId = (companyId) => {
//   return async (dispatch) => {
//     try {
//       // Make API call
//       const response = await apiConnector(`GET`, `${GET_ALL_USERS}/${companyId}`);

//       if (response.status === 200) {
//         // Dispatch directly using the data if it's already parsed
//         console.log(response.data.users)
//         dispatch(setUsers(response.data.users));
//       } else {
//         console.error("Failed to fetch users by company ID:", response.statusText);
//         // toast.error("Failed to fetch users");
//       }
//     } catch (error) {
//       // Handle errors
//       console.error("Error fetching users by company ID:", error);
//       // toast.error("An error occurred while fetching users");
//     }
//   };
// };

export const fetchUsersByCompanyId = (companyId) => {
  return async (dispatch) => {
    try {
      // Make API call
      const response = await apiConnector(
        `GET`,
        `${GET_ALL_USERS}/${companyId}`
      );

      if (response.status === 200) {
        // Extract only the _doc data from each user
        const usersWithDocs = response.data.users.map((user) => user._doc);
        // Dispatch the cleaned-up users list to the Redux store
        dispatch(setUsers(usersWithDocs));
      } else {
        console.error(
          "Failed to fetch users by company ID:",
          response.statusText
        );
        // toast.error("Failed to fetch users");
      }
    } catch (error) {
      // Handle errors
      console.error("Error fetching users by company ID:", error);
      // toast.error("An error occurred while fetching users");
    }
  };
};

export const fetchInvitedUsers = () => {
  return async (dispatch) => {
    try {
      // Make API call
      const response = await apiConnector(`GET`, `${INVITED_USERS}`);

      if (response.status === 200) {
        dispatch(setInvitedUsers(response.data.invitationUsers));
      } else {
        console.error(
          "Failed to fetch users by company ID:",
          response.statusText
        );
        // toast.error("Failed to fetch users");
      }
    } catch (error) {
      // Handle errors
      console.error("Error fetching users by company ID:", error);
      // toast.error("An error occurred while fetching users");
    }
  };
};

import { useState } from "react";
import { toSnakeCase } from "utils";

const useFilterLogic = (topbarFunctions) => {
  const [selectedFilterOption, setSelectedFilterOption] = useState(null);
  const [subFilterDropdownOptions, setSubFilterDropdownOptions] =
    useState(null);

  const handleFilterOptionClick = (option) => {

    if (selectedFilterOption === option) {
      setSubFilterDropdownOptions(null);
      setSelectedFilterOption(null);
    } else {
      setSubFilterDropdownOptions(option);
      setSelectedFilterOption(option);
    }
  };

  const handleSubFilterOptionClick = (subOption) => {

  
    const filterKey = toSnakeCase(subFilterDropdownOptions.label);
    if (subOption?._id === undefined) {
      topbarFunctions.updateFilter({
        key: filterKey,
        value: { label: subOption.label || subOption.name },
      });
    } else {
      topbarFunctions.updateFilter({
        key: filterKey,
        value: { _id: subOption._id, label: subOption.label || subOption.name},
      });
    }
  };

  const handleResetFilterClick = () => {
    topbarFunctions.clearFilters();
  };

  const handleRemoveFilter = (key, value) => {
    topbarFunctions.updateFilter({ key: toSnakeCase(key), value });
  };

  return {
    selectedFilterOption,
    subFilterDropdownOptions,
    setSelectedFilterOption,
    setSubFilterDropdownOptions,
    handleFilterOptionClick,
    handleSubFilterOptionClick,
    handleResetFilterClick,
    handleRemoveFilter,
  };
};

export default useFilterLogic;

/* eslint-disable react/prop-types */
import React from "react";
import IconText from "./IconText";
import { rgbColor } from "utils";
import Importance from "./Importance";
import { fieldIconMapping, truncateText } from "../../utils";
import { AngleSmallDown, AngleSmallUp } from "react-flaticons";
import ProfileIcon from "./ProfileIcon";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

const DropdownItem = ({
  option,
  hoverColor = "hover:bg-topbar-button-hover",
  handleOptionClick = () => {},
  py = "py-2",
  px = "px-2",
  textSize = "text-sm",
  iconSize = 16,
  selectedSubOptionLength = 0,
  textBgColor = "",
  textColor = "text-primary",
  dropDownBgColor = "",
  iconColor = "",
  gap_x = "gap-x-3",
  rightIcon = null,
  activeBg = "",
  isAssignee,
  truncateLength = 14,
  textbreak = false,
}) => {
  const { roadmapIdViews, roadmaps } = useSelector((state) => state.roadmaps);

  const iRoadmapStrategy = roadmaps.some(
    (roadmap) =>
      roadmap._id === roadmapIdViews[0]?.roadmapId && roadmap.for === "Strategy"
  );

  const { pathname } = useLocation();
  const isOwner =
    (pathname.includes("objectives") && option.label === "Assignee") ||
    (iRoadmapStrategy && option.label === "Assignee");
  return (
    <div
      className={`flex items-center justify-between   overflow-hidden     ${activeBg}    ${
        dropDownBgColor && "p-1 -mx-1 -my-0.5 "
      } ${hoverColor} rounded cursor-pointer`}
      onClick={() => handleOptionClick(option)}
    >
      {["1", "2", "3", "4", "5"].some((num) => option.label === num) ? (
        <div className=" flex items-center  pt-1.5 pb-1.5  ">
          <IconText
            icon={React.createElement(fieldIconMapping.importance)}
            text={""}
            isASidebarComponent={false}
            hoverable={false}
            iconSize={iconSize}
            isTextActive={true}
            py="py-1"
            px="pl-1.5"
          />
          {/* { React.createElement(fieldIconMapping.importance)} */}
          <Importance value={option.label} readOnly={true} />
        </div>
      ) : option.icon ? (
        <div className={dropDownBgColor && "bg-white w-fit rounded"}>
          <IconText
            icon={
              React.isValidElement(option.icon)
                ? option.icon
                : React.createElement(option.icon)
            }
            iconTextBgColor={
              dropDownBgColor ? rgbColor(dropDownBgColor, 0.2) : null
            }
            text={
              option.label === "For"
                ? "Type"
                : option.label === "submittedBy" ||
                  option.label === "SubmittedBy"
                ? "Creator"
                : isOwner
                ? "Owner"
                : option.label
            }
            truncateLength={truncateLength}
            isASidebarComponent={false}
            textSize={textSize}
            iconSize={iconSize}
            hoverable={false}
            textBgColor={(option?.withBackgroundColor ?? true) && textBgColor}
            iconColor={iconColor}
            isTextActive={selectedSubOptionLength > 0}
            textbreak={textbreak}
            gap_x={gap_x}
            textColor={textColor}
            py={"rounded " + py}
            px={px}
          />
        </div>
      ) : (
        <div
          className={`flex justify-start items-center  gap-0.5 w-full   px-0.5  rounded break-words ${
            (option?.withBackgroundColor ?? true) && textBgColor
          }`}
        >
          {isAssignee && <ProfileIcon size={20} name={option.name ?? "N A"} />}
          <div className={dropDownBgColor && "    rounded w-fit bg-white"}>
            <div
              className={`${px} ${py} overflow-hidden rounded ${textSize}`}
              style={{
                backgroundColor: dropDownBgColor
                  ? rgbColor(dropDownBgColor, 0.2)
                  : null,
              }}
            >
              {isAssignee
                ? truncateText(option.label ?? option.name, 8)
                : option.label ?? option.name}
              {/* {option.label ?? option.name} */}
            </div>
          </div>
        </div>
      )}

      {/* {option.icon ? (
        <div className={dropDownBgColor && "bg-white w-fit rounded"}>
          <IconText
            icon={
              React.isValidElement(option.icon)
                ? option.icon
                : React.createElement(option.icon)
            }
            iconTextBgColor={
              dropDownBgColor ? rgbColor(dropDownBgColor, 0.2) : null
            }
            text={option.label}
            isASidebarComponent={false}
            textSize={textSize}
            iconSize={iconSize}
            hoverable={false}
            textBgColor={(option?.withBackgroundColor ?? true) && textBgColor}
            iconColor={iconColor}
            isTextActive={selectedSubOptionLength > 0}
            gap_x={gap_x}
            textColor={textColor}
            py={"rounded " + py}
            px={px}
          />
        </div>
      ) : (
        <div
          className={`flex justify-between w-full rounded break-words ${
            (option?.withBackgroundColor ?? true) && textBgColor
          }`}
        >
          <div className={dropDownBgColor && "rounded w-fit bg-white"}>
            <div
              className={`${px} ${py} rounded ${textSize}`}
              style={{
                backgroundColor: dropDownBgColor
                  ? rgbColor(dropDownBgColor, 0.2)
                  : null,
              }}
            >
              {option.label}
            </div>
          </div>
        </div>
      )} */}
      {selectedSubOptionLength > 1 && (
        <div className="rounded-full px-1 mr-2 py-0.25 bg-filter-extra text-white text-xs">
          {selectedSubOptionLength}
        </div>
      )}
      {rightIcon && (
        <div className="ml-2 mr-0.5">
          <div className=" relative  ">
            <AngleSmallUp
              className="    right-1 absolute bottom-[-3px] "
              size={14}
            />
            <AngleSmallDown
              className="    right-1  absolute  top-[-3px] "
              size={14}
            />
          </div>
          {/* {React.isValidElement(rightIcon)
            ? rightIcon
            : React.createElement(rightIcon)} */}
        </div>
      )}
    </div>
  );
};

export default DropdownItem;

import { setPreviousUrl, clearPreviousUrl } from "../slices/previousUrlSlice";

// Save the previous URL
export const savePreviousUrl = (url) => (dispatch) => {
  dispatch(setPreviousUrl(url));
};

// Clear the previous URL
export const resetPreviousUrl = () => (dispatch) => {
  dispatch(clearPreviousUrl());
};

import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import FavoriteView from "./FavoriteView";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import { apiConnector } from "api/apiconnector"; // Assuming you're using this for API requests
import { userEndPoints } from "../../../api/api"; // Adjust the path if needed
import { toast } from "react-toastify";
import { reorderFavoriteViews } from "../../../reduxStore/slices/favoriteViewsSlice";

const { REORDER_FAVORITE_VIEWS } = userEndPoints;

const FavoriteViews = () => {
  const dispatch = useDispatch();
  const { favoriteViews } = useSelector((state) => state.favoriteView);
  const [isLoading, setIsLoading] = useState(false);
  const [originalViews, setOriginalViews] = useState([]); // State to store the original order

  // Function to handle drag-and-drop reorder
  const onDragEnd = async (result) => {
    const { source, destination } = result;

    // If the item is dropped outside the list or no destination, do nothing
    if (!destination) return;

    // Save the original order before making any changes
    const savedViews = [...favoriteViews];
    setOriginalViews(savedViews);

    // Reorder the favorite views locally
    const updatedViews = [...favoriteViews];
    const [movedItem] = updatedViews.splice(source.index, 1); // Remove item from source index
    updatedViews.splice(destination.index, 0, movedItem); // Insert item at destination index

    // Extract the reordered view IDs
    const reorderedIds = updatedViews.map((view) => view._id);

    // Dispatch Redux action to update the order locally
    dispatch(
      reorderFavoriteViews({
        sourceIndex: source.index,
        destinationIndex: destination.index,
      })
    );

    // Make the API call to persist the new order
    setIsLoading(true);
    try {
      const response = await apiConnector("POST", REORDER_FAVORITE_VIEWS, {
        reorderedIds,
      });

      if (response.status === 200) {
        // toast.success("Favorite views reordered successfully!");
      } else {
        console.error("Failed to update order:", response.statusText);
        toast.error("Failed to reorder favorite views");
        // Reset to original order if the API call fails
        dispatch(reorderFavoriteViews({ reorderedViews: savedViews }));
      }
    } catch (error) {
      console.error("Error reordering favorite views:", error);
      // Reset to original order if an error occurs
      toast.error("An error occurred while reordering favorite views");
      dispatch(reorderFavoriteViews({ reorderedViews: savedViews }));
    } finally {
      setIsLoading(false); // Reset loading state
    }
  };

  return (
    <DragDropContext
      onDragEnd={isLoading ? () => {} : onDragEnd} // Disable drag-and-drop while loading
    >
      <Droppable droppableId="favoriteViews">
        {(provided) => (
          <div
            className="flex flex-col gap-y-1"
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            {(favoriteViews ?? []).map((view, index) => (
              <Draggable
                key={view._id}
                draggableId={view._id}
                index={index}
                isDragDisabled={isLoading} // Disable drag on individual items while loading
              >
                {(provided) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  >
                    <FavoriteView view={view} />
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default FavoriteViews;

import { createSlice } from "@reduxjs/toolkit";

// Initial state for user data
const initialState = {
  users: [], // List of users
};

// Create a slice for user data
const userFromCompanySlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    setUsers(state, action) {
      state.users = action.payload; // Set the users list
    },
  },
});

// Export actions to use in components
export const { setUsers } = userFromCompanySlice.actions;

// Export the reducer to be included in the store
export const usersReducer = userFromCompanySlice.reducer;


/* eslint-disable react/prop-types */
import React from "react";
import { Check, CrossSmall } from "react-flaticons";

const ViewNameInput = ({
  viewNameInput,
  handleViewNameChange,
  handleAddViewClick,
  handleCancelClick,
}) => {
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleAddViewClick();
    }
  };

  return (
    <div className=" w-[15rem]  ml-auto z-10  flex items-center gap-x-1">
      <input
        type="text"
        value={viewNameInput}
        onChange={(e) => {
          handleViewNameChange(e.target.value);
        }}
        onClick={(e) => {
          e.stopPropagation();
        }}
        onKeyDown={handleKeyDown}
        className="w-[10rem] px-2 py-1  rounded border border-solid border-black/30 focus:outline-none text-primary text-sm"
        placeholder="View Name"
      />
      <span
        className="hover:bg-button-hover rounded-full p-1.5 cursor-pointer"
        onClick={handleAddViewClick}
      >
        <Check size={12} />
      </span>
      <span
        className="hover:bg-button-hover rounded-full p-1.5  cursor-pointer"
        onClick={handleCancelClick}
      >
        <CrossSmall size={14} />
      </span>
    </div>
  );
};

export default ViewNameInput;

import {
  updateTimeFrame,
  appendTimeFrame,
  removeTimeFrame,
  setTimeFrames,
} from "../slices/companySlice";
import { apiConnector } from "api/apiconnector"; // Ensure you have an apiConnector function to handle API requests
import { timeFrameEndPoints } from "api/api";
import { setObjectiveRoadmaps, setObjectives } from "../slices/objectiveSlice";
import { setRoadmaps } from "../slices/roadmapsSlice";
import { setFeatures } from "../slices/featureSlice";
import { setIdeas } from "../slices/ideaSlice";
import { setKeyResults } from "../slices/keyResultSlice";
import { getAllObjectives } from "./objectivesAPI";
import { getPersonalViews } from "./viewsAPI";
import { getAllFeatures } from "./featuresAPI";
import { getAllIdeas } from "./ideasAPI";
import { getAllRoadmaps } from "./roadmapsAPI";
import { getAllKeyResults } from "./keyResultsAPI";

const {
  ADD_COMPANY_TIME_FRAME,
  EDIT_COMPANY_TIME_FRAME,
  DELETE_COMPANY_TIME_FRAME,
  GET_COMPANY_TIME_FRAMES,
  GET_TIME_FRAME_ROADMAPS,
} = timeFrameEndPoints;

export const getCompanyTimeFrames = () => {
  return async (dispatch) => {
    try {
      const response = await apiConnector("GET", GET_COMPANY_TIME_FRAMES());
      if (response.status === 200) {
        dispatch(setTimeFrames(response.data));
      }
    } catch (error) {
      console.error("Error getting timeFrames", error);
    }
  };
};

export const getTimeFrameRoadmaps = (timeFrameIds) => {
  return async (dispatch) => {
    try {
      const query = timeFrameIds.join(",");

      const response = await apiConnector(
        "GET",
        GET_TIME_FRAME_ROADMAPS(query)
      );

      if (response.status === 200) {
        dispatch(
          setObjectiveRoadmaps({
            timeFrameIds: timeFrameIds,
            roadmaps: response.data.groupedRoadmaps,
          })
        );
      }
    } catch (error) {
      // Check if the error has a response and if the status is 404
      if (error.response && error.response.status === 404) {
        dispatch(
          setObjectiveRoadmaps({
            timeFrameIds: timeFrameIds,
            roadmaps: error.response.data.groupedRoadmaps,
          })
        );
      } else {
        // Handle other errors
        console.error("Error getting releases", error);
      }
    }
  };
};

export const addTimeFrameToCompany = (label, color, startDate, endDate) => {
  return async (dispatch) => {
    try {
      const response = await apiConnector("POST", ADD_COMPANY_TIME_FRAME(), {
        label,
        color,
        startDate,
        endDate,
      });
      if (response.status === 201) {
        dispatch(appendTimeFrame(response.data));
      } else {
        console.error("Failed to add timeFrame:", response.statusText);
      }
    } catch (error) {
      console.error("Error deleting timeFrame:", error);
    }
  };
};

export const editTimeFrameInCompany = (
  timeFrameId,
  label,
  color,
  startDate,
  endDate
) => {
  return async (dispatch) => {
    try {
      const response = await apiConnector(
        "PUT",
        EDIT_COMPANY_TIME_FRAME(timeFrameId),
        {
          label,
          color,
          startDate,
          endDate,
        }
      );
      if (response.status === 200) {
        // dispatch(setObjectives(null));
        // dispatch(setRoadmaps(null));
        // dispatch(setFeatures(null));
        // dispatch(setIdeas(null));
        // dispatch(setKeyResults(null));
        dispatch(updateTimeFrame(response.data.timeFrame));
        dispatch(getAllObjectives());
        dispatch(getAllFeatures()),
        dispatch(getAllIdeas()),
        dispatch(getAllRoadmaps()),
        dispatch(getAllKeyResults());
      } else {
        console.error("Failed to delete tag:", response.statusText);
      }
    } catch (error) {
      console.error("Error deleting tag:", error);
    }
  };
};

export const deleteTimeFrameOfCompany = (timeFrameId) => {
  return async (dispatch) => {
    try {
      const response = await apiConnector(
        "DELETE",
        DELETE_COMPANY_TIME_FRAME(timeFrameId)
      );
      if (response.status === 200) {
        // dispatch(setObjectives(null));
        // dispatch(setRoadmaps(null));
        // dispatch(setFeatures(null));
        // dispatch(setIdeas(null));
        // dispatch(setKeyResults(null));
        dispatch(removeTimeFrame(timeFrameId));
        dispatch(getAllObjectives());
        dispatch(getPersonalViews()),
        dispatch(getAllFeatures()),
        dispatch(getAllIdeas()),
        dispatch(getAllRoadmaps()),
        dispatch(getAllKeyResults());
      } else {
        console.error("Failed to delete timeFrame:", response.statusText);
      }
    } catch (error) {
      console.error("Error deleting timeFrame:", error);
    }
  };
};

import { toSnakeCase } from ".";

export const sortGroupedData = (
  groupedData = {},
  sortOption = {},
  groupBy,
  scoreType = "value_effort_score",
  statuses = {},
  users,
  accounts
) => {
  const sortedGroupedData =
    groupedData &&
    Object.keys(groupedData).reduce((acc, groupKey) => {
      acc[groupKey] = applySorting(
        groupedData[groupKey],
        sortOption,
        statuses,
        scoreType,
        users
      );
      return acc;
    }, {});

  const predefinedOrder =
    statuses &&
    statuses
      .slice()
      .sort((a, b) => {
        const typeOrder = ["parked", "to_do", "in_progress", "done"];

        const typeComparison =
          typeOrder.indexOf(a.type) - typeOrder.indexOf(b.type);

        if (typeComparison === 0) {
          return new Date(a.createdAt) - new Date(b.createdAt);
        }

        return typeComparison;
      })
      .map((status) => status?.label);

  if (
    (sortOption.field === "submittedby" && groupBy === "submittedby") ||
    (sortOption.field === "assignee" && groupBy === "assignee") 
   
  ) {
    const sortedGroupKeys = Object.keys(groupedData).sort((a, b) => {
      const nameA = users.find((user) => user._id === a)?.name || "";
      const nameB = users.find((user) => user._id === b)?.name || "";

      if (nameA > nameB) return sortOption.order === "asc" ? 1 : -1;
      if (nameA < nameB) return sortOption.order === "asc" ? -1 : 1;

      return 0;
    });

    return sortedGroupKeys.reduce((acc, key) => {
      acc[key] = groupedData[key];
      return acc;
    }, {});
  }
   else if (sortOption.field === "importance" && groupBy === "importance") {
    const sortedGroupKeys = Object.keys(groupedData).sort((a, b) => {
      // Ensure proper default values and numeric comparison
      const importanceA = groupedData[a].label || 0; // Default to 0 if missing
      const importanceB = groupedData[b].label || 0; // Default to 0 if missing

      if (importanceA > importanceB) return sortOption.order === "asc" ? 1 : -1;
      if (importanceA < importanceB) return sortOption.order === "asc" ? -1 : 1;

      return 0;
    });

    const remapKeys = { 5: 1, 4: 2, 3: 3, 2: 4, 1: 5 };

    return sortedGroupKeys.reduce((acc, key) => {
      if (sortOption.order === "desc") {
        // Replace the key using the remapKeys object, or keep it unchanged if not found
        const remappedKey = remapKeys[key] ?? key;
        acc[remappedKey] = groupedData[key];
      } else {
        // For "asc", keep the original keys
        acc[key] = groupedData[key];
      }
      return acc;
    }, {});
  }
  else if (sortOption.field === "accounts" && groupBy === "accounts") {
    const alphabeticalSortByFirstLetter = (a, b) => {
      const labelA = sortedGroupedData[a]?.label?.trim() || "";
      const labelB = sortedGroupedData[b]?.label?.trim() || "";
  
      const firstLetterA = labelA[0]?.toLowerCase() || ""; // Get the first letter of 'labelA'
      const firstLetterB = labelB[0]?.toLowerCase() || ""; // Get the first letter of 'labelB'
  
      if (firstLetterA === firstLetterB) {
        // Fallback to full alphabetical order if first letters are the same
        return sortOption.order === "asc"
          ? labelA.localeCompare(labelB)
          : labelB.localeCompare(labelA);
      }
  
      return sortOption.order === "asc"
        ? firstLetterA.localeCompare(firstLetterB)
        : firstLetterB.localeCompare(firstLetterA);
    };
  
    const sortedGroupKeys = Object.keys(sortedGroupedData).sort(alphabeticalSortByFirstLetter);
  
    return sortedGroupKeys.reduce((acc, key) => {
      acc[key] = sortedGroupedData[key];
      return acc;
    }, {});
  }
  
   else if (toSnakeCase(sortOption.field) === toSnakeCase(groupBy)) {
    const sortByOrder = (a, b) => {
      const indexA = predefinedOrder.indexOf(a);
      const indexB = predefinedOrder.indexOf(b);

      if (indexA === -1 && indexB === -1) {
        return sortOption.order === "asc"
          ? a.localeCompare(b)
          : b.localeCompare(a);
      }
      if (indexA === -1) return sortOption.order === "asc" ? 1 : -1;
      if (indexB === -1) return sortOption.order === "asc" ? -1 : 1;
      return sortOption.order === "asc" ? indexA - indexB : indexB - indexA;
    };

    const sortedGroupKeys = Object.keys(sortedGroupedData).sort(sortByOrder);

    return sortedGroupKeys.reduce((acc, key) => {
      acc[key] = sortedGroupedData[key];
      return acc;
    }, {});
  }

  // if (groupBy === "submittedby" || "assignee") {
  //   const sortedGroupKeys = Object.keys(groupedData).sort((a, b) => {
  //     const nameA = users.find((user) => user._id === a)?.name || "";
  //     const nameB = users.find((user) => user._id === b)?.name || "";

  //     if (nameA > nameB) return sortOption.order === "asc" ? 1 : -1;
  //     if (nameA < nameB) return sortOption.order === "asc" ? -1 : 1;

  //     return 0;
  //   });

  //   return sortedGroupKeys.reduce((acc, key) => {
  //     acc[key] = groupedData[key];
  //     return acc;
  //   }, {});
  // }

  return sortedGroupedData;
};

const applySorting = (
  data = [],
  sortOption = {},
  statuses = {},
  scoreType,
  users
) => {
  const realData = data.items;
  let sortedData = [];

  if (sortOption.field === "status") {
    const sortedStatuses = statuses
      .slice()
      .sort((a, b) => {
        const typeOrder = ["parked", "to_do", "in_progress", "done"];

        const typeComparison =
          typeOrder.indexOf(a.type) - typeOrder.indexOf(b.type);

        if (typeComparison === 0) {
          return new Date(a.createdAt) - new Date(b.createdAt);
        }

        return typeComparison;
      })
      .map((status) => status?.label);

    sortedData = sortedStatuses.flatMap((status) =>
      realData.filter(
        (item) => toSnakeCase(item?.status?.label) === toSnakeCase(status)
      )
    );

    sortedData = sortOption.order === "asc" ? sortedData : sortedData.reverse();
  } else if (sortOption.field === "score") {
    sortedData = [...realData].sort((a, b) => {
      const { field, order } = sortOption;
      const aScore = a[field][scoreType];
      const bScore = b[field][scoreType];
      return (aScore > bScore ? 1 : -1) * (order === "asc" ? 1 : -1);
    });
  } else if (sortOption.field === "release" || sortOption.field === "product") {
    sortedData = [...realData].sort((a, b) => {
      const { field, order } = sortOption;

      if (order === "asc") {
        if (a[field] === null && b[field] !== null) return -1;
        if (a[field] !== null && b[field] === null) return 1;
      } else {
        // For "desc" order, keep null values last
        if (a[field] === null && b[field] !== null) return 1;
        if (a[field] !== null && b[field] === null) return -1;
      }

      if (a[field] === null && b[field] === null) return 0;

      const extractParts = (str) => {
        const match = str.match(/^([^\d]*)(\d*)$/);
        const textPart = match ? match[1].trim() : str;
        const numberPart = match && match[2] ? parseInt(match[2], 10) : null;
        return { textPart, numberPart };
      };

      const aParts = extractParts(a[field]?.label ?? "");
      const bParts = extractParts(b[field]?.label ?? "");

      if (aParts.textPart !== bParts.textPart) {
        return (
          (aParts.textPart > bParts.textPart ? 1 : -1) *
          (order === "asc" ? 1 : -1)
        );
      }

      if (aParts.numberPart !== null && bParts.numberPart !== null) {
        return (
          (aParts.numberPart > bParts.numberPart ? 1 : -1) *
          (order === "asc" ? 1 : -1)
        );
      }

      if (aParts.numberPart !== null && bParts.numberPart === null) return -1;
      if (aParts.numberPart === null && bParts.numberPart !== null) return 1;

      return 0;
    });
  } else if (sortOption.field === "time_frame") {
    sortedData = [...realData].sort((a, b) => {
      const fieldA = a.timeFrame?.startDate
        ? new Date(a.timeFrame.startDate)
        : a.timeFrame?.label;
      const fieldB = b.timeFrame?.startDate
        ? new Date(b.timeFrame.startDate)
        : b.timeFrame?.label;

      if (fieldA > fieldB) return sortOption.order === "asc" ? 1 : -1;
      if (fieldA < fieldB) return sortOption.order === "asc" ? -1 : 1;
      return 0;
    });
  } else if (sortOption.field === "assignee") {
    sortedData = [...realData].sort((a, b) => {
      // Extract and clean assignee names
      const nameA = a.assignee?.name || "";
      const nameB = b.assignee?.name || "";

      // Helper function to determine category: number, alphabet, symbol
      const getCategory = (name) => {
        if (/^\d/.test(name)) return 0; // Numbers first
        if (/^[a-zA-Z]/.test(name)) return 1; // Alphabets next
        return 2; // Symbols last
      };

      // Determine categories
      const categoryA = getCategory(nameA);
      const categoryB = getCategory(nameB);

      // Sort by category first
      if (categoryA !== categoryB) {
        return sortOption.order === "asc"
          ? categoryA - categoryB
          : categoryB - categoryA;
      }

      // Within the same category, use lexicographical order
      if (nameA > nameB) return sortOption.order === "asc" ? 1 : -1;
      if (nameA < nameB) return sortOption.order === "asc" ? -1 : 1;

      return 0; // If names are identical
    });
  } else if (sortOption.field === "submittedby") {
    sortedData = [...realData].sort((a, b) => {
      const nameA =
        users.find((user) => user._id === a.submittedBy)?.name || "";
      const nameB =
        users.find((user) => user._id === b.submittedBy)?.name || "";

      const getCategory = (name) => {
        if (/^\d/.test(name)) return 0; // Numbers first
        if (/^[a-zA-Z]/.test(name)) return 1; // Alphabets next
        return 2; // Symbols last
      };

      const categoryA = getCategory(nameA);
      const categoryB = getCategory(nameB);

      if (categoryA !== categoryB) {
        return sortOption.order === "asc"
          ? categoryA - categoryB
          : categoryB - categoryA;
      }

      if (nameA > nameB) return sortOption.order === "asc" ? 1 : -1;
      if (nameA < nameB) return sortOption.order === "asc" ? -1 : 1;

      return 0;
    });
  } else if (
    sortOption.field === "importance" ||
    sortOption.field === "health" ||
    sortOption.field === "progress"
  ) {
    sortedData = [...realData].sort((a, b) => {
      const { field, order } = sortOption;

      if (a[field] === null && b[field] !== null) return 1;
      if (a[field] !== null && b[field] === null) return -1;
      if (a[field] === null && b[field] === null) return 0;

      return (a[field] > b[field] ? 1 : -1) * (order === "asc" ? 1 : -1);
    });
  } else if (sortOption.field === "source") {
    sortedData = [...realData].sort((a, b) => {
      const { order } = sortOption;

      // Normalize source values
      const sourceA = a.source || "Internal"; // Default to "Internal" if source is missing
      const sourceB = b.source || "Internal";

      // Define sorting priority: Internal comes before External
      const priority = { Internal: 1, External: 2 };

      // Compare based on priority
      return (
        (priority[sourceA] - priority[sourceB]) * (order === "asc" ? 1 : -1)
      );
    });
  } else if (sortOption.field !== "default") {
    sortedData = [...realData].sort((a, b) => {
      const { field, order } = sortOption;

      if (a[field] === null && b[field] !== null) return 1;
      if (a[field] !== null && b[field] === null) return -1;
      if (a[field] === null && b[field] === null) return 0;

      return (a[field] > b[field] ? 1 : -1) * (order === "asc" ? 1 : -1);
    });
  } else {
    sortedData = realData;
  }

  return {
    ...data,
    items: sortedData,
  };
};

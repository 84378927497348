import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getCookie } from "utils/cookieUtils";
import { setLoading } from "reduxStore/slices/authSlice";
import Loader from "../common/Loader";
import { companyRegister } from "../../reduxStore/operations/authAPI";

const SignUpWithGoogle = () => {
  const [errorMessages, setErrorMessages] = useState({});
  const [companyName, setCompanyName] = useState("");
  const [credential, setCredential] = useState("");

  const { loading } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("jwt");
    if (token) {
      navigate("/");
    }
  }, [navigate]);

  const handleCompanyRegister = (e) => {
    e.preventDefault();
    let errors = {};
    if (!companyName) {
      errors.companyName = "Company name is required.";
      setErrorMessages(errors);
      return;
    }
    const credential = localStorage.getItem("google_token");
    const isEmailSignUp = true;
    dispatch(setLoading(true));
    dispatch(
      companyRegister("", companyName, credential, "", navigate, isEmailSignUp)
    );
  };

  return (
    <div className="flex flex-col gap-4 items-center justify-center min-h-screen bg-gray-100">
      <div className=" relative bg-white p-8 rounded shadow-md w-full max-w-sm">
        <div className="  absolute top-[-120px] right-0 w-full  flex items-center justify-center  ">
          <img
            src="/logo.png  "
            alt="logo"
            className=" w-0 h-0  xl:w-[200px] xl:h-[100px] "
          />{" "}
        </div>

        <h2 className="text-2xl mb-6 text-center">Welcome onboard!</h2>
        <form onSubmit={handleCompanyRegister}>
          {/* Email Field */}
          <div className="mb-32">
            <label className="block text-gray-700">Company</label>
            <input
              type="text"
              className={`w-full p-2 border ${
                errorMessages.companyName ? "border-red-500" : "border-gray-300"
              } rounded mt-1`}
              value={companyName}
              placeholder="Company"
              onChange={(e) => setCompanyName(e.target.value)}
            />
            {errorMessages.companyName && (
              <p className="text-red-500 text-sm mt-1">
                {errorMessages.companyName}
              </p>
            )}
          </div>
          <button
            type="submit"
            disabled={loading}
            className="w-full bg-blue-500 text-white p-2 rounded mt-4 hover:bg-blue-600"
          >
            {loading ? <Loader size="20" color="white" /> : "Take me in"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default SignUpWithGoogle;

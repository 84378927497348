/* eslint-disable react/prop-types */
import React from "react";
import DropdownItem from "components/common/DropdownItem";
import { Star, Trash } from "react-flaticons";
import { TbEdit } from "react-icons/tb";
import { FaRegStar, FaStar } from "react-icons/fa";

const EditDeleteDropdown = ({
  editingViewId,
  handleEditView,
  handleDeleteView,
  handleFavoriteClick,
  handlePersonalClick,
  isAPersonalView,
  isAFavoriteView = false,
  roadmapId,
  isKr = false,
  keId,
  HandleItemRemove,
}) => {
  return (
    <div
      className="flex flex-col gap-y-1 p-1.5  "
      onClick={(e) => e.stopPropagation()}
    >
      {isKr && (
        <DropdownItem
          option={{ label: "Delete", icon: Trash }}
          iconSize="14"
          textSize="text-xs"
          iconColor="text-primary"
          gap_x="gap-x-2"
          handleOptionClick={() => HandleItemRemove(keId)}
        />
      )}
      {!isKr && !isAFavoriteView && (
        <DropdownItem
          option={{ label: "Rename", icon: TbEdit }}
          dropDownBgColor={editingViewId && "bg-button-active_10"}
          dropDownBgWidth={"w-full"}
          textSize="text-xs"
          iconColor="text-primary"
          iconSize="14"
          gap_x="gap-x-2"
          handleOptionClick={handleEditView}
        />
      )}
      {!roadmapId && !isAFavoriteView && !isKr && (
        <DropdownItem
          option={{ label: "Delete", icon: Trash }}
          iconSize="14"
          textSize="text-xs"
          iconColor="text-primary"
          gap_x="gap-x-2"
          handleOptionClick={handleDeleteView}
        />
      )}
      {roadmapId && (
        <DropdownItem
          option={{
            label: isAPersonalView ? "Space" : "Space",
            icon: isAPersonalView ? FaStar : FaRegStar,
          }}
          iconSize="14"
          textSize="text-xs"
          iconColor="text-primary"
          gap_x="gap-x-2"
          handleOptionClick={handlePersonalClick}
        />
      )}
      {!isKr && !isAFavoriteView && (
        <DropdownItem
          option={{ label: "Favorite", icon: Star }}
          iconSize="14"
          textSize="text-xs"
          iconColor="text-primary"
          gap_x="gap-x-2"
          textbreak={true}
          truncateLength={15}
          handleOptionClick={handleFavoriteClick}
        />
      )}
      {isAFavoriteView && (
        <DropdownItem
          option={{ label: "Remove ", icon: Star }}
          iconSize="14"
          textSize="text-xs"
          iconColor="text-primary"
          gap_x="gap-x-2"
          textbreak={true}
          truncateLength={15}
          handleOptionClick={handleFavoriteClick}
        />
      )}
    </div>
  );
};

export default EditDeleteDropdown;

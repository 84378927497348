import { createSlice } from "@reduxjs/toolkit";

// Initial state for view data
const initialState = {
  personalViews: [],
  activePersonalView: null,
};

// Create a slice for view data
const personalViewSlice = createSlice({
  name: "personalView",
  initialState,
  reducers: {
    setPersonalViews(state, action) {
      state.personalViews = action.payload;
    },
    appendPersonalViews(state, action) {
      const personalView = action.payload;
      const index = state.personalViews.findIndex(
        (view) => view._id === personalView._id
      );
      if (index === -1) {
        state.personalViews.push(action.payload);
      }
    },
    removePersonalView(state, action) {
      const removeViewId = action.payload;
      const index = state.personalViews.findIndex(
        (view) => view._id === removeViewId
      );
      if (index !== -1) {
        state.personalViews.splice(index, 1);
      }
    },
    reorderPersonalViews(state, action) {
      const { sourceIndex, destinationIndex } = action.payload;

      // Reorder the array
      const updatedViews = [...state.personalViews];
      const [movedItem] = updatedViews.splice(sourceIndex, 1); // Remove item from source index
      updatedViews.splice(destinationIndex, 0, movedItem); // Insert item at destination index

      state.personalViews = updatedViews;
    },
    updatePersonalView(state, action) {
      const { updatedView } = action.payload;
      const index = state.personalViews.findIndex(
        (view) => view._id === updatedView._id
      );
      if (index !== -1) {
        state.personalViews[index] = updatedView;
      }
    },
    updateRoadmapIdPersonalView(state, action) {
      const { updatedView, roadmapId, isUpdated } = action.payload;
      const index = state.personalViews.findIndex(
        (view) => view.roadmapId === roadmapId
      );
      if (index !== -1) {
        if (isUpdated === "label") {
          state.personalViews[index].label = updatedView.label;
        }
      }
    },
  },
});

// Export actions to use in components
export const {
  setPersonalViews,
  removePersonalView,
  appendPersonalViews,
  updatePersonalView,
  updateRoadmapIdPersonalView,
  reorderPersonalViews,
} = personalViewSlice.actions;

// Export the reducer to be included in the store
export const personalViewReducer = personalViewSlice.reducer;

/* eslint-disable react/prop-types */
import React, { useState, useEffect, useRef } from "react";
import IconText from "components/common/IconText";
import { capitalizeFirstLetter, fieldIconMapping } from "utils";
import { Info } from "react-flaticons";
import DropdownMenu from "../dropdown/DropdownMenu";

const SourceSection = ({
  sourceLabel,
  sources,
  handleSourceOptionClick,
  position = "gap-x-6",
  iconSize=16,
  with_title = true,
  with_label_text = true,
  with_dropdown = true,
  with_border = true,
  showHover = false,
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef();

  const handleLocalSourceOptionClick = (option) => {
    handleSourceOptionClick(option);
    setIsDropdownOpen(false);
  };

  const toggleDropdown = (e) => {
    e.stopPropagation();
    setIsDropdownOpen(!isDropdownOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className={`flex ${position}  items-center text-title_50 `}>
      {with_title && (
        <IconText
          icon={React.createElement(fieldIconMapping.source)}
          text={with_label_text?"Source":""}
          iconColor="#696969"
          iconSize={iconSize}
          hoverable={false}
          px={with_label_text?"px-2":"px-1"}
          gap_x={with_label_text?"gap-x-2 ":"gap-x-0  "}
        />
      )}
      <div className="relative " ref={dropdownRef}>
        <div className="flex items-center gap-x-2">
          <div
            className={`${with_border && "border rounded"} ${
              sourceLabel ? "p-1" : "px-7 py-1 w-[55px]"
            } text-xs cursor-default ${
              with_dropdown &&
              (isDropdownOpen
                ? "rounded cursor-pointer bg-button-active_10"
                : "rounded cursor-pointer hover:bg-button-hover")
            }`}
            onClick={toggleDropdown}
          >
            {capitalizeFirstLetter(sourceLabel ?? "")}
          </div>
          {showHover && (
            <div className="group">
              <Info size={14} className="cursor-pointer" />
              <div className="absolute hidden group-hover:block text-black w-[18rem] py-3 top-4 right-[-3rem] z-50">
                <div className="bg-white p-3 px-4 rounded-md shadow-md text-gray-600 text-sm">
                  <p>
                    <b className="text-title_50">Source Info:</b> Information about the source of the item can help provide context and ensure its reliability.
                  </p>
                  <p className="pt-3">Once a source is selected, it cannot be changed later.</p>
                </div>
              </div>
            </div>
          )}
        </div>
        {with_dropdown && isDropdownOpen && (
          <DropdownMenu
            options={sources ?? []}
            isOpen={isDropdownOpen}
            position="top-full left-0"
            handleOptionClick={handleLocalSourceOptionClick}
            type={"Source"}
            selected={sourceLabel}
          />
        )}
      </div>
    </div>
  );
};

export default SourceSection;

/* eslint-disable react/prop-types */
import { useLocation } from "react-router-dom";
import IconText from "../IconText";
import React from "react";

const SwitchCheckboxItem = ({
  option,
  handleOptionClick,
  selected,
  checkboxTextSize = "text-xs",
}) => {
  const { pathname } = useLocation();
  const isOwner =
    pathname.includes("objectives") &&
    option.label === "Assignee";
  return (
    <>
      <div className={`text-xs  text-primary ${checkboxTextSize}`}>
        {option.icon ? (
          <IconText
            icon={
              React.isValidElement(option.icon)
                ? option.icon
                : React.createElement(option.icon)
            }
            text={ option.label === "For" ? "Type" : isOwner ? "Owner" : option.label}
            textSize={checkboxTextSize}
            gap_x="gap-x-2"
            py=""
            px=""
            hoverable={false}
          />
        ) : (
          option.label 
        )}
      </div>
      <label
        htmlFor={option.label}
        className="relative w-9 rounded-full cursor-pointer"
      >
        <input
          type="checkbox"
          id={option.label}
          className="sr-only peer"
          onChange={(e) => {
            e.stopPropagation();
            handleOptionClick(option);
          }}
          checked={selected}
          aria-label={option.label}
        />
        {/* Checkbox Body */}
        <span className="block w-[31px] h-[17px] shadow-checkbox-inset rounded-full bg-checkbox-unchecked peer-checked:bg-checkbox-checked transition-colors duration-200"></span>
        {/* Checkbox Circle */}
        <span className="absolute top-[2px] w-[13px] h-[13px] left-[2px] bg-checkbox-gradient rounded-full transition-transform duration-200 transform peer-checked:translate-x-[14px]"></span>
      </label>
    </>
  );
};

export default SwitchCheckboxItem;

import {
  Briefcase,
  Bulb,
  Calendar,
  CommentCheck,
  CommentHeart,
  Cube,
  Earnings,
  Exclamation,
  Following,
  HourglassEnd,
  Interactive,
  Loading,
  Map,
  PaperPlane,
  RecordVinyl,
  Rhombus,
  SignInAlt,
  SignOutAlt,
  StarOctogram,
  TableColumns,
  Tags,
  User,
  UserTime,
} from "react-flaticons";
import { CiViewColumn } from "react-icons/ci";
import { FaCube, FaSignOutAlt } from "react-icons/fa";
import { IoFlameOutline } from "react-icons/io5";
import { LuBox } from "react-icons/lu";
import { RiCircleFill, RiHashtag, RiProgress5Line } from "react-icons/ri";
import { TiChartLineOutline } from "react-icons/ti";

export const toSnakeCase = (text) => {
  return text?.toLowerCase().replace(/ /g, "_") ?? "";
};

export const getActiveIconColor = (pathname, text) => {
  const searchParams = new URLSearchParams(location.search);
  const view = searchParams.get("view");
  if (view) {
    return "text-primary";
  }
  if (pathname.includes("/roadmaps/") && text === "Roadmaps") {
    // return "text-sidebar-icon-active";
    return "text-primary";
  }
  // Check specific conditions for active icon color
  if (pathname === "/" && text === "Features") {
    return "text-sidebar-icon-active";
  }
  if (pathname === "/settings" && text === "Profile") {
    return "text-sidebar-icon-active";
  }
  if (pathname.includes(text.toLowerCase())) {
    return "text-sidebar-icon-active";
  }

  // Default color if no conditions matched
  return "text-primary";
};

export const capitalizeFirstLetter = (str) => {
  const tempStr = str.toLowerCase();
  return tempStr.charAt(0).toUpperCase() + tempStr.slice(1).replace(/_/g, " ");
};

export const transformFiltersToObject = (filtersArray) => {
  const filtersObject = {};

  (filtersArray ?? []).forEach((item) => {
    filtersObject[item.field] = item?.value || [];
  });

  return filtersObject;
};

export const transformFiltersToArray = (filtersObject) => {
  const filtersArray = [];

  Object.entries(filtersObject).forEach(([field, values]) => {
    filtersArray.push({
      field,
      value: values,
    });
  });

  return filtersArray;
};

export const isObject = (value) => {
  return value !== null && typeof value === "object" && !Array.isArray(value);
};

export const isObjectEmpty = (obj) => {
  for (let key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      return false;
    }
  }
  return true;
};



export const fieldIconMapping = {
  status: <RiProgress5Line />,
  release: Loading,
  product: Cube,
  // product: LuBox,
  assignee: User,
  creator: UserTime,
  source: StarOctogram,
  importance: Exclamation,
  score: RiHashtag,
  objective: RecordVinyl,
  insights: IoFlameOutline,
  key_result: Rhombus,
  key_results: Rhombus,
  spilled_from: SignOutAlt,
  blocking: SignInAlt,
  accounts: Following,
  blocked_by: FaSignOutAlt,
  tags: Tags,
  roadmap: Map,
  tasks: HourglassEnd,
  ideas: Bulb,
  idea: Bulb,
  feature: PaperPlane,
  feedback: CommentHeart,
  timeFrames: RiCircleFill,
  time_frame: <Calendar />,
  health: TiChartLineOutline,
  progress: Earnings,
  type: Interactive,
  for: Interactive,
  columns: CiViewColumn,
};

export const colors = [
  "#A9A9A9",
  "#4AB3FF",
  "#FF9D66",
  "#84DF81",
  "#E4D00A",
  "#FF6678",
  "#FF00FF",
  "#D96CFF",
];

// old
// export const colors = [
//   "#A9A9A9",
//   "#4AB3FF",
//   "#FF9D66",
//   "#84DF81",
//   "#E4D00A",
//   "#FF6678",
//   "#FF00FF",
//   "#D96CFF",
// ];

export const rgbColor = (color, opacity = 1) => {
  return `rgba(${hexToRgb(color)}, ${opacity})`;
};

const hexToRgb = (hex) => {
  // Remove the hash at the start if it's there
  hex = hex?.replace(/^#/, "") ?? "";
  // Parse the r, g, b values
  if (hex !== "") {
    const bigint = parseInt(hex, 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;
    // Return the RGB color
    return `${r}, ${g}, ${b}`;
  }
  return "";
};

export const truncateText = (text, maxLength) => {
  return text.length > maxLength ? `${text.slice(0, maxLength)}...` : text;
};

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  RiProgress5Line,
  RiProgress8Fill,
  RiProgress8Line,
} from "react-icons/ri";
import { TbCircleDashed } from "react-icons/tb";
import { Minus } from "react-flaticons";
import { useNavigate } from "react-router-dom";

import Sidebar from "components/core/Sidebar";
import Topbar from "components/core/topbar/Topbar";

import Loader from "components/common/Loader";
import IconWrapper from "components/common/IconWrapper";

import FeaturesComponent from "components/features/FeaturesComponent";

import { capitalizeFirstLetter, fieldIconMapping } from "utils";
import { featureTopbarButtonFunctions } from "utils/topbarButtonFunctions";
import { featureTopbarTabFunctions } from "utils/topbarTabFunctions";

import { setFeatureActiveProperties } from "reduxStore/slices/featureSlice";

import AllDetailView from "components/common/AllDetailView";

const Features = () => {
  const { objectives } = useSelector((state) => state.objectives);
  const { viewType, isLoading, isViewsLoading } = useSelector(
    (state) => state.features
  );
  const { keyResults } = useSelector((state) => state.keyResults);
  const { roadmaps } = useSelector((state) => state.roadmaps);
  const { users } = useSelector((state) => state.users);

  const { statuses, releases, products, tags } = useSelector(
    (state) => state.company
  );
  const [featureStatuses, setFeatureStatuses] = useState([]);
  const [searchBarTerm, setSearchbarTerm] = useState("");

  useEffect(() => {
    setFeatureStatuses(statuses.feature);
  }, [statuses]);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const topbarFunctions = featureTopbarButtonFunctions(dispatch, navigate);
  const topbarTabFunctions = featureTopbarTabFunctions(dispatch, navigate);
  const setActiveProperties = (
    sortOption,
    selectedColumns,
    groupByOption,
    filters
  ) => {
    dispatch(
      setFeatureActiveProperties({
        sortOption,
        selectedColumns,
        groupByOption,
        filters,
      })
    );
  };

  const statusParked =
    (featureStatuses ?? []).filter((item) => item.type === "parked") ?? [];
  const statusToDo =
    (featureStatuses ?? []).filter((item) => item.type === "to_do") ?? [];
  const statusInProgress =
    (featureStatuses ?? []).filter((item) => item.type === "in_progress") ?? [];
  const statusDone =
    (featureStatuses ?? []).filter((item) => item.type === "done") ?? [];

  const tabs = [
    {
      label: "All",
      filters: {},
      selectedColumns: ["status", "release", "score", "product"],
      groupByOption: "default",
      sortOption: { order: "asc", field: "created" },
    },
  ];
  //This Will only show tags in kanban view
  if (viewType === "kanban") {
    tabs[0].selectedColumns.push("tags");
  }

  const groupByDropdownOptions = [
    { label: "Default", icon: Minus },
    ...(viewType !== "kanban"
      ? [{ label: "Status", icon: fieldIconMapping.status }]
      : []),
    { label: "Release", icon: fieldIconMapping.release },
    { label: "Product", icon: fieldIconMapping.product },
    { label: "Tags", icon: fieldIconMapping.tags },
    { label: "Assignee", icon: fieldIconMapping["assignee"] },
    { label: "Objective", icon: fieldIconMapping.objective },
  ];

  const getFilteredFeatures = () => {
    const predefinedFields = [
      "status",
      "release",
      "score",
      "product",
      "tags",
      "assignee",
      "idea",
      "roadmap",
      "objective",
      "key_result",
    ];

    const filteredOptions = predefinedFields.map((field) => ({
      label: capitalizeFirstLetter(field),
      icon: fieldIconMapping[field] || Minus,
    }));
    return [...filteredOptions];
  };

  // Determine whether to show dropdown options based on features
  const showButtonDropdownOptions = getFilteredFeatures();

  const getFilterOptions = () => [
    {
      label: "Status",
      icon: fieldIconMapping.status,
      subOptions: [
        ...statusParked.map((item) => ({
          ...item,
          iconColor: item.color,
          icon: TbCircleDashed,
        })),
        ...statusToDo.map((item) => ({
          ...item,
          iconColor: item.color,
          icon: RiProgress8Fill,
        })),
        ...statusInProgress.map((item) => ({
          ...item,
          iconColor: item.color,
          icon: RiProgress5Line,
        })),
        ...statusDone.map((item) => ({
          ...item,
          iconColor: item.color,
          icon: RiProgress8Line,
        })),
      ],
    },
    {
      label: "Release",
      icon: fieldIconMapping.release,
      subOptions: [{ label: "No Release" }].concat(releases ?? []),
    },
    {
      label: "Product",
      icon: fieldIconMapping.product,
      subOptions: [{ label: "No Product", iconColor: "#A9A9A9" }].concat(
        (products ?? []).map((product) => ({
          ...product,
          iconColor: product.color,
        }))
      ),
    },
    {
      label: "Assignee",
      icon: fieldIconMapping.assignee,
      subOptions: users ?? [],
    },
    {
      label: "Tags",
      icon: fieldIconMapping.tags,
      subOptions: tags ?? [],
    },
    {
      label: "Objective",
      icon: fieldIconMapping.objective,
      subOptions: (objectives ?? []).map((item) => ({
        ...item,
        icon: fieldIconMapping.objective,
        iconColor: "text-objectives-icon",
        label: item.title,
        withBackgroundColor: false,
      })),
      // withMoreWidth: true,
      withWidth: " w-[22rem]",
    },
    {
      label: "Key result",
      icon: fieldIconMapping.key_result,
      subOptions: (keyResults ?? []).map((item) => ({
        ...item,
        icon: fieldIconMapping.key_result,
        iconColor: "text-key_results-icon",
        label: item.title,
        withBackgroundColor: false,
      })),
      // withMoreWidth: true,
      withWidth: " w-[22rem]",
    },
    {
      label: "Roadmap",
      icon: fieldIconMapping.roadmap,
      subOptions: (roadmaps ?? [])
        .filter((roadmap) => roadmap.for === "Release")
        .map((item) => ({
          ...item,
          icon: fieldIconMapping.roadmap,
          iconColor: "text-roadmaps-icon",
          label: item.title,
          withBackgroundColor: false,
        })),
      withWidth: "w-72",
    },
  ];

  const filterOptions = getFilterOptions();

  // const sortOptions = [
  //   "Default",
  //   { label: "Status", icon: fieldIconMapping.status },
  //   // "Status",
  //   { label: "Release", icon: fieldIconMapping.release },
  //   // "Release",
  //   "Product",
  //   "Assignee",
  //   "Score",
  // ].map((label) => ({ label }));

  const sortOptions = [
    { label: "Created", icon: Minus },
    { label: "Status", icon: fieldIconMapping.status },
    { label: "Release", icon: fieldIconMapping.release },
    { label: "Product", icon: fieldIconMapping.product },
    { label: "Assignee", icon: fieldIconMapping.assignee },
    { label: "Score", icon: fieldIconMapping.score },
  ];

  return (
    <div className="flex font-inter w-screen h-screen tracking-wide cursor-default">
      <Sidebar />
      {isLoading && isViewsLoading ? (
        <div className="absolute inset-0 flex items-center justify-center">
          <Loader />
        </div>
      ) : (
        <div className="flex flex-col min-w-[80%] w-[100%]">
          <Topbar
            title="Feature"
            titleIcon={
              <IconWrapper
                icon={React.createElement(fieldIconMapping.feature)}
                iconColor="text-features-icon"
              />
            }
            tabs={tabs}
            groupByDropdownOptions={groupByDropdownOptions}
            showButtonDropdownOptions={showButtonDropdownOptions}
            filterOptions={filterOptions}
            sortOptions={sortOptions}
            topbarFunctions={topbarFunctions}
            topbarTabFunctions={topbarTabFunctions}
            setActiveProperties={setActiveProperties}
            activeState={"features"}
            searchTerm={searchBarTerm}
            onSearchChange={(value) => setSearchbarTerm(value)}
          />
          <FeaturesComponent searchTerm={searchBarTerm} />
        </div>
      )}
      <AllDetailView />
    </div>
  );
};

export default Features;
